import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";
import "../style/EventListStyle.css";
import { exportToCSV } from '../Utils/csvUtils';

const DiscipleshipJourneyTypeDetails = () => {
    const [users, setUsers] = useState([]);
    const [tag, setTag] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [selectedEventType, setSelectedEventType] = useState("");
    const navigate = useNavigate();
        const { id } = useParams();


    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/checkEventRegistrationByDjJoin/${id}`);
            const responseDetails = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${id}`);
            setTag(responseDetails.data);
            const formattedUsers = response.data.map(user => ({
                ...user,
                namaDepan: user.namaDepan || '',
                namaTengah: user.namaTengah || '',
                namaBelakang: user.namaBelakang || '',
                createdAt: formatCreatedAt(user.createdAt),
            }));
            setUsers(formattedUsers);
            setDataFetched(true);
        } catch (error) {
            console.error(error);
        }
    };

    const formatCreatedAt = (createdAt) => {
        const dateObj = new Date(createdAt);
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1; // Months are zero indexed
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Ganti discjourney type
    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTag/${id}`);
            navigate("/DiscipleshipJourneyTypeDetails");
        } catch (error) {
            console.error(error);
        }
    };

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'namaDepan', // accessor is the "key" in the data
            Cell: ({ row }) => `${row.original.namaDepan} ${row.original.namaTengah} ${row.original.namaBelakang}`.trim()
        },
        {
            Header: 'Graduated On',
            accessor: 'createdAt'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <input
                    type="checkbox"
                    checked={value === 'lulus'}
                    readOnly
                />
            )
        },
        {
            Header: 'Action',
            accessor: 'tagId',
            Cell: ({ value }) => (
                <button className="button is-danger is-small" onClick={() => deleteUser(value)}>Delete</button>
            )
        }
    ], []);

    const filteredData = useMemo(() => {
        let data = users;

        if (selectedEventType) {
            data = data.filter(user => user.status === selectedEventType);
        }

        return data;
    }, [users, selectedEventType]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data: filteredData, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className='is-size-4 mb-5 has-text-weight-bold has-text-start'>{tag.discipleshipJourneyTypeName}</div>
                <div className="columns mt-2">
                    <div className="column is-7">
                        <div className="custom-button mr-2" onClick={() => navigate('/addEvent')}>Add Event</div>
                        <button className="custom-button mr-2" onClick={() => exportToCSV(users, 'Discipleship Journey')}>Export to CSV</button>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <select
                                    value={selectedEventType}
                                    onChange={(e) => setSelectedEventType(e.target.value)}
                                    className="custom-select"
                                >
                                    <option value="">All Status Types</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add sort indicator */}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0} 
                            </strong>
                        </span>
                    </div> 
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscipleshipJourneyTypeDetails;
