import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
// Stlye
import '../style/UserAddStyle.css';
import "../style/tableStyle.css";
// Function From Utils
import { formatDate_DDMMMMYYYY } from '../Utils/dateUtils';

const ActivityLifegroupAttendanceListUpdate = () => {
    // State variables
    const [eventDate, setEventDate] = useState("");
    const [lgname, setLGName] = useState("");
    const [eventMember, setEventMember] = useState([]);
    const [checkedMembers, setCheckedMembers] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    // Fetch event details
    const getEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            setEventDate(formatDate_DDMMMMYYYY(response.data.eventDate));
            const fetchedLifegroupId = response.data.lifegroupId;
            if (fetchedLifegroupId) {
                const responseLG = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${fetchedLifegroupId}`);
                setLGName(responseLG.data.lifegroupName);
            }
        } catch (error) {
            console.error("Error fetching event:", error);
        }
    };

    // Fetch attendance list
    const getPersonId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListByEventId/${id}`);
            setEventMember(response.data);

            const initialCheckedState = {};
            response.data.forEach(user => {
                initialCheckedState[user.attendanceListId] = user.kehadiran;
            });
            setCheckedMembers(initialCheckedState);
        } catch (error) {
            console.error("Error fetching attendance list:", error);
        }
    };

    // Toggle checkbox handler
    const handleCheckboxChange = (attendanceListId) => {
        setCheckedMembers(prevState => ({
            ...prevState,
            [attendanceListId]: !prevState[attendanceListId]
        }));
    };

    // Save attendance data
    const saveAttendance = async (e) => {
        e.preventDefault();
        try {
            const sessionDataList = eventMember.map(user => ({
                attendanceListId: user.attendanceListId,
                kehadiran: checkedMembers[user.attendanceListId],
                eventType: "Activity Lifegroup"
            }));

            await Promise.all(sessionDataList.map(sessionData => {
                console.log(`Updating attendance for attendanceListId: ${sessionData.attendanceListId}`);
                return axios.patch(`${process.env.REACT_APP_API_URL}/updateAttendanceList/${sessionData.attendanceListId}`, { kehadiran: sessionData.kehadiran });
            }));

            console.log("All attendance data updated successfully");
            navigate(`/ActivityLGAttendanceDetail/${id}`);
        } catch (error) {
            console.error("Error saving attendance:", error);
        }
    };

    useEffect(() => {
        getEvent();
    }, []);

    useEffect(() => {
        if (lgname) {
            getPersonId();
        }
    }, [lgname]);

    return (
        <div className="columns">
            <div className="column">
                {/* LG Name */}
                <div className="columns">
                    <div className="column is-3">
                        <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                    </div>
                    <div className="column is-4">
                        <label className='control'>
                            <input
                                type="text"
                                className='input'
                                value={lgname}
                                placeholder='Lifegroup Name'
                                readOnly
                            />
                        </label>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3">
                        <div className='is-size-5 mb-2 has-text-weight-semibold'>Date</div>
                    </div>
                    <div className="column is-4">
                        <label className='control'>
                            <input
                                type="text"
                                className='input'
                                value={eventDate}
                                placeholder='Date'
                                readOnly
                            />
                        </label>
                    </div>
                </div>
                <hr />
                <table className="table is-striped is-fullwidth mt-5" style={{ maxHeight: '0px', overflowY: 'auto' }}>
                    <thead>
                        <tr>
                            <th>Nama</th>
                            <th>Hadir</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(eventMember) && eventMember.length > 0 ? (
                            eventMember.map(user => (
                                <tr key={user.attendanceListId}>
                                    <td>{user.namaDepan}</td>
                                    <td style={{ width: '50px', textAlign: 'center' }}>
                                        <input
                                            type="checkbox"
                                            style={{ transform: 'scale(0.8)', verticalAlign: 'middle' }}
                                            checked={checkedMembers[user.attendanceListId]}
                                            onChange={() => handleCheckboxChange(user.attendanceListId)}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">Loading</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button className="button is-primary mt-3" 
                    style={{
                        backgroundColor: '#0052CC',
                        color: 'white',
                        padding: '10px 24px',
                        fontWeight: 'bold',
                        borderRadius: '8px'
                    }}
                onClick={saveAttendance}>Update</button>
            </div>
        </div>
    );
};

export default ActivityLifegroupAttendanceListUpdate;
