import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'
import Select from 'react-select'


const JemaatTagAdd = () => {

    // Untuk data yang ke DB
    
    const [personName, setPersonName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    // Inputan
    
    const [SessionName, setSessionName] = useState("");
    const [SessionDate, setSessionDate] = useState("");

   // Untuk Sugest
    const [optionTag, setOptionTag] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);

    // Select Taguisites
    useEffect(() => {
        const fetchTagOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTag`);
            console.log(response)
            const options = response.data.map((dj) => ({
            value: dj.tagId,
            label: dj.tagName,
            }));
            setOptionTag(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
        };

        fetchTagOptions();
    }, []);

    const handleTagChange = (selectedOption) => {
        setSelectedTag(selectedOption);
        
        // Tambahkan atau hapus tag dari array
        if (selectedOption) {
            // Periksa apakah tag sudah ada dalam array
            const isTagAlreadySelected = selectedTags.some(tag => tag.value === selectedOption.value);
            if (!isTagAlreadySelected) {
                setSelectedTags([...selectedTags, selectedOption]);
            }
        } else {
            // Jika selectedOption null, hapus tag dari array
            setSelectedTags(selectedTags.filter(tag => tag.value !== selectedTag.value));
        }
    };

     // Fungsi untuk menghapus tag dari array
    const handleDeleteTag = (tagToDelete) => {
        setSelectedTags(selectedTags.filter(tag => tag.value !== tagToDelete.value));
    };

    const handleNextClick = () => {
        navigate(`/`); // Arahkan ke halaman index (root)
    };

    
    // Fetch list jemaat
    const getTag = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTag`);
        console.log(response)
    };

    // Fetch Detail Person
    const getPersonDetails = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
        setPersonName(response.data.namaDepan + response.data.namaTengah)
        console.log(response)
    };
    
    
    const saveSession = async (e) => {
    e.preventDefault();
    try {
        // Loop melalui setiap tag yang dipilih dan simpan ke server
        const personId = id
        console.log(personId)
        await Promise.all(selectedTags.map(async (tag) => {
            const userData = {
                personId:personId,
                tagId: tag.value, // Ambil nilai tagId dari tag yang dipilih
                status: "Active",
            };

            console.log(userData)

            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );

            console.log(filteredUserData);

            // Kirim permintaan POST untuk menyimpan tag
            await axios.post(`${process.env.REACT_APP_API_URL}/insertTagList`, filteredUserData);
        }));

        navigate(`/TagListByPerson/${id}`); // Arahkan ke halaman index (root)
    } catch (error) {
        console.log(error);
    }
};
    //Use Effect
    useEffect(()=> {
        getTag();
        getPersonDetails();
    },[]);

    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Add Tag List</div>
            {/* Nama */}
            <div className='is-size-5 mb-2 has-text-weight-semibold'>Nama Jemaat</div>
            <div className="field">
                <label className='control'>
                    <input
                        type="text"
                        className='input'
                        value={personName} 
                        readOnly={true} 
                        placeholder='Nama Event'
                    />
                </label>
            </div>
            <form onSubmit={saveSession}action=''>
            <div className=' is-size-5 mb-2 has-text-weight-semibold'>Nama Tag</div>
                <Select
                    value={selectedTag}
                onChange={handleTagChange}
                    options={optionTag}
                />
                <div className='is-size-5 mt-3 mb-3 has-text-weight-semibold'>Tag Yang dipilih</div>
                <table>
                    <thead>
                        <tr>
                            <th>Tag</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedTags.map(tag => (
                            <tr key={tag.value}>
                                <td>{tag.label}</td>
                                <td>
                                    <button className='button is-danger' onClick={() => handleDeleteTag(tag)}>Hapus</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="columns">
                    <div className="column is-11">
                        <button className='button is-success'>Save</button>
                    </div>
                    <div className="column is-1">
                        <button className='button is-success' onClick={handleNextClick}>Next</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
)
}
export default JemaatTagAdd