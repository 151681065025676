import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css';

const UserEdit = () => {
    const [username, setUserName] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const tokenData = getTokenData();
        if (tokenData) {
            setRole(tokenData.role);
        }
        getUserEdit();
    }, []);

    const getTokenData = () => {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token) {
            try {
                const tokenData = JSON.parse(atob(token.split('.')[1])); // Parsing the payload part of the token
                console.log(tokenData);
                return tokenData;
            } catch (error) {
                console.error('Error parsing token:', error);
                return null;
            }
        } else {
            return null;
        }
    };

    const getUserEdit = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserById/${id}`);
            setUserName(response.data.UserName);
            setEmail(response.data.Email);
            setStatus(response.data.status);
            // Jangan set kata sandi dengan nilai yang di-hash
            // setPassword(response.data.password);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const updateUser = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateUser/${id}`, {
                UserName: username,
                Email: email,
                password: password, // Hanya kirim kata sandi jika diubah
                status: status,
                role: role
            });
            navigate("/");
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    return (
        <div className="column is-mobile mr-2">
            <div className="user-detail-container has-text-centered">
                <form onSubmit={updateUser}>
                    <div className="columns">
                        <div className="column is-11">
                            <div className='is-size-4 mb-3 has-text-weight-bold'>Edit User</div>
                        </div>
                    </div>
                    <hr />
                    <div className="columns">
                        <div className="column is-4">
                            <div className='is-size-5 mb-3 has-text-weight-semibold'>Username</div>
                        </div>
                        <div className="column is-8">
                            <input
                                type="text"
                                className='input'
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                                placeholder='namaDepan'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            <div className='is-size-5 mb-3 has-text-weight-semibold'>Email</div>
                        </div>
                        <div className="column is-8">
                            <input
                                type="text"
                                className='input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='email'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            <div className='is-size-5 mb-3 has-text-weight-semibold'>Password</div>
                        </div>
                        <div className="column is-8">
                            <input
                                type="password"
                                className='input'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='password'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            <div className='is-size-5 mb-3 has-text-weight-semibold'>Status</div>
                        </div>
                        <div className="column is-8">
                            <input
                                type="text"
                                className='input'
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                placeholder='status'
                            />
                        </div>
                    </div>
                    {role === 'master' && (
                        <div className="columns">
                            <div className="column is-4">
                                <div className='is-size-5 mb-3 has-text-weight-semibold'>Role</div>
                            </div>
                            <div className="column is-8">
                                <select
                                    className='input'
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="">Pilih Role</option>
                                    <option value="ketua lifegroup">Ketua Lifegroup</option>
                                    <option value="lg operator">LG Operator</option>
                                    <option value="comms operator">Comms Operator</option>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="event operator">Event Operator</option>
                                    <option value="membership operator">Membership Operator</option>
                                    <option value="master">Master</option>
                                    <option value="pastor">Pastor</option> {/* Koreksi nilai duplikat */}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="control">
                        <button className='button mt-2 is-success' type="submit">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserEdit;
