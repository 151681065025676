import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css';

const UserDetails = () => {
    // State variables
    const [user, setUser] = useState([]);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [activeSection, setActiveSection] = useState('personal');

    const { id } = useParams();
    const navigate = useNavigate();

    // Fetch user details
    useEffect(() => {
        getDetailJemaat();
    }, []);

    const getDetailJemaat = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
            console.log(response);
            setUser(response.data);

            // Extract date components
            const { day, month, year } = splitDate(response.data.tanggalLahir);
            console.log(day);
            console.log(month);
            console.log(year);

            setDay(day);
            setMonth(month);
            setYear(year);
        } catch (error) {
            console.error("Error fetching the data: ", error);
        }
    };

    // Navigation handlers
    const handleEditClick = () => navigate(`/UserList/editUser/${id}`);
    const handleTagClick = () => navigate(`/TagListByPerson/${id}`);
    const handleFamilyClick = () => navigate(`/FamilyMembers/${id}`);
    const handleHistoryClick = () => navigate(`/JemaatHistory/${id}`);

    const handleClick = (section) => {
        setActiveSection(section);
    };

    const splitDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return {
            day: day < 10 ? `0${day}` : day,
            month: month < 10 ? `0${month}` : month,
            year: year
        };
    };

    return (
        <div className="columns">
            <div className="column">
                <div className="columns is-justify-content-flex-end">
                    <div className="custom-button ml-2" onClick={handleEditClick}>Edit Person</div>
                    <div className="custom-button ml-2" onClick={handleTagClick}>Detail Tag</div>
                    <div className="custom-button ml-2" onClick={handleFamilyClick}>Detail Family</div>
                    <div className="custom-button ml-2" onClick={handleHistoryClick}>History</div>
                </div>
                <div className="columns">
                    <div className="column is-4 has-text-centered is-flex is-flex-direction-column is-align-items-center">
                        <div className="is-size-5 mb-4 has-text-weight-bold">
                            {`${user.namaDepan || ''} ${user.namaTengah || ''} ${user.namaBelakang || ''}`.trim()}
                        </div>
                        <div className="is-size-6 mb-4 has-text-weight-normal">
                            {user.email}
                        </div>
                        <div className="is-size-6 mb-4 has-text-weight-normal">
                            {user.nomorTelepon}
                        </div>
                        <div className="card has-text-left">
                            <div
                                className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'personal' ? 'active-section' : ''}`}
                                onClick={() => handleClick('personal')}
                            >
                                Personal Information
                            </div>
                            <div
                                className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'church' ? 'active-section' : ''}`}
                                onClick={() => handleClick('church')}
                            >
                                Church Congregation Information
                            </div>

                            <style jsx>{`
                                .active-section {
                                    background-color: #0082FF;
                                    color: white;
                                    cursor: pointer;
                                }
                                .card div {
                                    padding: 10px;
                                }
                            `}</style>
                        </div>
                    </div>
                    {activeSection === 'personal' ? (
                        <div className="column is-8">
                            <div className="is-size-4 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                                Personal Information
                            </div>
                            <div className="columns">
                                <div className="column is-6">
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Name</div>
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.namaDepan}
                                        readOnly={true}
                                    />
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.namaTengah}
                                        readOnly={true}
                                    />
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.namaBelakang}
                                        readOnly={true}
                                    />
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.namaLain}
                                        readOnly={true}
                                    />
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Gender</div>
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.gender}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="column is-6">
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Place of Birth</div>
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.tempatLahir}
                                        readOnly={true}
                                    />
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Date of Birth</div>
                                    <div className="columns">
                                        <div className="column is-one-third">
                                            <input
                                                type="text"
                                                className="input"
                                                value={day || ''}
                                                readOnly={true}
                                                placeholder="Day"
                                            />
                                        </div>
                                        <div className="column is-one-third">
                                            <input
                                                type="text"
                                                className="input"
                                                value={month || ''}
                                                readOnly={true}
                                                placeholder="Month"
                                            />
                                        </div>
                                        <div className="column is-one-third">
                                            <input
                                                type="text"
                                                className="input"
                                                value={year || ''}
                                                readOnly={true}
                                                placeholder="Year"
                                            />
                                        </div>
                                    </div>
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Life Phase</div>
                                    <input
                                        type="text"
                                        className="input"
                                        value={user.faseHidup || ''}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="column is-8">
                            <div className="is-size-4 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                                Church Congregation Information
                            </div>
                            <div className="columns">
                                <div className="column is-6">
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Local Church</div>
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.lokasiGereja}
                                        readOnly={true}
                                    />
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Longing</div>
                                    <input
                                        type="text"
                                        className='input'
                                        value={user.kerinduan}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="column is-6">
                                    <div className='is-size-6 mb-3 has-text-weight-bold'>Congregational Commitment</div>
                                    <input
                                        type="text"
                                        className='textarea'
                                        value={user.komitmenBerjemaat}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
