import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table'; 
import "../style/tableStyle.css";

// Global Filter Component (for search functionality)
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <div className="control">
            <input
                className="custom-input"
                type="text"
                value={globalFilter || ''}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search"
            />
        </div>
    );
};

const JemaatHistory = () => {
    const [users, setUsers] = useState([]);
    const [namadepan, setNamaDepan] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getTagByUser();
    }, []);

    const getTagByUser = async () => {
        try {
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
            setNamaDepan(responseUser.data.namaDepan + " " + responseUser.data.namaTengah);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonHistory/${id}`);
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Define columns for React Table
    const columns = React.useMemo(
        () => [
            {
                Header: 'Event Name',
                accessor: 'eventName',
                Cell: ({ value }) => <strong>{value}</strong> // Bold event name
            },
            {
                Header: 'Event Date',
                accessor: 'eventDate',
                Cell: ({ value }) => {
                    // Format the date as "07 Jul 2024"
                    const date = new Date(value);
                    return date.toLocaleDateString('id-ID', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                    });
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) =>
                    value === 'lulus' ? (
                        'Lulus'
                    ) : (
                        <em>Tidak Lulus</em> // Italic for non "lulus" status
                    )
            }
        ],
        []
    );


    // React Table instance with pagination and global filter
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, 
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter, 
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data: users,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter, 
        usePagination 
    );

    return (
        <div className="column">
            <div className="columns">
                <div className="column is-2">
                    <div className="is-size-4 mb-3 has-text-weight-bold">Nama Jemaat</div>
                </div>
                <div className="column">
                    <label className="control mb-2">
                        <input
                            type="text"
                            className="input"
                            value={namadepan}
                            readOnly={true} 
                        />
                    </label>
                </div>
            </div>
            <hr />

             {/* Entries per page selector and Global Search */}
            <div className="columns is-vcentered" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Left side: Entries per page selector */}
                <div className="column is-3">
                    <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="control" style={{ marginRight: '10px' }}>
                            <div className="select">
                                <select
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                >
                                    {[10, 25, 50, 100].map(size => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                    </div>
                </div>
                
                {/* Right side: Global Search */}
                <div className="column is-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
            </div>

            <table {...getTableProps()} className="table is-striped is-fullwidth mt-5">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => { 
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: '1', textAlign: 'left' }}>
                    <span>
                        <strong>
                            Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0} 
                        </strong>
                    </span>
                </div> 
                <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                    <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    <span style={{ margin: '0 10px' }}>
                        <button className='custom-button'>
                            {pageIndex + 1}
                        </button>
                    </span>
                    <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                </div>
                <div style={{ flex: '1', textAlign: 'right' }}>
                    <span>
                        <strong>
                            Page: {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default JemaatHistory;
