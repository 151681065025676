import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css';

const DiscipleshipJourneyResult = () => {
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState([]);
    const [checkedMembers, setCheckedMembers] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    // Fetch Event Details
    const getEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            setEventName(response.data.eventName);
        } catch (error) {
            console.error('Error fetching event:', error);
        }
    };

    // Fetch List of Participants
    const getPersonId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewRegistrationList/${id}`);
            console.log(response.data);
            setEventMember(response.data);

            // Initialize checkedMembers state based on the status
            const initialCheckedState = {};
            response.data.forEach(user => {
                initialCheckedState[user.eventRegistrationId] = user.status === 'lulus';
            });
            setCheckedMembers(initialCheckedState);
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    const handleCheckboxChange = (userId) => {
        setCheckedMembers(prevState => ({
            ...prevState,
            [userId]: !prevState[userId]
        }));
    };

    const saveAttendance = async (e) => {
        e.preventDefault();
        try {
            const sessionDataList = eventMember.map(user => ({
                eventRegistrationId: user.eventRegistrationId,
                status: checkedMembers[user.eventRegistrationId] ? 'lulus' : 'tidak'
            }));

            await Promise.all(sessionDataList.map(sessionData => {
                return axios.patch(`${process.env.REACT_APP_API_URL}/updateEventRegistration/${sessionData.eventRegistrationId}`, sessionData);
            }));

            console.log("All attendance data inserted successfully");
            navigate(`/ClassResultView/${id}`);
        } catch (error) {
            console.error('Error saving attendance:', error);
        }
    };

    useEffect(() => {
        getEvent();
        getPersonId();
    }, []);

    return (
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Class Result</div>
                <div className='is-size-5 mb-2 has-text-weight-bold'>Class Name</div>
                <div className="field">
                    <label className='control'>
                        <input
                            type="text"
                            className='input'
                            value={eventName}
                            readOnly={true}
                            placeholder='Nama Event'
                        />
                    </label>
                </div>
                <div className='is-size-4 mb-3 has-text-weight-bold'>Event Participant</div>
                <table style={{ maxHeight: '0px', overflowY: 'auto' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Passed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(eventMember) && eventMember.length > 0 ? (
                            eventMember.map(user => (
                                <tr key={user.eventRegistrationId}>
                                    <td>{user.person.namaDepan}</td>
                                    <td style={{ width: '50px', textAlign: 'center' }}>
                                        <input
                                            type="checkbox"
                                            style={{ transform: 'scale(0.8)', verticalAlign: 'middle' }}
                                            checked={checkedMembers[user.eventRegistrationId]}
                                            onChange={() => handleCheckboxChange(user.eventRegistrationId)}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">Loading</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button className="button is-primary mt-3" onClick={saveAttendance}>Update</button>
            </div>
        </div>
    );
};

export default DiscipleshipJourneyResult;
