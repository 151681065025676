import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

const ActivityLifegroupAddPerLifegroup = () => {
  const { lifegroupId } = useParams();
  const { id } = useParams();
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTimeIn, setEventTimeIn] = useState("");
  const [eventTimeOut, setEventTimeOut] = useState("");
  const [eventCapacity, setEventCapacity] = useState("");
  const [eventSetup, setEventSetup] = useState("");
  const [eventAttendanceTotal, setEventAttendanceTotal] = useState("");
  const [eventLokasi, setEventLokasi] = useState("");
  const [seriesTitle, setSeriesTitle] = useState("");
  const [fasilitator, setFasilitator] = useState("");
  const [optionPerson, setOptionPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  // History Autocomplete
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewActivityLGHistory/${id}`);
        console.log(response);
        setEventDate(response.data.eventDate);
        setEventTimeIn(response.data.eventTimeIn);
        setEventTimeOut(response.data.eventTimeOut);
        setEventLokasi(response.data.eventLokasi);
        setSeriesTitle(response.data.seriesTitle);
        setFasilitator(response.data.fasilitator);
      } catch (error) {
        console.error('Error fetching history:', error);
      }
    };
    fetchHistory();
  }, [id]);
  
  // Person Autocomplete
  useEffect(() => {
    const fetchPersonOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberWithPersonDetails/${id}`);
        const options = response.data.map((dj) => ({
          value: dj.lgMemberId,
          label: `${dj.namaDepan || ''} ${dj.PersonMiddleName || ''} ${dj.PersonLastName || ''}`.trim() || 'No Name Available',
          searchLabel: `${dj.namaDepan || ''} ${dj.PersonMiddleName || ''} ${dj.PersonLastName || ''}`.trim()
        }));
        setOptionPerson(options);
      } catch (error) {
        console.error('Error fetching persons:', error);
      }
    };

    fetchPersonOptions();
  }, [id]);

  
  const handlePersonChange = async (newValue, actionMeta) => {
  console.log('Action Meta:', actionMeta); // For debugging

  if (actionMeta.action === 'create-option') {
    console.log(newValue.label);
    displayPersonModal(newValue.label);

    const notificationData = {
      notificationTypeId: 'YOUR_NOTIFICATION_TYPE_ID', // Replace with your actual notification type ID
      message: `A new person option "${newValue.label}" was added.`,
      recipient: '3114910f-def4-4e0a-a07e-ea0d00f5c11c', // Replace with actual recipient id
      sender: 'System', // Replace with actual sender id
      read: false,
      status: 'unread'
    };
    console.log(notificationData);

    try {
      // Post the notification data to the backend
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertNotification`, notificationData);
      console.log('Notification inserted successfully:', response.data);
    } catch (error) {
      console.error('Error inserting notification:', error);
    }
  } else {
    setSelectedPerson(newValue);
    setFasilitator(newValue ? newValue.label : "");
    setErrors((prevErrors) => ({ ...prevErrors, fasilitator: "" }));
  }
};


  // Function to display modal with search result
  const displayPersonModal = async (personName) => {
    const modal = document.createElement('div');
    modal.className = 'modal is-active';
    modal.innerHTML = `
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" id="modal-content">
          <p>Checking if "${personName}" exists...</p>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    `;
    document.body.appendChild(modal);

    const closeModal = () => {
      document.body.removeChild(modal);
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
      const people = response.data;

      const nameParts = personName.toLowerCase().split(' ');

      const matchedPeople = people.filter(person => {
        const fullNameParts = [
          person.namaDepan?.toLowerCase() || '',
          person.namaTengah?.toLowerCase() || '',
          person.namaBelakang?.toLowerCase() || ''
        ].filter(Boolean);

        return nameParts.some(part => fullNameParts.includes(part));
      });

      const modalContent = document.getElementById('modal-content');
      if (matchedPeople.length > 0) {
        modalContent.innerHTML = `
          <p>People matching "${personName}":</p>
          <div id="person-checkboxes">
            ${matchedPeople.map(person => `
              <div>
                <input type="checkbox" id="person-${person.personId}" value="${person.personId}">
                <label for="person-${person.personId}">${person.namaDepan || ''} ${person.namaTengah || ''} ${person.namaBelakang || ''}</label>
              </div>
            `).join('')}
          </div>
          <p>Or add "${personName}" as a new entry:</p>
          <div class="buttons">
            <button class="button is-success" id="confirmAddBtn">Add Selected</button>
            <button class="button" id="cancelAddBtn">Cancel</button>
          </div>
        `;
      } else {
        modalContent.innerHTML = `
          <p>Name "${personName}" is not in the options. Do you want to add this person?</p>
          <div class="buttons">
            <button class="button is-success" id="confirmAddBtn">Add</button>
            <button class="button" id="cancelAddBtn">Cancel</button>
          </div>
        `;
      }

      document.getElementById('confirmAddBtn')?.addEventListener('click', () => {
        const selectedCheckboxes = document.querySelectorAll('#person-checkboxes input:checked');
        const selectedIds = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);

        if (selectedIds.length > 0) {
          const selectedPerson = matchedPeople.find(person => selectedIds.includes(person.personId.toString()));
          setSelectedPerson({ value: selectedPerson.personId, label: `${selectedPerson.namaDepan || ''} ${selectedPerson.namaTengah || ''} ${selectedPerson.namaBelakang || ''}` });
          setFasilitator(selectedPerson.personId); // Set fasilitator to personId
        } else {
          setSelectedPerson({ value: personName, label: personName });
          setFasilitator(personName);
        }

        closeModal();
      });

      document.getElementById('cancelAddBtn')?.addEventListener('click', () => {
        closeModal();
      });

      document.querySelector('.modal-close').addEventListener('click', () => {
        closeModal();
      });

    } catch (error) {
      console.error('Error fetching person data:', error);
      const modalContent = document.getElementById('modal-content');
      modalContent.innerHTML = `
        <p>Error fetching data. Please try again later.</p>
        <button class="button" id="closeErrorBtn">Close</button>
      `;
      document.getElementById('closeErrorBtn').addEventListener('click', () => {
        closeModal();
      });
    }
  };

  // Validasi
  const validateForm = () => {
    const newErrors = {};

    if (eventTimeIn >= eventTimeOut) {
      newErrors.eventTimeOut = 'Time Out must be greater than Time In';
    }

    if (!seriesTitle) {
      newErrors.seriesTitle = "Series Title is required";
    }

    if (!fasilitator) {
      newErrors.fasilitator = "Fasilitator is required";
    }

    if (!eventDate) {
      newErrors.eventDate = "Event Date is required";
    }

    if (!eventLokasi) {
      newErrors.eventLokasi = "Event Location is required";
    }

    if (!eventTimeIn) {
      newErrors.eventTimeIn = "Time In is required";
    }

    if (!eventTimeOut) {
      newErrors.eventTimeOut = "Time Out is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (errors.eventDate && eventDate) {
      const newErrors = { ...errors };
      delete newErrors.eventDate;
      setErrors(newErrors);
    }
    if (errors.eventLokasi && eventLokasi) {
      const newErrors = { ...errors };
      delete newErrors.eventLokasi;
      setErrors(newErrors);
    }
    if (errors.eventTimeIn && eventTimeIn) {
      const newErrors = { ...errors };
      delete newErrors.eventTimeIn;
      setErrors(newErrors);
    }
    if (errors.eventTimeOut && eventTimeOut) {
      const newErrors = { ...errors };
      delete newErrors.eventTimeOut;
      setErrors(newErrors);
    }
    if (errors.seriesTitle && seriesTitle) {
      const newErrors = { ...errors };
      delete newErrors.seriesTitle;
      setErrors(newErrors);
    }
    if (errors.fasilitator && fasilitator) {
      const newErrors = { ...errors };
      delete newErrors.fasilitator;
      setErrors(newErrors);
    }
  }, [eventDate, eventLokasi, eventTimeIn, eventTimeOut, seriesTitle, fasilitator]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (eventTimeIn >= eventTimeOut) {
      alert('Time Out must be greater than Time In');
      return;
    }

    const formData = new FormData();
    formData.append('eventName', eventName);
    formData.append('eventDate', eventDate);
    formData.append('eventTimeIn', eventTimeIn);
    formData.append('eventTimeOut', eventTimeOut);
    formData.append('eventSetup', eventSetup);
    formData.append('eventAttendanceTotal', eventAttendanceTotal);
    formData.append('eventCapacity', eventCapacity);
    formData.append('eventLokasi', eventLokasi);
    formData.append('seriesTitle', seriesTitle);
    formData.append('fasilitator', fasilitator);
    formData.append('lifegroupId', id);
    formData.append('eventType', 'Activity Lifegroup');
    formData.append('status', 'active');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertEventImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Server response:', response.data);
      const eventId = response.data.Image.eventId;

      if (eventId) {
        const modal = document.createElement('div');
        modal.className = 'modal is-active';
        modal.innerHTML = `
          <div class="modal-background"></div>
          <div class="modal-content">
            <div class="box">
              <p>Data berhasil ditambahkan. Apakah Anda ingin mengisi presensi sekarang?</p>
              <div class="buttons">
                <button class="button is-success" id="presensiBtn">Presensi</button>
                <button class="button is-success" id="okBtn">Tidak</button>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(modal);

        document.getElementById('presensiBtn').addEventListener('click', () => {
          navigate(`/ActivityLGAttendance/${eventId}`, { state: { lifegroupId: lifegroupId } });
          closeModal();
        });

        document.getElementById('okBtn').addEventListener('click', () => {
          navigate('/ActivityLGList');
          closeModal();
        });

        const closeModal = () => {
          document.body.removeChild(modal);
        };
      } else {
        console.error('Event ID is not present in the response.');
      }
    } catch (error) {
      if (error.response) {
        console.error('Server response error:', error);
      } else if (error.request) {
        console.error('Request error:', error.request);
      } else {
        console.error('Error uploading image:', error.message);
      }
    }
  };

  return (
    <div className='column'>
      <form onSubmit={handleFormSubmit}>
        <div className='is-size-4 mb-4 has-text-weight-semibold'>Life Group Event Information</div>
        <hr />
        <div className="columns">
          {/* Event Date */}
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Date</div>
          </div>
          <div className="column is-3">
            <input
              type="date"
              className={`input ${errors.eventDate ? 'is-danger' : ''}`}
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              placeholder='Date'
            />
          </div>
          {/* Time */}
          <div className="column is-1">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Time</div>
          </div>
          <div className="column is-2">
            <input
              type="time"
              className={`input ${errors.eventTimeIn ? 'is-danger' : ''}`}
              value={eventTimeIn}
              onChange={(e) => setEventTimeIn(e.target.value)}
            />
          </div>
          <div className="column is-1">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>To</div>
          </div>
          <div className="column is-2">
            <input
              type="time"
              className={`input ${errors.eventTimeOut ? 'is-danger' : ''}`}
              value={eventTimeOut}
              onChange={(e) => setEventTimeOut(e.target.value)}
            />
          </div>
        </div>
        {/* Location */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Location</div>
          </div>
          <div className="column is-9">
            <div className="field">
              <div className="control">
                <input
                  type="text"
                  className={`input ${errors.eventLokasi ? 'is-danger' : ''}`}
                  value={eventLokasi}
                  onChange={(e) => setEventLokasi(e.target.value)}
                  placeholder='Location'
                />
              </div>
              {errors.eventLokasi && <p className='help is-danger'>{errors.eventLokasi}</p>}
            </div>
          </div>
        </div>
        {/* Series Title */}
        <div className='is-size-4 mb-4 has-text-weight-semibold'>Sermon Information</div>
        <hr />
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
          </div>
          <div className="column is-9">
            <div className="field">
              <div className="control">
                <input
                  type="text"
                  className={`input ${errors.seriesTitle ? 'is-danger' : ''}`}
                  value={seriesTitle}
                  onChange={(e) => setSeriesTitle(e.target.value)}
                  placeholder='Series Title'
                />
              </div>
              {errors.seriesTitle && <p className='help is-danger'>{errors.seriesTitle}</p>}
            </div>
          </div>
        </div>
        {/* Fasilitator */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Fasilitator</div>
          </div>
          <div className="column is-9">
            <div className="field">
              <div className="control">
                <CreatableSelect
                  isClearable
                  options={optionPerson}
                  value={selectedPerson}
                  onChange={handlePersonChange}
                  placeholder='Select or Enter Fasilitator'
                />
              </div>
              {errors.fasilitator && <p className='help is-danger'>{errors.fasilitator}</p>}
            </div>
          </div>
        </div>
        <div className="is-flex is-justify-content-flex-end mt-4">
            <button className='button' style={{
                backgroundColor: '#0052CC',
                color: 'white',
                padding: '10px 24px',
                fontWeight: 'bold',
                borderRadius: '8px'
            }}>
                Save
            </button>
        </div>
      </form>
    </div>
  );
};

export default ActivityLifegroupAddPerLifegroup;
