import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import '../style/UserAddStyle.css';

const DiscipleshipJourneyTypeEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Assuming you pass the ID of the item to be edited through the route params

    // State variables for form data
    const [discipleshipJourneyName, setDiscipleshipJourneyName] = useState("");
    const [discipleshipJourneyPrereq, setDiscipleshipJourneyPrereq] = useState("");

    // State variables for select options and selected values
    const [optionPrereq, setOptionPrereq] = useState([]);
    const [selectedPrereq, setSelectedPrereq] = useState(null);

    // Fetch options for prerequisites
    const fetchPrereqOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType`);
            const options = response.data.map((dj) => ({
                value: dj.discipleshipJourneyTypeId,
                label: dj.discipleshipJourneyTypeName,
            }));
            setOptionPrereq(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
    };

    // Fetch existing data for the item to be edited
    const fetchDiscipleshipJourney = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${id}`);
            const data = response.data;
            setDiscipleshipJourneyName(data.discipleshipJourneyTypeName);

            // Fetch the prerequisite name
            if (data.prereq) {
                const prereqResponse = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${data.prereq}`);
                const prereqData = prereqResponse.data;
                setDiscipleshipJourneyPrereq(prereqData.discipleshipJourneyTypeName);

                // Set the selected prereq option
                const selectedOption = {
                    value: prereqData.discipleshipJourneyTypeId,
                    label: prereqData.discipleshipJourneyTypeName,
                };
                setSelectedPrereq(selectedOption);
            }
        } catch (error) {
            console.error('Error fetching discipleship journey type:', error);
        }
    };

    useEffect(() => {
        fetchPrereqOptions();
    }, []);

    useEffect(() => {
        if (optionPrereq.length > 0 && id) {
            fetchDiscipleshipJourney();
        }
    }, [id, optionPrereq]);

    // Handle select change
    const handlePrereqChange = (selectedOption) => {
        setSelectedPrereq(selectedOption);
        setDiscipleshipJourneyPrereq(selectedOption.value);
    };

    const updateDiscipleshipJourney = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                discipleshipJourneyTypeName: discipleshipJourneyName,
                prereq: discipleshipJourneyPrereq,
            };

            // Filter only properties with values
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData)
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateDiscipleshipJourneyType/${id}`, filteredUserData);
            navigate("/");
        } catch (error) {   
            console.error('Error updating discipleship journey type:', error);
        }
    };

    return (
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Edit Discipleship Journey</div>
                <hr />
                <form onSubmit={updateDiscipleshipJourney}>
                    {/* Name */}
                    <div className='is-size-5 mb-2 has-text-weight-semibold'>Discipleship Journey Name</div>
                    <div className="field">
                        <label className='control'>
                            <input
                                type="text"
                                className='input'
                                value={discipleshipJourneyName}
                                onChange={(e) => setDiscipleshipJourneyName(e.target.value)}
                                placeholder='Discipleship Journey Name'
                            />
                        </label>
                    </div>
                    {/* Prereq */}
                    <div className="columns ">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-2 has-text-weight-semibold'>Prereq (syarat)</div>
                        </div>
                        <div className="column">
                            <Select
                                value={selectedPrereq}
                                onChange={handlePrereqChange}
                                options={optionPrereq}
                                placeholder={discipleshipJourneyPrereq}
                            />
                        </div>
                    </div>
                    <button className='button is-success mt-2'>Save</button>
                </form>
            </div>
        </div>
    );
};

export default DiscipleshipJourneyTypeEdit;
