import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import '../style/tableStyle.css';

const AttendanceListUpdate = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [Session, setSession] = useState({});
    const [Absen, setAbsen] = useState([]);
    const [eventID, setEventID] = useState(null);
    const [Event, setEvent] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable();
        }
    }, [dataFetched]);

    useEffect(() => {
        getSession();
    }, []);

    useEffect(() => {
        if (eventID !== null) {
            getEvent();
            getAbsen();
        }
    }, [eventID]);

    const getAbsen = async () => {
        try {
            console.log(`Fetching attendance list with session ID: ${id}`);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListBySessionId/${id}`);
            setAbsen(response.data);
            console.log('Attendance list fetched successfully:', response.data);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching attendanceListUpdate:', error);
        }
    };

    const getEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${eventID}`);
            setEvent(response.data);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching event:', error);
        }
    };

    const getSession = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewSessionById/${id}`);
            setSession(response.data);
            setEventID(response.data.eventId);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching session:', error);
        }
    };

    const handleCheckboxChange = (attendanceListId) => {
        setAbsen(prevState =>
            prevState.map(absen =>
                absen.attendanceListId === attendanceListId
                    ? { ...absen, kehadiran: !absen.kehadiran }
                    : absen
            )
        );
    };

    const handleUpdate = async () => {
        try {
            const updates = Absen.map(absen => {
                return axios.patch(`${process.env.REACT_APP_API_URL}/updateAttendanceList/${absen.attendanceListId}`, { kehadiran: absen.kehadiran });
            });

            await Promise.all(updates);
            console.log('Attendance list updated successfully');
            alert('Attendance list updated successfully');
        } catch (error) {
            console.error('Error updating attendanceListUpdate:', error);
        }
    };

    return (
        <div className="column">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Attendance List Update </div>
            <hr />
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Event</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={Event.eventName || ''}
                            readOnly={true}
                        />
                    </label>
                </div>
            </div>
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Session</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={Session.sessionName || ''}
                            readOnly={true}
                        />
                    </label>
                </div>
            </div>
            <div className="table-container">
                <table id="dataTable" className="table">
                    <thead>
                        <tr>
                            <th>Nama Peserta</th>
                            <th>Status Kehadiran</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Absen.map((absen) => (
                            <tr key={absen.attendanceListId}>
                                <td>{`${absen.namaDepan} ${absen.namaTengah || ''}`}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={absen.kehadiran}
                                        onChange={() => handleCheckboxChange(absen.attendanceListId)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button onClick={handleUpdate} className="button is-primary mt-3">Update Attendance</button>
        </div>
    );
};

export default AttendanceListUpdate;
