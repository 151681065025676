import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation  } from 'react-router-dom';



const UserTelegramSendMessage = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const location = useLocation();
    const selectedUsers = location.state.selectedUsers;
    const [message, setMessage] = useState('');


// Memastikan bahwa selectedUsers sekarang berisi nilai integer
    console.log(selectedUsers);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserTelegram`);
            setUsers(response.data);
            const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/viewMessage`);
            console.log(response2);
            console.log(selectedUsers)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);


    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Kirim pesan ke fungsi broadcast
            console.log(message)
            const messageValue = message
            console.log(messageValue)
            await axios.post(`${process.env.REACT_APP_API_URL}/broadcast`, { selectedUsers, message: messageValue });
            const insertMessage =  await axios.post(`${process.env.REACT_APP_API_URL}/insertMessage`,
                { socialMedia:"Telegram", Message: messageValue ,status:"active" });
            const messageid = insertMessage.data.MessageId
            for (const user of selectedUsers) {
                console.log('user'+user)
                const penerimaId=user
                console.log('penerimaId'+penerimaId)
                await axios.post(`${process.env.REACT_APP_API_URL}/insertMessagePerson`, { PenerimaId: penerimaId, MessageId: messageid });
            }
            // Redirect ke halaman lain setelah broadcast berhasil

            navigate("/");
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    return (
        <div>
            <div className="is-size-4 mb-3 has-text-weight-bold">Send Message</div>
            <hr />
            <div className="is-size-4 mb-3 has-text-weight-semibold">Input Message</div>
            <hr />
                <div>
                    <textarea
                            className='textarea'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder=' Message '
                            />
                </div>
                <br />
                <button className="button is-primary"  onClick={handleSubmit}>Send Broadcast</button>
            </div>
    );
};

export default UserTelegramSendMessage;
