import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams , Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const EventAttendanceList = () => {
    const [eventName, setEventName] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const [EventRegistration, setEventRegistration] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);

    useEffect(()=>{
        getUserById();
        getEventRegistByEventId();
    }, []);

    const getUserById= async () => {
        const response = await axios.get(`http://broko.li/backend/viewEvent/${id}`);
            setEventName(response.data.eventName)
    };

    const getEventRegistByEventId= async () => {
        const response = await axios.get(`http://broko.li/backend/viewRegistrationList/${id}`);
            console.log(response)
            setEventRegistration(response.data)
    };

    
return (
<div className="column">
    <div className='is-size-4 mb-3 has-text-weight-bold'>Detail Event Participant</div>
    <hr />
    {/* Event Name */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Name</div>
        </div>
        <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={eventName}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
        </div>
    </div>
    <Link to={`/AddEventParticipant/${id}`} className="button"> Add Event Participant </Link>
    {/* Table */}
    <div className="table-container">
        <table id="dataTable" className="table">
            <thead>
                <tr>
                    <th>Action</th>
                    <th>Nama</th>
                    {/* <th>Jumlah Kehadiran</th>
                    <th>Status</th> */}
                </tr>
            </thead>
            <tbody>
                {EventRegistration.map(Event => (
                    <tr key={Event.eventId}>
                        <td>
                            <Link to={`/userlist/userDetails/${Event.person.personId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                        </td>
                        <td>{Event.person.namaDepan} </td>
                        {/* <td>{Event.sessionDate}</td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <hr />
</div>

)
}
export default EventAttendanceList