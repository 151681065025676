import React, { useState, useEffect, useRef } from 'react';
import '../style/ModalStyle.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GmailModal = ({ isOpen, onClose, selectedUsers }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const confirmButtonRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            confirmButtonRef.current?.focus();
        }
    }, [isOpen]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    };

    const handleConfirm = async () => {
        setShowConfirmation(false);

        try {
            console.log('Message:', message);
            console.log('Subject:', subject);
            console.log('Selected Users:', selectedUsers);

            const url = `${process.env.REACT_APP_API_URL}/broadcastGmail`;
            console.log(url);

            // Send the broadcast message
            const broadcastResponse = await axios.post(url, {
                selectedUsers,
                subject,
                message: message
            });
            console.log('Broadcast Response:', broadcastResponse.data);

            // Insert the message
            const insertMessageResponse = await axios.post(`${process.env.REACT_APP_API_URL}/insertMessage`, {
                socialMedia: "Gmail",
                Body: subject,
                status: "active",
                Subject: message
            });
            console.log('Insert Message Response:', insertMessageResponse.data);

            const messageId = insertMessageResponse.data.MessageId;
            if (!messageId) {
                throw new Error('Message ID is not returned from insertMessage API.');
            }

            // Insert message for each user
            for (const user of selectedUsers) {
                console.log('Processing User:', user);
                const insertMessagePersonResponse = await axios.post(`${process.env.REACT_APP_API_URL}/insertMessagePerson`, {
                    PenerimaId: user,
                    MessageId: messageId
                });
                console.log('Insert Message Person Response:', insertMessagePersonResponse.data);
            }

            // Close the modal and show a success message
            alert('Message has been successfully sent!');
            navigate("/");

        } catch (error) {
            console.error('Error sending message:', error);
            // Log additional error details if available
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <div 
                className="modal is-active" 
                role="dialog" 
                aria-labelledby="modalTitle" 
                aria-describedby="modalMessage"
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: '1050',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <div 
                    className="modal-card" 
                    style={{ 
                        backgroundColor: 'white', 
                        padding: '20px', 
                        borderRadius: '6px',
                        width: '400px',
                        maxWidth: '90%',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        position: 'relative',
                        margin: '0',
                        boxSizing: 'border-box',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#005EB8',
                            height: '2.5rem',
                            width: '100%',
                            borderTopLeftRadius: '6px',
                            borderTopRightRadius: '6px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                    />

                    <div className="has-text-centered has-background-white" style={{ paddingTop: '40px' }}>
                        <p className="title is-4">New Message</p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label className="label">Subject</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Enter subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
                                <textarea
                                    className="textarea"
                                    placeholder="Type your message here"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                        <div className="has-text-centered mt-5">
                            <button
                                className="button mr-4"
                                onClick={onClose}
                                style={{ backgroundColor: '#929292', color: 'white' }}
                                type="button"
                            >
                                Cancel
                            </button>

                            <button
                                className="button"
                                style={{ backgroundColor: '#005EB8', color: 'white' }}
                                type="submit"
                                ref={confirmButtonRef}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {showConfirmation && (
                <>
                    <div 
                        className="modal-overlay" 
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Darkened background
                            zIndex: '1050', // Behind the modal
                        }}
                    />
                    <div 
                        className="modal is-active" 
                        role="dialog" 
                        aria-labelledby="confirmationTitle" 
                        aria-describedby="confirmationMessage"
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '1060', // Above the overlay
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div 
                            className="modal-card" 
                            style={{ 
                                backgroundColor: 'white', 
                                padding: '0', // Reset padding to 0
                                borderRadius: '8px', // Slightly larger border radius
                                width: '100rem', // Increased width
                                maxWidth: '90%',
                                height: '60rem', // Increased height for the modal
                                boxShadow: '0 8px 16px rgba(0,0,0,0.3)', // More pronounced shadow
                                position: 'relative',
                            }}
                        >
                            <div 
                                className="modal-card-head" 
                                style={{
                                    backgroundColor: '#005EB8', // Blue background for the header
                                    padding: '2rem', // Increased padding for the header
                                    borderTopLeftRadius: '8px', // Matching border radius
                                    borderTopRightRadius: '8px', // Matching border radius
                                    color: 'white',
                                    height: '2rem', // Increased height for the header
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                }}
                            >
                                <div className="title is-4 has-text-white">Confirmation Detail</div>
                            </div>
                            
                            <div 
                                className="modal-card-body" 
                                style={{ 
                                    paddingTop: '5rem', // Padding for the body
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'calc(100% - 12rem)', // Adjust height to fit within modal
                                }}
                            >   
                                <div className="title is-4 pl-2">Please confirm your details</div>
                                <div 
                                    className="title is-5 pl-2"
                                    style={{
                                        backgroundColor: '#E0DBDB', // Highlight color
                                        padding: '1.8rem', // Padding for better readability
                                        borderRadius: '4px', // Rounded corners for the highlight
                                        overflow: 'hidden', // Hide overflow
                                        textOverflow: 'ellipsis', // Add ellipsis for overflowed text
                                        whiteSpace: 'nowrap', // Prevent text from wrapping
                                        display: 'flex', // Enable Flexbox
                                        alignItems: 'center', // Vertically center text
                                        // justifyContent: 'center', // Horizontally center text
                                        height: 'auto', // Adjust height as needed
                                    }}
                                >
                                    {selectedUsers.join(', ')}
                                </div>
                                <div className="title is-5 pl-2 has-text-weight-bold">Subject</div>
                                <div className="title is-6 pl-2 ">{subject}</div>
                                <div className="title is-5 pl-2 has-text-weight-bold">Message</div>
                                <div className="title is-6 pl-2 ">{message}</div>
                                
                                <div style={{ marginTop: 'auto', textAlign: 'right' }}> {/* Pushes button to the bottom right */}
                                    <button
                                        className="button"
                                        style={{ backgroundColor: '#005EB8', color: 'white' }}
                                        onClick={handleConfirm}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </>
            )}



        </>
    );
};

export default GmailModal;
