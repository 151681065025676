import React, { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Chart, registerables } from 'chart.js';
import { Bar,Line } from "react-chartjs-2";
import "../style/DashboardStyle.css";
import $ from 'jquery';

Chart.register(...registerables);

const DashboardDiscipleshipJourney = () => {
    
    // Upcoming Events
    const [events, setEvent] = useState([]);
    
    //  DiscJourney By Date
    const [startDateDJACtivityByDate, setstartDateDJACtivityByDate] = useState([]);
    const [endDateDJACtivityByDate, setendDateDJACtivityByDate] = useState([]);
    const [countDJActivityByDate, setCountDJActivityByDate] = useState([]);
    const [dateDJACtivityByDate, setDateDJACtivityByDate] = useState([]);

    //  DiscJourney By Type
    const [startDateDJTypeACtivityByDate, setstartDateDJTypeACtivityByDate] = useState([]);
    const [endDateDJTypeACtivityByDate, setendDateDJTypeACtivityByDate] = useState([]);
    const [countDJTypeActivityByDate, setCountDJTypeActivityByDate] = useState([]);
    const [dateDJTypeACtivityByDate, setDateDJTypeACtivityByDate] = useState([]);

    // others
    const navigate = useNavigate();
    const [dataFetched, setDataFetched] = useState(false);
    const [range, setRange] = useState('');

    useEffect(() => {
        getUpcomingEvent();
    }, []);

    // Add Button
    const handleSelectChange = (event) => {
        const value = event.target.value;
        if (value === "add-event") {
            navigate("/addEvent"); // Menggunakan navigate untuk navigasi ke "/addEvent"
        } else if (value === "add-activityLG") {
            navigate("/ActivityLGAdd"); // Menggunakan navigate untuk navigasi ke "/addUser"
        }
        else if (value === "add-disc-journey-class") {
            navigate("/AddDiscipleshipJourneyClass"); // Menggunakan navigate untuk navigasi ke "/addUser"
        }
        else if (value === "add-disc-journey") {
            navigate("/AddDiscipleshipJourney"); // Menggunakan navigate untuk navigasi ke "/addUser"
        }
    };

    // Backend
    // Upcoming Event
    const getUpcomingEvent = async () => {
        try {
            const responseEvent = await axios.get(`${process.env.REACT_APP_API_URL}/viewUpcomingEvent`);
            console.log(responseEvent);

            // Filter hanya event dengan eventType "Discipleship Journey"
            const filteredEvents = responseEvent.data.filter(event => event.eventType === 'Discipleship Journey');

            setEvent(filteredEvents);
        } catch (error) {
            console.log(error);
        }
    };

    // DiscJourney
    const getDJByDate = async (startDate, endDate) => {
        try {
            console.log(startDate, endDate)
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewDicsipleshipJourneyEvent/${formatDate2(startDate)}/${formatDate2(endDate)}`);
            const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/viewDicsipleshipJourneyEvent/${(startDate)}/${(endDate)}`);
            console.log(response2)
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            console.log(startDate, endDate)
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const eventsCountPerWeek = {};

            // Menghitung selisih hari antara startDate dan endDate
            const differenceInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));


            if (differenceInDays === 7) {
                // Inisialisasi objek untuk menyimpan jumlah acara setiap hari
                const eventsCountPerDay = {};

                // Hitung jumlah acara dalam satu minggu dari hari ini
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Mendapatkan tanggal satu minggu yang lalu

                // Filter acara yang terjadi dalam satu minggu terakhir
                const eventsInPastWeek = eventData.filter(event => {
                    const eventDate = new Date(event.eventDate);
                    return eventDate >= oneWeekAgo;
                });

                // Membuat struktur data yang merepresentasikan semua hari dalam satu minggu terakhir
                for (let i = 0; i < 8; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const formattedDate = date.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    eventsCountPerDay[formattedDate] = 0; // Inisialisasi jumlah acara untuk setiap hari dengan nilai nol
                }
                // Log initialized event counts
                console.log("Initialized Events Count Per Day:", eventsCountPerDay);

                // Mengisi jumlah acara yang sesuai untuk setiap hari yang memiliki acara
                eventsInPastWeek.forEach(event => {
                    const eventDate = new Date(event.eventDate);
                    const formattedDate = eventDate.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    if (eventsCountPerDay[formattedDate] !== undefined) {
                        eventsCountPerDay[formattedDate]++;
                    }
                });

                const dates = Object.keys(eventsCountPerDay);
                const counts = Object.values(eventsCountPerDay);

                console.log(dates)
                console.log(counts)

                setCountDJActivityByDate(dates);
                setDateDJACtivityByDate(counts);
            }
            else if (differenceInDays <= 31) {
                // Jika rentang tanggal kurang dari atau sama dengan 31 hari (sekitar satu bulan)

                // Salin nilai endDate agar tidak mengubah nilai aslinya
                const akhir = new Date(endDate);
                akhir.setDate(akhir.getDate() - 30);

                const minggu = [];
                const today = new Date();
                let currentDate = new Date(startDate); // Gunakan startDate sebagai titik awal iterasi

                while (currentDate <= endDate) {
                    const weekStartDate = new Date(currentDate);
                    let weekEndDate = new Date(currentDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 6);

                    // Jika tanggal akhir minggu melebihi hari ini, atur tanggal akhir minggu menjadi hari ini
                    if (weekEndDate > today) {
                        weekEndDate = new Date(today);
                    }

                    minggu.push([weekStartDate, weekEndDate]); // Tambahkan rentang minggu dalam bentuk array

                    currentDate.setDate(currentDate.getDate() + 7);
                }

                // Inisialisasi setiap rentang minggu dengan nilai 0
                minggu.forEach(([start, end]) => {
                    const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[weekKey] = 0;
                });

                console.log("Data acara:");
                console.log(eventData);

                eventData.forEach(event => {
                    console.log("Tanggal acara:", event.eventDate);
                    const eventDate = new Date(event.eventDate);
                    let eventProcessed = false; // Tambahkan variabel boolean untuk menandai apakah tanggal acara sudah diproses

                    for (const [start, end] of minggu) {
                        console.log("Rentang minggu yang diuji:", start, "-", end);
                        if (eventDate >= start && eventDate <= end) {
                            console.log("Tanggal acara cocok dengan rentang minggu! " + start, "-", end);
                            const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                            console.log(eventsCountPerWeek)
                            eventsCountPerWeek[weekKey]++;
                            console.log(eventsCountPerWeek)
                            console.log(weekKey)
                            eventProcessed = true;
                            break;
                        }
                    }
                    console.log(eventsCountPerWeek)

                    if (!eventProcessed) {
                        console.log("Tanggal acara tidak cocok dengan rentang minggu:", eventDate);
                        // Temukan rentang minggu terdekat
                        let nearestWeek;
                        let nearestDiff = Infinity;
                        for (const [start, end] of minggu) {
                            const diffStart = Math.abs(eventDate - start);
                            const diffEnd = Math.abs(eventDate - end);
                            const minDiff = Math.min(diffStart, diffEnd);
                            if (minDiff < nearestDiff) {
                                nearestDiff = minDiff;
                                nearestWeek = [start, end];
                            }
                        }
                        // Masukkan tanggal acara ke rentang minggu terdekat
                        const [nearestStart, nearestEnd] = nearestWeek;
                        const nearestWeekKey = nearestStart.toISOString().slice(5, 10); // Only take MM-DD
                        eventsCountPerWeek[nearestWeekKey]++;
                    }
                });

                console.log("Hasil pencatatan acara per minggu:");
                console.log(eventsCountPerWeek);

                const dates = Object.keys(eventsCountPerWeek);
                const counts = Object.values(eventsCountPerWeek);
                setCountDJActivityByDate(dates);
                setDateDJACtivityByDate(counts);
            }
            else if (differenceInDays <= 366) {
                // Jika rentang tanggal kurang dari atau sama dengan 365 hari (sekitar satu tahun)
                const startYear = startDate.getFullYear(); // Tahun awal
                const endYear = endDate.getFullYear(); // Tahun akhir

                // Inisialisasi objek untuk menyimpan jumlah acara per bulan
                const eventsCountPerMonth = {};

                // Loop melalui setiap tahun dalam rentang waktu
                for (let year = startYear; year <= endYear; year++) {
                    const startMonth = (year === startYear) ? startDate.getMonth() : 0; // Bulan awal
                    const endMonth = (year === endYear) ? endDate.getMonth() : 11; // Bulan akhir

                    // Loop melalui setiap bulan dalam tahun yang sedang diproses
                    for (let month = startMonth; month <= endMonth; month++) {
                        const firstDayOfMonth = new Date(year, month, 1); // Tanggal pertama bulan
                        const lastDayOfMonth = new Date(year, month + 1, 0); // Tanggal terakhir bulan

                        // Inisialisasi jumlah acara pada bulan ini
                        let eventCountThisMonth = 0;

                        // Loop melalui setiap acara
                        eventData.forEach(event => {
                            const eventDate = new Date(event.eventDate);
                            // Periksa apakah acara berada dalam rentang bulan yang sedang diproses
                            if (eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth) {
                                eventCountThisMonth++;
                            }
                        });

                        // Simpan jumlah acara pada bulan ini ke objek eventsCountPerMonth
                        const monthKey = `${year}-${month + 1}`; // Format key "tahun-bulan"
                        eventsCountPerMonth[monthKey] = eventCountThisMonth;
                    }
                }

                console.log("Hasil pencatatan acara per bulan:");
                console.log(eventsCountPerMonth);

                // Ambil tanggal-tanggal dan jumlah acara per bulan
                const months = Object.keys(eventsCountPerMonth);
                const eventCounts = Object.values(eventsCountPerMonth);

                // Lakukan sesuatu dengan data ini, misalnya, kirim ke fungsi setCounteventByMonth dan setDateEventByMonth
                setCountDJActivityByDate(months);
                setDateDJACtivityByDate(eventCounts);
            }

            else {
                console.log("Range of dates is not set" + differenceInDays);
                setCountDJActivityByDate(0);
                setDateDJACtivityByDate(0);

            }

        } catch (error) {
            console.log(error);
        }
    };

     // DiscJourney
    const getDJTypeByDate = async (startDate, endDate) => {
        try {
            console.log(startDate, endDate)
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent`);
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            console.log(startDate, endDate)
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const eventsCountPerWeek = {};

            // Menghitung selisih hari antara startDate dan endDate
            const differenceInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));


            if (differenceInDays === 7) {
                // Inisialisasi objek untuk menyimpan jumlah acara setiap hari
                const eventsCountPerDay = {};

                // Hitung jumlah acara dalam satu minggu dari hari ini
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Mendapatkan tanggal satu minggu yang lalu

                // Filter acara yang terjadi dalam satu minggu terakhir
                const eventsInPastWeek = eventData.filter(event => {
                    const eventDate = new Date(event.eventDate);
                    return eventDate >= oneWeekAgo;
                });

                // Membuat struktur data yang merepresentasikan semua hari dalam satu minggu terakhir
                for (let i = 0; i < 8; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const formattedDate = date.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    eventsCountPerDay[formattedDate] = 0; // Inisialisasi jumlah acara untuk setiap hari dengan nilai nol
                }
                // Log initialized event counts
                console.log("Initialized Events Count Per Day:", eventsCountPerDay);

                // Mengisi jumlah acara yang sesuai untuk setiap hari yang memiliki acara
                eventsInPastWeek.forEach(event => {
                    const eventDate = new Date(event.eventDate);
                    const formattedDate = eventDate.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    if (eventsCountPerDay[formattedDate] !== undefined) {
                        eventsCountPerDay[formattedDate]++;
                    }
                });

                const dates = Object.keys(eventsCountPerDay);
                const counts = Object.values(eventsCountPerDay);

                console.log(dates)
                console.log(counts)

                setCountDJTypeActivityByDate(dates);
                setDateDJTypeACtivityByDate(counts);
            }
            else if (differenceInDays <= 31) {
                // Jika rentang tanggal kurang dari atau sama dengan 31 hari (sekitar satu bulan)

                // Salin nilai endDate agar tidak mengubah nilai aslinya
                const akhir = new Date(endDate);
                akhir.setDate(akhir.getDate() - 30);

                const minggu = [];
                const today = new Date();
                let currentDate = new Date(startDate); // Gunakan startDate sebagai titik awal iterasi

                while (currentDate <= endDate) {
                    const weekStartDate = new Date(currentDate);
                    let weekEndDate = new Date(currentDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 6);

                    // Jika tanggal akhir minggu melebihi hari ini, atur tanggal akhir minggu menjadi hari ini
                    if (weekEndDate > today) {
                        weekEndDate = new Date(today);
                    }

                    minggu.push([weekStartDate, weekEndDate]); // Tambahkan rentang minggu dalam bentuk array

                    currentDate.setDate(currentDate.getDate() + 7);
                }

                // Inisialisasi setiap rentang minggu dengan nilai 0
                minggu.forEach(([start, end]) => {
                    const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[weekKey] = 0;
                });

                console.log("Data acara:");
                console.log(eventData);

                eventData.forEach(event => {
                    console.log("Tanggal acara:", event.eventDate);
                    const eventDate = new Date(event.eventDate);
                    let eventProcessed = false; // Tambahkan variabel boolean untuk menandai apakah tanggal acara sudah diproses

                    for (const [start, end] of minggu) {
                        console.log("Rentang minggu yang diuji:", start, "-", end);
                        if (eventDate >= start && eventDate <= end) {
                            console.log("Tanggal acara cocok dengan rentang minggu! " + start, "-", end);
                            const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                            console.log(eventsCountPerWeek)
                            eventsCountPerWeek[weekKey]++;
                            console.log(eventsCountPerWeek)
                            console.log(weekKey)
                            eventProcessed = true;
                            break;
                        }
                    }
                    console.log(eventsCountPerWeek)

                    if (!eventProcessed) {
                        console.log("Tanggal acara tidak cocok dengan rentang minggu:", eventDate);
                        // Temukan rentang minggu terdekat
                        let nearestWeek;
                        let nearestDiff = Infinity;
                        for (const [start, end] of minggu) {
                            const diffStart = Math.abs(eventDate - start);
                            const diffEnd = Math.abs(eventDate - end);
                            const minDiff = Math.min(diffStart, diffEnd);
                            if (minDiff < nearestDiff) {
                                nearestDiff = minDiff;
                                nearestWeek = [start, end];
                            }
                        }
                        // Masukkan tanggal acara ke rentang minggu terdekat
                        const [nearestStart, nearestEnd] = nearestWeek;
                        const nearestWeekKey = nearestStart.toISOString().slice(5, 10); // Only take MM-DD
                        eventsCountPerWeek[nearestWeekKey]++;
                    }
                });

                console.log("Hasil pencatatan acara per minggu:");
                console.log(eventsCountPerWeek);

                const dates = Object.keys(eventsCountPerWeek);
                const counts = Object.values(eventsCountPerWeek);
                setCountDJTypeActivityByDate(dates);
                setDateDJTypeACtivityByDate(counts);
            }
            else if (differenceInDays <= 366) {
                // Jika rentang tanggal kurang dari atau sama dengan 365 hari (sekitar satu tahun)
                const startYear = startDate.getFullYear(); // Tahun awal
                const endYear = endDate.getFullYear(); // Tahun akhir

                // Inisialisasi objek untuk menyimpan jumlah acara per bulan
                const eventsCountPerMonth = {};

                // Loop melalui setiap tahun dalam rentang waktu
                for (let year = startYear; year <= endYear; year++) {
                    const startMonth = (year === startYear) ? startDate.getMonth() : 0; // Bulan awal
                    const endMonth = (year === endYear) ? endDate.getMonth() : 11; // Bulan akhir

                    // Loop melalui setiap bulan dalam tahun yang sedang diproses
                    for (let month = startMonth; month <= endMonth; month++) {
                        const firstDayOfMonth = new Date(year, month, 1); // Tanggal pertama bulan
                        const lastDayOfMonth = new Date(year, month + 1, 0); // Tanggal terakhir bulan

                        // Inisialisasi jumlah acara pada bulan ini
                        let eventCountThisMonth = 0;

                        // Loop melalui setiap acara
                        eventData.forEach(event => {
                            const eventDate = new Date(event.eventDate);
                            // Periksa apakah acara berada dalam rentang bulan yang sedang diproses
                            if (eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth) {
                                eventCountThisMonth++;
                            }
                        });

                        // Simpan jumlah acara pada bulan ini ke objek eventsCountPerMonth
                        const monthKey = `${year}-${month + 1}`; // Format key "tahun-bulan"
                        eventsCountPerMonth[monthKey] = eventCountThisMonth;
                    }
                }

                console.log("Hasil pencatatan acara per bulan:");
                console.log(eventsCountPerMonth);

                // Ambil tanggal-tanggal dan jumlah acara per bulan
                const months = Object.keys(eventsCountPerMonth);
                const eventCounts = Object.values(eventsCountPerMonth);

                // Lakukan sesuatu dengan data ini, misalnya, kirim ke fungsi setCounteventByMonth dan setDateEventByMonth
                setCountDJTypeActivityByDate(months);
                setDateDJTypeACtivityByDate(eventCounts);
            }

            else {
                console.log("Range of dates is not set" + differenceInDays);
                setCountDJActivityByDate(0);
                setDateDJACtivityByDate(0);

            }

        } catch (error) {
            console.log(error);
        }
    };
    
    // Button Filter Date
    // Discipleship Journey
    const handleRangeChangeDJActivity = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateDJACtivityByDate(currentDate)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateDJACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateDJACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateDJACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        console.log(startDate, currentDate)
        getDJByDate(startDate, currentDate);
    };
     // Discipleship Journey Type
    const handleRangeChangeDJTypeActivity = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateDJTypeACtivityByDate(currentDate)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateDJTypeACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateDJTypeACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateDJTypeACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        console.log(startDate, currentDate)
        getDJTypeByDate(startDate, currentDate);
    };
    
    // Chart 
    const ChartComponent = ({ dataY, dataX, namaChartY, namaChartX }) => {
        const data = {
            labels: dataY, // Label untuk setiap bar
            datasets: [
                {
                    label: 'Count', // Label untuk dataset secara keseluruhan
                    data: dataX, // Menggunakan prop 'countservice' langsung
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
                }
            ]
        };

        const options = {
            scales: {
                y: {
                    type: 'linear',
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: namaChartY, // Label untuk sumbu Y
                        font: {
                            size: 16,
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: namaChartX, // Label untuk sumbu X
                        font: {
                            size: 16,
                        }
                    }
                }
            }
        };

        return <Line data={data} options={options} />;
    };

    // Format tanggal
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Asia/Jakarta', // Set zona waktu ke WIB (Asia/Jakarta)
            weekday: 'long', // Tampilkan nama hari dalam bahasa Indonesia
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return new Intl.DateTimeFormat('id-ID', options).format(date);
    };

    const formatDate2 = (date) => {
    if (!(date instanceof Date)) {
        console.error("Invalid date object provided");
        return null; // Or handle the error in another way
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);




    return (
        <div className="columns">
            <div className="column">
                <div className="columns">
                    <div className="column is-4">
                        <div className='is-size-3 mb-4 has-text-weight-bold'>Dashboard Discipleship journey </div>
                    </div>
                    <div className="column is-offset-6 is-2">
                        <div className="select">
                            <select id="kategori" name="kategori" onChange={handleSelectChange}>
                                <option value="" disabled selected hidden>+ Add</option>
                                <option value="add-event">Add Event</option>
                                <option value="add-disc-journey-class">Add Discipleship Journey Class</option>
                                <option value="add-disc-journey">Add Discipleship Journey</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    {/* Discipleship Journey */}
                    <div className="column">
                        <div className="card">
                            <div className='is-size-5 mb-4 has-text-weight-bold'>Discipleship Journey</div>
                            <div className="buttons">
                                <button className={`button ${range === '1W' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeDJActivity('1W')}>1W</button>
                                <button className={`button ${range === '1M' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeDJActivity('1M')}>1M</button>
                                <button className={`button ${range === '1Y' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeDJActivity('1Y')}>1Y</button>
                                {/* Tambahkan tombol-tombol lainnya sesuai dengan kebutuhan */}
                            </div>
                            <ChartComponent dataX={dateDJACtivityByDate} dataY={countDJActivityByDate} namaChartY={"Count Discipleship Journey"} namaChartX={"Date"} /> {/* Render ChartComponent */}
                        </div>
                    </div> 
                    {/* Upcoming Event */}
                    <div className="column">
                        <div className="table-container card">
                            <table id="dataTable" className="table mt-5">
                                <div className='is-size-5 mb-4 has-text-weight-bold'>Upcoming Discipleship Journey</div>
                                <tbody>
                                    {events.map(Event => (
                                        <tr key={Event.eventId}>
                                            <td>{Event.discipleshipJourneyTypeName} </td>
                                            <td>{formatDate(Event.eventDate)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardDiscipleshipJourney;
