import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'
import { event } from 'jquery';


const EventParticipantAddByRecomemendation = () => {

    // Untuk data yang ke DB
    
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const [notes, setNotes] = useState("");
    const [Discjourney, setDiscJourney] = useState("");
    const [Discjourney2, setDiscJourney2] = useState("");
    const [SyaratKelas, setSyaratKelas] = useState([]);


    const navigate = useNavigate();
    const {id} = useParams();
    
    // Suggest Jemaat
    const [suggested, setSuggested] = useState([]);
    let jemaatList = "";

    // Suggest Event
    const [suggestedEvent, setSuggestedEvent] = useState([]);
    let eventList = "";

    
    // Fetch list jemaat
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggested(response.data);
        console.log(response)
        jemaatList = response.data;
        console.log(jemaatList);
    };
    
    // Fetch list jemaat
    const getEvent = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent`);
            // Filter data dengan eventType "Discipleship Journey" dan status bukan "selesai"
            const filteredData = response.data.filter(
                (event) => event.eventType === "Discipleship Journey" && event.status !== "selesai"
            );
            setSuggestedEvent(filteredData);
            console.log(filteredData);
            jemaatList = filteredData;
            console.log(jemaatList);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };


    const saveParticipant = async (e) => {
        e.preventDefault();
        try {
            // Fetch the current list of registrations
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewRegistrationList/${id}`);
            console.log(response);
            // Extract the list of registered person IDs
            const registeredPersonIds = response.data.map(registration => registration.person.personId);

            // Check if the eventMember is already registered
            if (registeredPersonIds.includes(eventMember)) {
                alert("Participant is already registered.");
                return;
            }

            const userData = {
                eventId,
                personId: eventMember,
                // Discjourney 2 ganti ke dtail evnent
                discipleshipJourneyTypeId: Discjourney2,
                registrationStatus: {
                    registStatus: 'Pending',
                    status: "Active",          // Contoh status awal
                    notes  // Catatan atau informasi tambahan
                    // fileurl:"";
                }
            };

            // Filter only properties with values
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData);

            // Insert the new registration
            await axios.post(`${process.env.REACT_APP_API_URL}/insertEventRegistration`, filteredUserData);
            alert("Participant has been successfully registered.");
        } catch (error) {
            console.error('Error during registration:', error);
            alert("An error occurred during registration. Please try again later.");
        }
    };


    //Use Effect
    useEffect(()=> {
        getJemaat();
        getEvent();
    },[]);

    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <form onSubmit={saveParticipant}action=''>
                <label className='control mb-2'>
                    <div className=' is-size-6 mb-2 has-text-weight-bold'>Nama Jemaat</div>
                        <select className='input' value={eventMember} onChange={(e) => setEventMember(e.target.value)}>
                            <option value=""> Nama Jemaat </option>
                            {/* Check if jemaatList is an array before mapping over it */}
                            {Array.isArray(suggested) && suggested.map((jemaat) => (
                                <option key={jemaat.personId} value={jemaat.personId}>
                                    {jemaat.namaDepan} {/* Menggunakan nilai jemaat.eventName sebagai teks opsi */}
                                </option>
                            ))}
                        </select>
                </label>
                <label className='control mb-2'>
                    <div className=' is-size-6 mb-2 has-text-weight-bold'>Event Name</div>
                        <select className='input' value={eventId} onChange={(e) => setEventId(e.target.value)}>
                            <option value=""> EventName </option>
                            {/* Check if jemaatList is an array before mapping over it */}
                            {Array.isArray(suggestedEvent) && suggestedEvent.map((event) => (
                                <option key={event.eventId} value={event.eventId}>
                                    {event.eventName} {/* Menggunakan nilai event.eventName sebagai teks opsi */}
                                </option>
                            ))}
                        </select>
                </label>
                <label className='control mb-2'>
                    <div className='is-size-6 mb-2 has-text-weight-bold'>Notes</div>
                    <textarea
                        className='textarea'  // Menggunakan className 'textarea' untuk styling input area
                        value={notes}         // State untuk menyimpan isi notes
                        onChange={(e) => setNotes(e.target.value)}  // Update nilai notes saat input berubah
                        placeholder="Add any notes here" // Placeholder untuk memberikan petunjuk
                    />
                </label>
                
                <div className="control">
                    <button className='button is-success mt-2'>Save</button>
                </div>
            </form>
        </div>
    </div>
)
}
export default EventParticipantAddByRecomemendation