import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams ,Link } from 'react-router-dom';
import '../style/UserAddStyle.css'

const DetailUser = () => {

    // Untuk data yang ke DB
    const [userName, setuserName] = useState("");
    const [role, setrole] = useState("");
    const [email, setemail] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();
    const [User, setUser] = useState([]);
    const [lgMembers, setlgMembers] = useState([]);
    const [lgMembers2, setlgMembers2] = useState([]);

    //Use Effect
    useEffect(()=> {
        getDetailUser();
    },[]);

    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getDetailUser = async () => {
        console.log(id)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserById/${id}`);
        setUser(response.data)
        console.log(response)
    };

    const handleEditClick = () => {
        navigate(`/UserEdit/${id}`);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };
    
    return (
        <div className="column is-mobile mr-2">
            <div className="columns">
                <div className="column is-3 is-offset-9">
                    <div className="custom-button" onClick={handleEditClick}>Edit User</div>
                </div>
            </div>
            <div className="user-detail-container has-text-centered">
                <div className='is-size-4 mb-5 has-text-weight-bold'> {User.UserName}</div>
                <div className="columns">
                    <div className="column is-6 column-overflow">
                        <div className=' is-size-5 mb-3 has-text-weight-bold'>Email</div>
                    </div>
                    <div className="column is-6 column-overflowx">
                        {User.Email}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className='is-size-5 mb-3 has-text-weight-bold'>Password</div>
                    </div>
                    <div className="column is-6" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {User.password ? User.password.split('').map(() => '*').join('') : 'No Password'}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className=' is-size-5 mb-3 has-text-weight-bold'>Status</div>
                    </div>
                    <div className="column is-6">
                        {User.status}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className=' is-size-5 mb-3 has-text-weight-bold'>Role</div>
                    </div>
                    <div className="column is-6">
                        {User.role}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className=' is-size-5 mb-3 has-text-weight-bold'>Date User</div>
                    </div>
                    <div className="column is-6">
                        {formatDate(User.createdAt)}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DetailUser
