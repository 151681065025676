// Sidebar.js

import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom'; // Jika Anda menggunakan React Router
import '../style/SidebarStyle.css'
import axios from 'axios';
import { get } from 'jquery';

function Sidebar({ isSidebarVisible, onToggleSidebar }) {    

    const [isSidebar, setIsSidebar] = useState(true);
    const [isLGOpen, setIsLGOpen] = useState(false);
    const [isEventsOpen, setIsEventsOpen] = useState(false);
    const [isDJOpen, setIsDJOpen] = useState(false);
    const [isCommsOpen, setIsCommsOpen] = useState(false);
    const [issJemaat, setIssJemaat] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [idUser, setIdUser] = useState("");
    const [idPerson, setIdPerson] = useState("");
    const [idLG, setIdLG] = useState("");


    const [role,setRole] = useState("");
    

    const toggleLGMenu = () => {
        setIsLGOpen(!isLGOpen);
    };

     // Fungsi toggle untuk submenu Events
    const toggleEventsMenu = () => {
        setIsEventsOpen(!isEventsOpen);
    };

    const toggleDJMenu = () => {
        setIsDJOpen(!isDJOpen);
    };

    const toogleCommsMenu = () => {
        setIsCommsOpen(!isCommsOpen);
    };

    const toogleProfileMenu = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const toogleHomeMenu = () => {
        navigate('/');
        };

    const toggleSidebar = () => {
        // setIsSidebar(!isSidebar);
        // console.log(isSidebar)
        setIsSidebar(prevState => {
        const newState = !prevState;
            onToggleSidebar(newState);
            return newState;
        }); 
    };

    const sidebarClassName = isSidebar ? 'sidebar open' : 'sidebar';

    const navigate = useNavigate();

    const handleLogout = () => {
    // Lakukan proses logout di sini, seperti menghapus token dari localStorage dan kemudian mengarahkan pengguna ke halaman login
    localStorage.removeItem('token');
    console.log('terhapus')
    navigate('/login'); // Hapus token dari localStorage
    // Lakukan navigasi ke halaman login atau halaman lain yang sesuai
};
    useEffect(() => {
        const tokenData = getTokenData();
        if (tokenData) {
            // Set role and user ID from token data
            setRole(tokenData.role);
            setIdUser(tokenData.userId);
            setIdPerson(tokenData.personId);
            // Tidak perlu
            getLG(tokenData.personId); // Pass the personId directly to the getLG function
        }
    }, []);

    // get Token Data
    const getTokenData = () => {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token) {
            try {
                const tokenData = JSON.parse(atob(token.split('.')[1])); // Parsing the payload part of the token
                console.log(tokenData);
                return tokenData;
            } catch (error) {
                console.error('Error parsing token:', error);
                return null;
            }
        } else {
            return null;
        }
    };

    const getLG = async (personId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberWithPersonId/${personId}`);
            console.log(response);
            setIdLG(response.data.lifegroupId);
            console.log(response.data.lifegroupId);
        } catch (error) {
            console.error(error);
        }
    };

    // Render Menu
    const renderDashboard = () => {
        if( role === 'master'){
            return (
                <li>
                        <Link to="/Dashboard" className="svg-link-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-icon">
                                <path fill="currentColor" d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/>
                            </svg>
                            <div className='ml-2 has-text-weight-semibold'>Dashboard</div>
                        </Link>
                </li>
            );
        }
    }
    const renderEvent = () => {
        if(role == 'event operator' || role =='master'){
            return (
                <li>
                    <div onClick={toggleEventsMenu} className="svg-link-container" style={{ display: 'flex', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-icon" style={{ width: '24px', height: '24px' }}>
                            <path fill="currentColor" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H432c-8.8 0-16-7.2-16-16V432c0-8.8 7.2-16 16-16zM128 432c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H144c-8.8 0-16-7.2-16-16V432z"/>
                        </svg>
                        <div className='ml-2 is-size-6 has-text-weight-semibold'>Events</div>
                    </div>
                    {isEventsOpen && (
                        <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0', }}>
                            <li>
                                <Link to="/eventDashboard" style={{ display: 'block', textDecoration: 'none', color: '#005EB8',fontSize: '14px'}}>
                                    Event Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to="/EventList" style={{ display: 'block', textDecoration: 'none', color: '#005EB8',fontSize: '14px'}}>
                                    Event Management
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>
            );
        }
        else{
            return null;
        }
        }
    const renderLG = () => {
        if (role == 'lg operator' || role == 'master' || role == 'ketua lifegroup'){
            if(role == 'ketua lifegroup'){
                return (
                    <li>
                        <div onClick={toggleLGMenu} className="svg-link-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.08em" height="1em" viewBox="0 0 1920 1792">
                                <path fill="currentColor" d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256m1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5M640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181m704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640m576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353m-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181"/>
                            </svg>
                            <div className='ml-2 has-text-weight-semibold'>Lifegroups</div>
                        </div>
                        {isLGOpen && (
                            <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0' }}>
                                <li>
                                    <Link 
                                        to={`/MyDashboardLg/${idLG}`} 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        My Lifegroup Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`/ActivityLGListPerLG/${idLG}`} 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        My Life Group Activity
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                );
            }
            else{
                return (
                    <li>
                        <div onClick={toggleLGMenu} className="svg-link-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.08em" height="1em" viewBox="0 0 1920 1792">
                            <path fill="currentColor" d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256m1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5M640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181m704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640m576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353m-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181"/>
                        </svg>
                            <div className='ml-2 has-text-weight-semibold'>Lifegroups</div>
                        </div>
                        {isLGOpen && (
                            <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0' }}>
                                <li>
                                    <Link  
                                        to="/LifegroupDashboard" 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        Life Group Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/LifegroupList" 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        Life Group Management
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/ActivityLGList" 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        Life Group Activity
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`/MyDashboardLg/${idLG}`} 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        My Lifegroup Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`/ActivityLGListPerLG/${idLG}`} 
                                        style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                    >
                                        My Life Group Activity
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                );
            }
        }
        else{
            return null;
        }
            
        } 
    const renderDJ = () => {
        if (role == 'event operator' || role == 'master'){
            return (
                <li>
                <div onClick={toggleDJMenu} className="svg-link-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.85 21.125q-2.6-1.2-4.225-3.625T2 12.025q0-.65.063-1.275t.212-1.225l-1.15.675l-1-1.725L4.9 5.725l2.75 4.75l-1.75 1l-1.35-2.35q-.275.675-.412 1.4T4 12.025q0 2.425 1.325 4.413t3.525 2.937zM15.5 9V7h2.725q-1.15-1.425-2.775-2.212T12 4q-1.375 0-2.6.425t-2.25 1.2l-1-1.75Q7.4 3 8.875 2.5T12 2q1.975 0 3.775.738T19 4.874V3.5h2V9zm-.65 15l-4.775-2.75l2.75-4.75l1.725 1l-1.425 2.45q2.95-.425 4.913-2.675T20 12q0-.275-.012-.513T19.925 11h2.025q.025.25.038.488T22 12q0 3.375-2.013 6.038t-5.237 3.587l1.1.65z"/>
                </svg>
                    <div className='ml-2 has-text-weight-semibold'>Discipleship Journey</div>
                </div>
                {isDJOpen && (
                    <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0' }}>
                        <li>
                            <Link 
                                to="/DiscipleshipJourneyDashboard" 
                                style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                            >
                                Discipleship Journey Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to="/DiscipleshipJourneyList" 
                                style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                            >
                                Discipleship Journey Manage
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to="/DiscipleshipJourneyClassList" 
                                style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                            >
                                Discipleship Journey Class
                            </Link>
                        </li>
                    </ul>
                )}
            </li>
        );
        }
        else{
            return null;
        }
    } 
    const renderUser = () => {
        if(role === "master" || role === "membership operator"){
            return (
                <li>
                    <Link to="/UserList" className='is-size-6' style={{ margin: 0 }}>
                        <div className="svg-link-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-icon">
                                <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                            </svg>
                            <div className='ml-2 has-text-weight-semibold'>People</div>
                        </div>
                    </Link>
                </li>
            );
        }
        return null;
        
    } 
    const renderManagement = () => {
        if(role === "master"){
            return (
                <li>
                <Link to="/Management" className='is-size-6' style={{ margin: 0 }}>  
                    <div className="svg-link-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024" className='svg-icon'>
                            <path fill="currentColor" d="M512.5 390.6c-29.9 0-57.9 11.6-79.1 32.8c-21.1 21.2-32.8 49.2-32.8 79.1s11.7 57.9 32.8 79.1c21.2 21.1 49.2 32.8 79.1 32.8s57.9-11.7 79.1-32.8c21.1-21.2 32.8-49.2 32.8-79.1s-11.7-57.9-32.8-79.1a110.96 110.96 0 0 0-79.1-32.8m412.3 235.5l-65.4-55.9c3.1-19 4.7-38.4 4.7-57.7s-1.6-38.8-4.7-57.7l65.4-55.9a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a442.5 442.5 0 0 0-79.6-137.7l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.2 28.9c-30-24.6-63.4-44-99.6-57.5l-15.7-84.9a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52-9.4-106.8-9.4-158.8 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.3a353.4 353.4 0 0 0-98.9 57.3l-81.8-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a445.9 445.9 0 0 0-79.6 137.7l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.2 56.5c-3.1 18.8-4.6 38-4.6 57c0 19.2 1.5 38.4 4.6 57l-66 56.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.3 44.8 96.8 79.6 137.7l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.8-29.1c29.8 24.5 63 43.9 98.9 57.3l15.8 85.3a32.05 32.05 0 0 0 25.8 25.7l2.7.5a448.3 448.3 0 0 0 158.8 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-84.9c36.2-13.6 69.6-32.9 99.6-57.5l81.2 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.5-87.4 79.6-137.7l.9-2.6c4.3-12.4.6-26.3-9.5-35m-412.3 52.2c-97.1 0-175.8-78.7-175.8-175.8s78.7-175.8 175.8-175.8s175.8 78.7 175.8 175.8s-78.7 175.8-175.8 175.8"/>
                        </svg>
                        <div className='ml-2 has-text-weight-semibold'>Settings</div>
                    </div>
                </Link>
            </li>
        );
    }
    } 
    const renderComms = () => {
        if (role === 'comms Operator' || role == 'master' || role == 'event operator') {
            return (
                <li>
                    <div onClick={toogleCommsMenu} className="svg-link-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" className='svg-icon'>
                            <path fill="currentColor" d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06"/>
                        </svg>

                        <div className='ml-2 has-text-weight-semibold'>Comms</div>
                    </div>
                    {isCommsOpen && (
                        <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0' }}>
                            <li>
                                <Link 
                                    to="/ChooseReceiverTelegram" 
                                    style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                >
                                    Telegram
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/Gmail" 
                                    style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                                >
                                    Gmail
                                </Link>
                            </li>
                        </ul>
                    )}
                </li>
            )
        }
        else{
            return null;
        }
    }   
    const renderProfile = () => {
            return (
                <li>
                <div onClick={toogleProfileMenu} className="svg-link-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" className='svg-icon'>
                        <g fill="none" fill-rule="evenodd">
                            <path d="M24 0v24H0V0z"/>
                            <path fill="currentColor" d="M20 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zM9.5 12h-2a2.5 2.5 0 0 0-2.495 2.336L5 14.5v.5a1 1 0 0 0 1.993.117L7 15v-.5a.5.5 0 0 1 .41-.492L7.5 14h2a.5.5 0 0 1 .492.41l.008.09v.5a1 1 0 0 0 1.993.117L12 15v-.5a2.5 2.5 0 0 0-2.336-2.495zm8.5 1h-4l-.117.007a1 1 0 0 0 0 1.986L14 15h4l.117-.007a1 1 0 0 0 0-1.986zm-2-3h-2a1 1 0 0 0-.117 1.993L14 12h2a1 1 0 0 0 .117-1.993zM8.5 8a1.75 1.75 0 1 0 0 3.5a1.75 1.75 0 0 0 0-3.5"/>
                        </g>
                    </svg>
                    <div className='ml-2 has-text-weight-semibold'>Profile</div>
                </div>
                {isProfileOpen && (
                    <ul className="submenu" style={{ paddingLeft: '5vh', margin: '0' }}>
                        <li>
                            <Link 
                                to={`/userlist/userDetails/${idPerson}`} 
                                style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                            >
                                Profile
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to={`/Detailuser/${idUser}`} 
                                style={{ display: 'block', textDecoration: 'none', color: '#005EB8', fontSize: '14px' }}
                            >
                                Account
                            </Link>
                        </li>
                    </ul>
                )}
            </li>
            );
        }
    const renderHome = () => {
        return (
            <li className='mt-5 '>
                <div onClick={toogleHomeMenu} className="svg-link-container">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-icon">
                        <path fill="currentColor" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                    </svg>
                    <div className='ml-2 has-text-weight-semibold'>Home</div>
                </div>
            </li>
        );
    }


    

useEffect(() => {
        getTokenData();
    }, []);


return (
        <div>
            {/* <div className="sidebar-toggle" onClick={toggleSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='black'width="1em" height="1em" >
                    <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/>
                </svg>
            </div> */}
            {isSidebar && (
                <div className={sidebarClassName}>
                    <div
                        className='is-size-4  has-text-weight-bold'
                        style={{ height: '12vh', display: 'flex', alignItems: 'center', marginTop:'0px', marginBottom:"12px",  }}
                    >
                        Every Nation
                    </div>
                        <ul>
                        {renderHome()}
                        {/* Dashboard */}
                        {renderDashboard()}
                        {/* Events */}
                        {renderEvent()}
                        {/* LG Events */}
                        {renderLG()}
                        {/* Discipleship Journey */}
                        {renderDJ()}                       
                        {/* User Mangement */}
                        {renderUser()}
                        {/* Management */}
                        {renderManagement()}
                        {/* Comms */}
                        {renderComms()}
                        {/* Profile */}
                        {renderProfile()} 
                        <li>
                            {/* <button onClick={handleLogout} className="logout-button">Logout</button> */}
                            {/* <button onClick={handleLogout} className='button mt-2 is-danger'>Logout</button> */}
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );

};

export default Sidebar;
