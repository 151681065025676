import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import '../style/UserAddStyle.css';

const LifegroupMembersAdd = () => {
    const [lifegroupName, setLifegroupName] = useState("");
    const [lifegroupMember, setLifegroupMember] = useState("");
    const [lifegroupId, setLifegroupId] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();
    const [lgMembers, setLgMembers] = useState([]);
    const [lgMembers2, setLgMembers2] = useState([]);

    const [suggested, setSuggested] = useState([]);
    const [optionPerson, setOptionPerson] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [errors, setErrors] = useState({});

    const getLifegroupDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${id}`);
            console.log(response);
            setLifegroupName(response.data.lifegroupName);
            setLifegroupId(response.data.lifegroupId);
        } catch (error) {
            console.error('Error fetching lifegroup details:', error);
            alert('Lifegroup ID not found. Redirecting to home page.');
            navigate('/');
        }
    };

    const getPersonDetails = async (personId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${personId}`);
            console.log(response.data);
            setLgMembers2(prevState => [...prevState, response.data]);
        } catch (error) {
            console.error('Error fetching person details:', error);
        }
    };

    const getMember = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberByLGId/${id}`);
            setLgMembers(response.data);
            console.log(lgMembers);
            lgMembers.forEach(item => {
                getPersonDetails(item.personId);
            });
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const getJemaat = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
            setSuggested(response.data);
            console.log(response);
        } catch (error) {
            console.error('Error fetching jemaat:', error);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!lifegroupMember) {
            newErrors.lifegroupMember = "Please select a lifegroup member";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const saveLGMember = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            if (lgMembers.some(member => member.personId === lifegroupMember)) {
                alert("Person is already a member of the lifegroup");

                // Reset selectedPerson and lifegroupMember so Select remains functional
                setSelectedPerson(null);
                setLifegroupMember("");

                return;
            }

            const userData = {
                lifegroupId,
                personId: lifegroupMember,
                status: "active",
            };

            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData);
            await axios.post(`${process.env.REACT_APP_API_URL}/insertLifeGroupMember`, filteredUserData);
            alert("Data successfully added");
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchPersonOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
                console.log(response);
                const options = response.data.map((dj) => ({
                    value: dj.personId,
                    label: (
                        <div>
                            <div>
                                {`${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''}`.trim() || 'No Name Available'}
                            </div>
                        </div>
                    ),
                    searchLabel: `${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''}`.trim()
                }));
                console.log(options);
                setOptionPerson(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchPersonOptions();
    }, []);

    const handlePersonChange = (selectedOption) => {
        setSelectedPerson(selectedOption);
        console.log('Selected DJ:', selectedOption);
        setLifegroupMember(selectedOption.value);
        setErrors((prevErrors) => ({ ...prevErrors, lifegroupMember: "" }));
    };

    const handleSelectFocus = () => {
        setSelectedPerson(null); // Reset jika ada masalah
        setLifegroupMember(""); // Bersihkan person yang dipilih
    };

    // Custom filQter function for react-select
    const customFilter = (option, searchText) => {
        if (option.data.searchLabel.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        getLifegroupDetails();
        getJemaat();
        getMember();
    }, []);

    return (
        <div className="columns is-mobile mr-2">
            <div className="column">
                <form onSubmit={saveLGMember} action=''>
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                        </div>
                        <div className="column is-6">
                            <div className="field">
                                <label className='control'>
                                    <input
                                        type="text"
                                        className='input'
                                        value={lifegroupName}
                                        readOnly={true}
                                        placeholder='Nama Lifegroup'
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'> Lifegroup Member </div>
                        </div>
                        <div className="column is-6">
                            <label className='control mb-2'>
                                <Select
                                    value={selectedPerson}
                                    onChange={handlePersonChange}
                                    onFocus={handleSelectFocus} 
                                    options={optionPerson}
                                    isSearchable={true}
                                    filterOption={customFilter}
                                    placeholder='Select a lifegroup member'
                                />
                                {errors.lifegroupMember && <p className="help is-danger">{errors.lifegroupMember}</p>}
                            </label>
                        </div>
                    </div>
                    <div className="is-flex is-justify-content-flex-end mt-4">
                        <button className='button' style={{
                        backgroundColor: '#0052CC',
                        color: 'white',
                        padding: '10px 24px',
                        fontWeight: 'bold',
                        borderRadius: '8px'
                        }}>
                        Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LifegroupMembersAdd;
