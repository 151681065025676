import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";

const MessageList = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewMessage`);
                console.log(response)
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        getUsers();
    }, []);

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deletePerson/${id}`);
            setUsers(prevUsers => prevUsers.filter(user => user.personId !== id));
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };

    const updateMessage = async (id, status) => {
        console.log(status, id);
        if (status === 'active') {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateMessage/${id}`, { status: 'deactive' });
            console.log(`Success update Message ${id} to inactive status`);
        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateMessage/${id}`, { status: 'active' });
            console.log(`Success update Message ${id} to active status`);
        }
        window.location.reload();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Subject',
                accessor: 'Subject',
                Cell: ({ row }) => (
                    <Link to={`/MessageDetailPerson/${row.original.MessageId}`}>
                        {row.original.Subject || row.original.Message || '-'}
                    </Link>
                ),
            },
            {
                Header: 'Body',
                accessor: 'Body',
                Cell: ({ value }) => (value ? value : '-'),
            },
            {
                Header: 'Social Media',
                accessor: 'socialMedia',
            },
            {
                Header: 'Action',
                accessor: 'actions',
                Cell: ({ row }) => {
                    const { MessageId, status } = row.original;
                    const isActive = status === 'active';
                    
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '36px' }}>
                            <button
                                onClick={() => updateMessage(MessageId, status)}
                                style={{ 
                                    background: 'none', 
                                    border: 'none', 
                                    cursor: 'pointer', 
                                    padding: '0',
                                    color: '#0082FF'
                                }}
                            >
                                {isActive ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                        <path fill="#00A84D" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                            <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                        </path>
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                        <path fill="#FF0000" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                            <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                        </path>
                                    </svg>
                                )}
                            </button>
                        </div>
                    );
                }
            }
        ],
        []
    );

    const data = useMemo(() => users, [users]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <h1 className="is-size-4 mb-4 ml-1 has-text-weight-bold">Message</h1>
                <div className="buttons mt-4">
                    <Link to="/Gmail" className="button is-success">Add Message via Gmail</Link>
                    <Link to="/ChooseReceiverTelegram" className="button is-primary">Add Message via Telegram</Link>
                </div>
                <div className="columns mt-2">
                    <div className="column is-2">
                        <div className="field is-grouped">
                            <div className="select">
                                <select
                                    value={pageSize}
                                    onChange={e => setPageSize(Number(e.target.value))}
                                    className="select"
                                >
                                    {[10, 25, 50, 100].map(size => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="control">
                                <label className="label ml-2 ">entries per page</label>
                            </div>
                        </div>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="control">
                            <input
                                className="custom-input"
                                type="text"
                                value={globalFilter || ''}
                                onChange={e => setGlobalFilter(e.target.value)}
                                placeholder="Search"
                            />
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <table {...getTableProps()} className="table is-striped is-fullwidth">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageList;
