import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import "../style/tableStyle.css";

const LifegroupActivityListPerLg = () => {
    const [discipleshipJourneys, setDiscipleshipJourneys] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [selectedJourneyId, setSelectedJourneyId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('mulai');
    const [globalFilter, setGlobalFilter] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getDiscipleshipJourneys();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            setDataFetched(false); // Reset flag after data is set
        }
    }, [dataFetched]);

    const getDiscipleshipJourneys = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewActivityLifegroup`);
            const filteredData = response.data.filter(item => item.lifegroupId === id); // Filter data by ID
            setDiscipleshipJourneys(filteredData);
            console.log(filteredData);
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const updateEvent = async (id) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/deactivateEvent/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };

    const openModal = (id) => {
        setSelectedJourneyId(id);
        setIsModalActive(true);
    };

    const closeModal = () => {
        setIsModalActive(false);
        setSelectedJourneyId(null);
        setSelectedStatus('mulai');
    };

    const checkData = (value) => {
        if (value === undefined || value === null || value === '') {
            return <em>Tidak ada data</em>;
        }
        return value;
    };

    const columns = useMemo(() => [
        {
            Header: 'Date',
            accessor: 'eventDate',
            Cell: ({ value }) => checkData(formatDate(value)),
        },
        {
            Header: 'Time In',
            accessor: 'eventTimeIn',
            Cell: ({ value }) => checkData(value),
        },
        {
            Header: 'Time Out',
            accessor: 'eventTimeOut',
            Cell: ({ value }) => checkData(value),
        },
        {
            Header: 'Action',
            accessor: 'actions',
            Cell: ({ row }) => {
                const { lifegroupId, status, eventId } = row.original;
                const isActive = status === 'active'; // Check if status is 'active'
                
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '36px' }}>
                        <Link
                            to={`/ActivityLGDetails/${eventId}`} 
                            className="button is-info is-small"
                            style={{ backgroundColor: '#0082FF', color: 'white' }}
                        >
                            Details
                        </Link>
                        <button
                            onClick={() => updateEvent(eventId)} // Pass the event ID
                            style={{ 
                                background: 'none', 
                                border: 'none', 
                                cursor: 'pointer', 
                                padding: '0',
                                color: '#0082FF' // Set text color to match button color
                            }}
                        >
                            {isActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                    <path fill="#00A84D" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                    <path fill="#FF0000" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            )}
                        </button>
                    </div>
                );
            }
        }
    ], []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter: setGlobalFilterTable,
        setPageSize
    } = useTable(
        { 
            columns, 
            data: discipleshipJourneys, 
            initialState: { pageIndex: 0, pageSize: 10, globalFilter: globalFilter } 
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-1">
                    <div className="column is-7">
                        <div className="custom-button mr-2" onClick={() => navigate(`/ActivityLGAddPerLG/${id}`)}>Add Lifegroup Activity</div>
                        <div className="custom-button" onClick={() => navigate(`/addLifegroupMember/${id}`)}>Add Lifegroup Member</div>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => {
                                        setGlobalFilter(e.target.value);
                                        setGlobalFilterTable(e.target.value);
                                    }}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifegroupActivityListPerLg;
