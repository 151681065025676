    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import { useNavigate, useParams, Link } from 'react-router-dom';
    import $ from 'jquery';
    import 'datatables.net';
    import '../style/tableStyle.css';

    const Attendance = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [Session, setSesion] = useState([]);
    const [Absen, setAbsen] = useState([]);
    const [eventID, setEventID] = useState(null); // Initialize eventID to null
    const [Event, setEvent] = useState({});

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (dataFetched) {
        $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);

    useEffect(() => {
        getSession();
    }, []);

    useEffect(() => {
        if (eventID !== null) {
        getEvent();
        getAbsen();
        }
    }, [eventID]);

    const getAbsen = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListBySessionId/${id}`);
        setAbsen(response.data);
        console.log(response)
        setDataFetched(true);
        } catch (error) {
        console.error('Error fetching attendance:', error);
        }
    };

    const getEvent = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${eventID}`);
        setEvent(response.data);
        setDataFetched(true);
        } catch (error) {
        console.error('Error fetching event:', error);
        }
    };

    const getSession = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewSessionById/${id}`);
        setSesion(response.data);
        setEventID(response.data.eventId);
        setDataFetched(true);
        } catch (error) {
        console.error('Error fetching session:', error);
        }
    };

    return (
        <div className="column">
        <div className='is-size-4 mb-3 has-text-weight-bold'>Detail Attendance List</div>
        <hr />
        <div className="control mb-5">
            {Absen.length > 0 ? (
            // Edit Attendance link
            <Link to={`/UpdateAttendanceList/${id}`} className="button is-info "> Edit Attendance </Link>
            ) : (
            // Add Attendance link
            <Link to={`/AddAttendanceList/${id}`} className="button is-info "> Add Attendance </Link>
            )}
            {/* Event Registration link */}
            {eventID && (
            <Link to={`/EventRegistration/${eventID}`} className="button is-info " style={{ marginLeft: '10px' }}> Event Registration </Link>
            )}
        </div>
        {/* Event Name */}
        <div className="columns">
            <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Event</div>
            </div>
            <div className="column is-3">
            <label className='control mb-2'>
                <input
                type="text"
                className='input'
                value={Event.eventName || ''}
                readOnly={true} // Make input readonly
                />
            </label>
            </div>
        </div>
        {/* Session Name */}
        <div className="columns">
            <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Session</div>
            </div>
            <div className="column is-3">
            <label className='control mb-2'>
                <input
                type="text"
                className='input'
                value={Session.sessionName || ''}
                readOnly={true} // Make input readonly
                />
            </label>
            </div>
        </div>
        {/* Table */}
        <div className="table-container">
            <table id="dataTable" className="table">
            <thead>
                <tr>
                <th>Nama Peserta</th>
                <th>Status Kehadiran</th>
                </tr>
            </thead>
            <tbody>
                {Absen.map((absen) => (
                <tr key={absen.attendanceListId}>
                    <td>{`${absen.namaDepan} ${absen.namaTengah || ''}`}</td>
                    <td>{absen.kehadiran ? 'Hadir' : 'Tidak Hadir'}</td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
        </div>
    );
    };

    export default Attendance;
