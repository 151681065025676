import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import '../style/UserAddStyle.css'


const ActivityLifegroupSessionAdd = () => {

    // Untuk data yang ke DB
    const [eventName, setEventName] = useState("");
    const [eventType, setEventType] = useState("");
    const [lgname, setLGName] = useState("");

    const [eventMember, setEventMember] = useState([]);
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    // Inputan
    const [SessionName, setSessionName] = useState("");
    const [SessionDate, setSessionDate] = useState("");

    // State untuk menyimpan status centang
    const [checkedMembers, setCheckedMembers] = useState({});
    
    // Untuk Sugest 
    const [suggested, setSuggested] = useState([]);
    let jemaatList = "";

    // Mengakses state yang dikirimkan
    const location = useLocation();
    const lifegroupId = location.state && location.state.lifegroupId;


    const getEventId = async () => {
        const eventId = id ; // Replace with actual event id logic
        setEventId(id);
        console.log(lifegroupId)
    };

    // Fetch Detail Event
    const getEvent = async () => {
        console.log(eventId);
        const response = await axios.get(`http://broko.li/backend/viewEvent/${eventId}`);
        console.log(response);
        const { eventName, eventType, lifeGroupId } = response.data;
        setEventName(eventName);
        setEventType(eventType);
    };

    // Fetch List of Person Id
    const getPersonId = async () => {
        console.log(eventType);
        if (eventType === 'Activity Lifegroup') {
            const response = await axios.get(`http://broko.li/backend/viewLifeGroupMemberWithPersonDetails/${lifegroupId}`);
            console.log(response);
            const members = response.data;
            setEventMember(members);
            console.log(members);
            // Initialize checkbox status
            const initialCheckedState = {};
            members.forEach(user => {
                initialCheckedState[user.personId] = false;
            });
            setCheckedMembers(initialCheckedState);
        } else {
            const response = await axios.get(`http://broko.li/backend/viewRegistrationList/${eventId}`);
            console.log(response);
            const members = response.data;
            setEventMember(members);
            console.log(members);
            // Initialize checkbox status
            const initialCheckedState = {};
            members.forEach(user => {
                initialCheckedState[user.personId] = false;
            });
            setCheckedMembers(initialCheckedState);
        }
    };

   useEffect(() => {
    getEventId();
  }, []);

  useEffect(() => {
    if (eventId) {
      getEvent();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventType) {
      getPersonId();
    }
  }, [eventType]);




    const handleCheckboxChange = (userId, personId) => {
        setCheckedMembers(prevState => ({
            ...prevState,
            [userId]: !prevState[userId]
        }));
    
        return personId; // Mengembalikan nilai personId
    };
    
    

    const saveAttendance = async (e) => {
        e.preventDefault();
        try {
            const sessionDataList = []; // Daftar data sesi untuk disimpan
    
            // Loop melalui semua anggota acara
            eventMember.forEach(user => {
                // Periksa apakah pengguna ini dicentang
                const isAttended = checkedMembers[user._id];
    
                // Tentukan status kehadiran berdasarkan apakah pengguna dicentang atau tidak
                const attendanceStatus = isAttended ? true : false;
    
                // Buat objek data sesi untuk pengguna ini
                const sessionData = {
                    sessionId: id,
                    personId: user._id.toString(),
                    kehadiran: attendanceStatus,
                    eventType: eventType
                };
    
                // Tambahkan objek data sesi ke daftar
                sessionDataList.push(sessionData);
            });
    
            // Simpan semua data sesi ke server
            await Promise.all(sessionDataList.map(sessionData => {
                console.log(`Inserting attendance for personId: ${sessionData.personId}`);
                return axios.post('http://broko.li/backend/insertAttendanceList', sessionData);
            }));
    
            console.log("All attendance data inserted successfully");
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };
    
    
    
    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Attendance List Add</div>
            {/* Nama */}
            <div className='is-size-5 mb-2 has-text-weight-bold'>Nama Event</div>
            <div className="field">
                <label className='control'>
                    <input
                        type="text"
                        className='input'
                        value={eventName} 
                        readOnly={true} 
                        placeholder='Nama Event'
                    />
                </label>
            </div>
            <table style={{ maxHeight: '0px', overflowY: 'auto' }}>
                <thead>
                    <tr>
                        <th>nama</th>
                        <th>Hadir</th>
                    </tr>
                </thead>
                <tbody>
                        {Array.isArray(eventMember) && eventMember.length > 0 ? (
                            eventMember.map((user, index) => (
                                // ganti perosn id
                                <tr key={user._id}>
                                    <td>{user.namaDepan}</td>
                                    <td style={{ width: '50px', textAlign: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{ transform: 'scale(0.8)', verticalAlign: 'middle' }}
                                        checked={checkedMembers[user._id]}
                                        onChange={() => {
                                            const personId = handleCheckboxChange(user._id, user.personId);
                                            console.log("Person ID:", personId); 
                                        }}
                                    />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">Loading</td>
                            </tr>
                        )}
                    </tbody>
            </table>
            <button className="button is-primary mt-3" onClick={saveAttendance}>Save</button>
        </div>
    </div>
)
}
export default ActivityLifegroupSessionAdd