import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
// Style
import '../style/UserAddStyle.css'
import "../style/tableStyle.css";
// Function from Utils
import { formatDate_DDMMMMYYYY } from '../Utils/dateUtils';


const ActivityLifegroupAttendanceListAdd = () => {


    const [lgname, setLGName] = useState("");

    const [eventMember, setEventMember] = useState([]);
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();


    // State untuk menyimpan status centang
    const [checkedMembers, setCheckedMembers] = useState({});
    
    // Detail variables
    const [lifegroupName, setLifegroupName] = useState("");
    const [lifegroupId, setLifegroupId] = useState("");
    const [lifegroupDate, setLifegroupDate] = useState("");

    const getDetailLG = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            setLifegroupDate(formatDate_DDMMMMYYYY(response.data.eventDate));
            setLifegroupId(response.data.lifegroupId);
            const fetchedLifegroupId = response.data.lifegroupId;
            setLifegroupId(fetchedLifegroupId);
            if (fetchedLifegroupId) {
                const responseLG = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${fetchedLifegroupId}`);
                setLifegroupName(responseLG.data.lifegroupName);
            }
        } catch (error) {
            console.error("Error fetching event data:", error);
        }
    };

    // Fetch Detail Event
    const getEvent = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
        setLGName(response.data.lifegroupId)
    };

    // Fetch Listt person Id
    const getPersonId = async() => {
        // ganti denga lg ID
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberWithPersonDetails/${lgname}`);
            console.log(response.data)
            setEventMember(response.data);
            // Inisialisasi status centang
            const initialCheckedState = {};
            response.data.forEach(user => {
                initialCheckedState[user.PersonID] = false;
            });
            setCheckedMembers(initialCheckedState);
        }
        
    
    const handleCheckboxChange = (userId, personId) => {
        setCheckedMembers(prevState => ({
            ...prevState,
            [userId]: !prevState[userId]
        }));
    
        return personId; // Mengembalikan nilai personId
    };
    
    

    const saveAttendance = async (e) => {
        e.preventDefault();
        try {
            const sessionDataList = []; // Daftar data sesi untuk disimpan
    
            // Loop melalui semua anggota acara
            eventMember.forEach(user => {
                // Periksa apakah pengguna ini dicentang
                const isAttended = checkedMembers[user._id];
    
                // Tentukan status kehadiran berdasarkan apakah pengguna dicentang atau tidak
                const attendanceStatus = isAttended ? true : false;
    
                // Buat objek data sesi untuk pengguna ini
                const sessionData = {
                    eventId: id,
                    personId: user.PersonID,
                    kehadiran: attendanceStatus,
                    eventType: "Activity Lifegroup"
                };
    
                // Tambahkan objek data sesi ke daftar
                sessionDataList.push(sessionData);
            });
    
            // Simpan semua data sesi ke server
            await Promise.all(sessionDataList.map(sessionData => {
                console.log(`Inserting attendance for personId: ${sessionData.personId}`);
                return axios.post(`${process.env.REACT_APP_API_URL}/insertAttendanceList`, sessionData);
            }));
    
            console.log("All attendance data inserted successfully");
            navigate(`/ActivityLGAttendanceDetail/${id}`);
        } catch (error) {
            console.log(error);
        }
    };
    
    
    useEffect(() => {
        getEvent();
        getDetailLG();
    }, []);

    useEffect(() => {
        if (lgname) {
        getPersonId();
        }
    }, [lgname]);
        
    
return (
    <div className="columns">
        <div className="column ">
            {/* LG Name */}
            <div className="columns">
                <div className="column is-3">
                    <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                </div>
                <div className="column is-4">
                    <label className='control'>
                        <input
                            type="text"
                            className='input'
                            value={lifegroupName}
                            placeholder='Lifegroup Name'
                            readOnly
                        />
                    </label>
                </div>
            </div>
            <div className="columns">
                <div className="column is-3">
                    <div className='is-size-5 mb-2 has-text-weight-semibold'>Date</div>
                </div>
                <div className="column is-4">
                    <label className='control'>
                        <input
                            type="text"
                            className='input'
                            value={lifegroupDate}
                            placeholder='Date'
                            readOnly
                        />
                    </label>
                </div>
            </div>
            <hr />
            <table className="table is-striped is-fullwidth mt-5" style={{ maxHeight: '0px', overflowY: 'auto' }}>
                <thead>
                    <tr>
                        <th>nama</th>
                        <th>Hadir</th>
                    </tr>
                </thead>
                <tbody>
                        {Array.isArray(eventMember) && eventMember.length > 0 ? (
                            eventMember.map((user, index) => (
                                // ganti perosn id
                                <tr key={user._id}>
                                    <td>{user.namaDepan}</td>
                                    <td style={{ width: '50px', textAlign: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{ transform: 'scale(0.8)', verticalAlign: 'middle' }}
                                        checked={checkedMembers[user._id]}
                                        onChange={() => {
                                            const personId = handleCheckboxChange(user._id, user.PersonID);
                                            console.log("Person ID:", personId); 
                                        }}
                                    />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">Loading</td>
                            </tr>
                        )}
                    </tbody>
            </table>
            <button className="button is-primary mt-3" 
                style={{
                    backgroundColor: '#0052CC',
                    color: 'white',
                    padding: '10px 24px',
                    fontWeight: 'bold',
                    borderRadius: '8px'
                }}
                onClick={saveAttendance}>Save</button>
        </div>
    </div>
)
}
export default ActivityLifegroupAttendanceListAdd