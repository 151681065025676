import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'


const AddUser = () => {
    const [user, setUser] = useState([]);
    const [namaDepan, setName] = useState("");
    const [namaTengah, setNamaTengah] = useState("");
    const [namaBelakang, setNamaBelakang] = useState("");
    const [namaLain, setNamaLain] = useState("");
    const [gender, setGender] = useState("");
    const [tanggalLahir, setTanggalLahir] = useState("");
    const [tempatLahir, setTempatLahir] = useState("");
    const [faseHidup, setFaseHidup] = useState("");
    const [statusPerkawinan, setStatusPerkawinan] = useState("");
    const [pasangan, setPasangan] = useState("");
    const [tanggalPerkawinan, setTanggalPerkawinan] = useState("");
    const [nomorTelepon, setNomorTelepon] = useState("");
    const [email, setEmail] = useState("");
    const [lokasiGereja, setLokasiGereja] = useState("");
    const [role, setRole] = useState("");
    const [orangTua, setOrangTua] = useState("");
    const [komitmenPelayanan, setKomitmenPelayanan] = useState("");
    const [komitmenBerjemaat, setKomitmenBerjemaat] = useState("");
    const [ayah, setAyah] = useState("");
    const [ibu, setIbu] = useState("");
    const [kerinduan, setKerinduan] = useState("");

    const [activeSection, setActiveSection] = useState('personal');
    const handleClick = (section) => {
        setActiveSection(section);
    };


    const navigate = useNavigate();
    const { id } = useParams();

    // Untuk Sugest 
    const [suggestedPasangan, setSuggestedPasangan] = useState([]);
    const [suggestedProvinsi, setSuggestedProvinsi] = useState([]);
    const [suggestedKota, setSuggestedKota] = useState([]);
    const [provinsi, setProvinsi] = useState("");

      // Untuk Sugest OrangTua
    const [optionAyah, setoptionAyah] = useState([]);
    const [selectedAyah, setselectedAyah] = useState(null);
    const [optionIbu, setoptionIbu] = useState([]);
    const [selectedIbu, setselectedIbu] = useState(null);

    const [Father, setFather] = useState({ namaDepan: '' }); // Default kosong
    const [Mother, setMother] = useState({ namaDepan: '' }); // Default kosong

    let jemaatList = "";

    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggestedPasangan(response.data);
        jemaatList = response.data;
        // console.log(jemaatList);
    };

    // Fetch lokasi
    const getKota = async () => {
        try {
            const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinsi}.json`);
            console.log(response.data)
            setSuggestedKota(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch lokasi
    const getLokasi = async () => {
        try {
            const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
            console.log(response.data)
            setSuggestedProvinsi(response.data);
            console.log(provinsi)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
     // Ayah
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

            // Memisahkana male dan female
            const males = response.data.filter(person => person.gender === 'Male');

            const options = males.map((dj) => ({
            value: dj.personId,
            label: `${dj.namaDepan} ${dj.namaTengah} (ID: ${dj.personId})`,
            }));
            console.log(options)
            setoptionAyah(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleAyahChange = (selectedOption) => {
        setselectedAyah(selectedOption);
        setAyah(selectedOption.value);
    };

    // Ibu
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkana male dan female
                const males = response.data.filter(person => person.gender === 'Female');

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: `${dj.namaDepan} ${dj.namaTengah} (ID: ${dj.personId})`,
                }));
                console.log(options)
                setoptionIbu(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleIbuChange = (selectedOption) => {
        setselectedIbu(selectedOption);
        setIbu(selectedOption.value);
    };
    //Use Effect
    useEffect(() => {
        getLokasi();
        getKota();
    }, [provinsi]);

    useEffect(()=>{
        getUserById();
        getJemaat();
    }, []);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
        const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/personFamily/${id}`);
        setUser(response.data)
        console.log(response2);
        setFather(response2.data.father || { namaDepan: '' }); // Jika tidak ada data, atur default kosong
        setMother(response2.data.mother || { namaDepan: '' }); // Jika tidak ada data, atur default kosong
        console.log(response)
        setName(response.data.namaDepan);
        setNamaTengah(response.data.namaTengah);
        setNamaBelakang(response.data.namaBelakang);
        setNamaLain(response.data.namaLain);
        setGender(response.data.gender);
        setTanggalLahir(response.data.tanggalLahir);
        setTempatLahir(response.data.tempatLahir);
        setFaseHidup(response.data.faseHidup);
        setStatusPerkawinan(response.data.statusPerkawinan);
        setPasangan(response.data.pasangan);
        setTanggalPerkawinan(response.data.tanggalPerkawinan);
        setNomorTelepon(response.data.nomorTelepon);
        setEmail(response.data.email);
        setLokasiGereja(response.data.lokasiGereja);
        setRole(response.data.role);
        setOrangTua(response.data.orangTua);
        setKomitmenPelayanan(response.data.komitmenPelayanan);
        setKomitmenBerjemaat(response.data.komitmenBerjemaat);
        setAyah(response.data.ayah)
        setIbu(response.data.ibu)
        setKerinduan(response.data.kerinduan)
        console.log(tempatLahir)

    };

    const updateUser = async(e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updatePerson/${id}`,{
                namaDepan,
                namaTengah,
                namaBelakang,
                namaLain,
                gender,
                tanggalLahir,
                tempatLahir,
                faseHidup,
                statusPerkawinan,
                pasangan,
                tanggalPerkawinan,
                nomorTelepon,
                email,
                lokasiGereja,
                role,
                orangTua,
                komitmenPelayanan,
                komitmenBerjemaat,
                ayah,
                ibu,
            });
            navigate("/")
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="border">
            <div className="columns">
                <div className="column is-4 has-text-centered is-flex is-flex-direction-column is-align-items-center">
                    <div className="is-size-5 mb-4 has-text-weight-bold">
                        {`${user.namaDepan || ''} ${user.namaTengah || ''} ${user.namaBelakang || ''}`.trim()}
                    </div>
                    <div className="is-size-6 mb-4 has-text-weight-normal">
                        {user.email}
                    </div>
                    <div className="is-size-6 mb-4 has-text-weight-normal">
                        {user.nomorTelepon}
                    </div>
                    <div className="card has-text-left">
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'personal' ? 'active-section' : ''}`}
                            onClick={() => handleClick('personal')}
                        >
                            Personal Information
                        </div>
                        <div
                            className={`is-size-6 mb-4 has-text-weight-bold ${activeSection === 'church' ? 'active-section' : ''}`}
                            onClick={() => handleClick('church')}
                        >
                            Church Congregation Information
                        </div>

                        <style jsx>{`
                            .active-section {
                                background-color: #005EB8;
                                color: white;
                                cursor: pointer;
                                border-radius: 0.5rem;
                            }
                            .card div {
                                padding: 10px;
                            }
                        `}</style>
                    </div>
                </div>
                {activeSection === 'personal' ? (
                    <div className="column is-8">
                        <div className="is-size-4 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Personal Information
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Name</div>
                                <input
                                    type="text"
                                    className='input'
                                    value={namaDepan}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className='input'
                                    value={namaTengah}
                                    onChange={(e) => setNamaTengah(e.target.value)}

                                />
                                <input
                                    type="text"
                                    className='input'
                                    value={namaBelakang}
                                    onChange={(e) => setNamaBelakang(e.target.value)}

                                />
                                <input
                                    type="text"
                                    className='input'
                                    value={namaLain}
                                    onChange={(e) => setNamaLain(e.target.value)}

                                />
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Gender</div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="gender"
                                                value="Male"
                                                checked={gender === "Male"}
                                                onChange={(e) => setGender(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            Male
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="gender"
                                                value="Female"
                                                checked={gender === "Female"}
                                                onChange={(e) => setGender(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Place of Birth</div>
                                <input
                                    type="text"
                                    className='input'
                                    value={tempatLahir}
                                    onChange={(e) => setTempatLahir(e.target.value)}
                                />
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Date of Birth</div>
                                <input
                                    type="date"
                                    className='input'
                                    value={tanggalLahir}
                                    onChange={(e) => setTanggalLahir(e.target.value)}
                                />
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Life Phase</div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Anak-anak"
                                                checked={faseHidup === "Anak-anak"}
                                                className='checkbox-input'
                                                onChange={(e) => setFaseHidup(e.target.value)}
                                            />
                                            Anak-anak
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="PraRemaja/Remaja"
                                                checked={faseHidup === "PraRemaja/Remaja"}
                                                onChange={(e) => setFaseHidup(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            PraRemaja/Remaja
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Pemuda"
                                                checked={faseHidup === "Pemuda"}
                                                onChange={(e) => setFaseHidup(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            Pemuda
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="DewasaMuda"
                                                checked={faseHidup === "DewasaMuda"}
                                                onChange={(e) => setFaseHidup(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            DewasaMuda
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="faseHidup"
                                                value="Berkeluarga"
                                                checked={faseHidup === "Berkeluarga"}
                                                onChange={(e) => setFaseHidup(e.target.value)}
                                                className='checkbox-input'
                                            />
                                            Berkeluarga
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="column is-8">
                        <div className="is-size-4 mb-4 has-text-weight-bold" style={{ color: '#005EB8' }}>
                            Church Congregation Information
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Local Church</div>
                                <div className='checkbox-options-container'>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="lokasiGereja"
                                                value="SurabayaBarat"
                                                checked={lokasiGereja === "SurabayaBarat"}
                                                className='checkbox-input'
                                                onChange={(e) => setLokasiGereja(e.target.value)}
                                            />
                                            Surabaya Barat
                                        </label>
                                    </div>
                                    <div className='checkbox-options'>
                                        <label className='control'>
                                            <input
                                                type="checkbox"
                                                name="lokasiGereja"
                                                value="SurabayaTimur"
                                                checked={lokasiGereja === "SurabayaTimur"}
                                                className='checkbox-input'
                                                onChange={(e) => setLokasiGereja(e.target.value)}
                                            />
                                            Surabaya Timur
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className='is-size-6 mb-3 has-text-weight-bold'>Congregational Commitment</div>
                                <input
                                    type="text"
                                    className='textarea'
                                    value={komitmenBerjemaat}
                                    onChange={(e) => setKomitmenBerjemaat(e.target.value)}
                                />
                            </div>
                        </div>
                            <button
                                    className='button mt-2'
                                    style={{ backgroundColor: '#005EB8', width: '150px', color: 'white' }}
                                    onClick={(e) => {
                                        updateUser(e);
                                    }}
                                >
                                    Edit
                                </button>
                    </div>
                )}
            </div>
        </div>
    )
}
export default AddUser