import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";

const DiscipleshipJourneyList = () => {
    const [DiscipleshipJourneys, setDiscipleshipJourneys] = useState([]);
    const navigate = useNavigate();

    const getDiscipleshipJourneys = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeWithPrereq`);
            setDiscipleshipJourneys(response.data);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateDJ = async (id, status) => {
        console.log(status,id)
        // Cek status dan lakukan pembaruan yang sesuai
        if (status === 'active') {
            // Logika untuk status 'active'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateDiscipleshipJourneyType/${id}`, { status: 'deactive' });
            console.log(`success update Lifegroup ${id} to inactive status`);
        } else {
           // Logika untuk status 'dective'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateDiscipleshipJourneyType/${id}`, { status: 'active' });
            console.log(`success update Lifegroup ${id} to active status`);
        }
        // Lakukan update ke server atau state management
        // Refresh halaman setelah pembaruan
        window.location.reload(); // Reload halaman untuk menyegarkan data
    };

    const checkData = (value) => {
        if (value === undefined || value === null || value === '') {
            return <em>No Data</em>;
        }
        return value;
    };

    useEffect(() => {
        getDiscipleshipJourneys();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Discipleship Journey Name',
            accessor: 'discipleshipJourneyTypeName',
            Cell: ({ row }) => (
                <Link to={`/DiscipleshipJourneyTypeDetail/${row.original.discipleshipJourneyTypeId}`} className="has-text-weight-bold" style={{ marginRight: '10px' }}>
                    {checkData(row.original.discipleshipJourneyTypeName)}
                </Link>
            )
        },
        {
            Header: 'Discipleship Journey Prerequisite',
            accessor: 'prereqName',
            Cell: ({ value }) => checkData(value)
        },
        {
            Header: 'Action',
            accessor: 'actions',
            Cell: ({ row }) => {
                // const isActive = row.original.status === 'active'; // Check if status is 'active'
                const { discipleshipJourneyTypeId, status } = row.original;
                
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <button
                            onClick={() => navigate(`/DiscipleshipJourneyTypeEdit/${row.original.discipleshipJourneyTypeId}`)}
                            className="button is-success is-small"
                            style={{ marginRight: '10px' }}
                        >
                            Edit
                        </button>
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button
                                onClick={() => updateDJ(discipleshipJourneyTypeId,status)} // Updated to correct function
                                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}
                            >
                                {isActive ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24">
                                        <path fill="green" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                            <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                        </path>
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24">
                                        <path fill="red" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                            <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                        </path>
                                    </svg>
                                )}
                            </button>
                        </div> */}
                    </div>
                );
            }
        }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data: DiscipleshipJourneys, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-2">
                    <div className="column is-7">
                        <div className="custom-button" onClick={() => navigate('/addDiscipleshipJourney')}>Add Discipleship Journey</div>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>

                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscipleshipJourneyList;
