import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const Home = () => {
    const [idPerson, setIdPerson] = useState("");
    const [userName, setUserName] = useState("");
    const [events, setEvents] = useState([]);
    const [historys, setHistorys] = useState([]);

    const events2 = [
        { eventName: 'Church Community', eventDate: '6 June 2025', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Training', eventDate: '1 Feb 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Sunday Prayer', eventDate: '10 Aug 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Worship Night', eventDate: '12 Sept 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Youth Conference', eventDate: '15 Nov 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Christmas Celebration', eventDate: '25 Dec 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'New Year’s Eve', eventDate: '31 Dec 2024', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Men’s Breakfast', eventDate: '10 Jan 2025', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Women’s Retreat', eventDate: '22 Jan 2025', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Volunteer Training', eventDate: '5 Feb 2025', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Spring Festival', eventDate: '20 Mar 2025', eventLokasi: 'Lemarc Mall' },
        { eventName: 'Easter Service', eventDate: '5 Apr 2025', eventLokasi: 'Lemarc Mall' },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 4;

    const handleNext = () => {
        if (currentIndex < events2.length - itemsPerPage) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const currentEvents = events2.slice(currentIndex, currentIndex + itemsPerPage);
    console.log(currentEvents)


    // get Token Data
    const getTokenData = () => {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token) {
            try {
                const tokenData = JSON.parse(atob(token.split('.')[1])); // Parsing bagian payload dari token
                console.log(tokenData);
                setIdPerson(tokenData.personId);
                setUserName(tokenData.username);
                return tokenData;
            } catch (error) {
                console.error('Error parsing token:', error);
                return null;
            }
        } else {
            return null;
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Upcoming Event
    const getUpcomingEvent = async () => {
    try {
        // Fetch upcoming events
        const responseEvent = await axios.get(`${process.env.REACT_APP_API_URL}/viewUpcomingEvent`);
        // Filter responseEvent to include only events with eventType "Discipleship Journey"
        const filteredEvents = responseEvent.data.filter(event => event.eventType === "Discipleship Journey");

        console.log(filteredEvents);
        setEvents(filteredEvents);

        // Fetch person history
        let responseHistory;
        try {
            responseHistory = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonHistory/${idPerson}`);
            console.log(responseHistory);
            setHistorys(responseHistory.data);
        } catch (error) {
            console.error('Error fetching person history:', error);
            setHistorys([]); // Set an empty array if the request fails
        }
    } catch (error) {
        console.log('Error fetching upcoming events:', error);
    }
};



const handleRegister = async (eventId) => {
    try {
        // Fetch event details
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${eventId}`);
        const eventData = response.data;
        const discJourneyTypeId = eventData.discipleshipJourneyTypeId;
        console.log(discJourneyTypeId);

        // Check if there are prerequisites
        if (discJourneyTypeId) {
            const responseDJ = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${discJourneyTypeId}`);
            const prereqId = responseDJ.data.prereq;

            if (prereqId) {
                // Fetch prerequisite class details
                const prereqResponse = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyTypeById/${prereqId}`);
                const prereqClassName = prereqResponse.data.discipleshipJourneyTypeName;

                const responseDJ2 = await axios.get(`${process.env.REACT_APP_API_URL}/checkEventRegistrationByDj/${prereqId}`);
                console.log(responseDJ2.data);
                const prereqData = responseDJ2.data;

                // Ensure that prereqData is an array
                const prereqArray = Array.isArray(prereqData) ? prereqData : [prereqData];

                // Check if the user meets the prerequisite
                const prerequisiteMet = prereqArray.some(prereq => prereq.personId === idPerson && prereq.status === 'lulus');

                if (!prerequisiteMet) {
                    alert(`You have not completed the required class: ${prereqClassName}`);
                    return;
                }
            }
        }

        // Check if event registration already exists
        const checkResponse = await axios.get(`${process.env.REACT_APP_API_URL}/checkEventRegistrationExists/${eventId}/${idPerson}`);
        if (checkResponse.data.exists) {
            alert("You are already registered for this event!");
            return;
        }

        // Proceed with registration
        const registrationData = {
            eventId,
            personId: idPerson,
            discipleshipJourneyTypeId: discJourneyTypeId
        };

        // Filter only properties with values
        const filteredRegistrationData = Object.fromEntries(
            Object.entries(registrationData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
        );

        await axios.post(`${process.env.REACT_APP_API_URL}/insertEventRegistration`, filteredRegistrationData);
        alert("Registration successful!");
    } catch (error) {
        console.error('Error during registration:', error);
        alert("Error registering for event.");
    }
};

    useEffect(() => {
        const tokenData = getTokenData();
        if (tokenData) {
            setIdPerson(tokenData.personId);
            setUserName(tokenData.username);
        }
    }, []);

    useEffect(() => {
        if (idPerson) {
            getUpcomingEvent();
        }
    }, [idPerson]);

    return (
        <div className="column">
            <div className="hero-body" style={{ backgroundColor: '#FFFFFF' }}>
                <div className="container" style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'row' }}>
                    <p className="subtitle " style={{ fontWeight:'bold',color: '#005EB8', writingMode: 'vertical-rl', transform: 'rotate(180deg)', margin: 0 }}>
                        <span className="is-uppercase is-size-7">Welcome to this website</span>
                    </p>
                    <p className="title" style={{ color: '#005EB8', margin: 0, whiteSpace: 'nowrap', fontSize: 'clamp(2rem, 6vw, 4rem)' }}>
                        <span style={{ fontWeight: 'bold' }}>Every Nation.</span><br />
                        <span style={{ fontStyle: 'italic' }}>Every Campus.</span>
                    </p>
                </div>
            </div>
            <section className="section" style={{ backgroundColor: '#005EB8' ,margin: '0 -3rem', padding: 0 }}>
                <div className="container">
                    <h2 className="title has-text-centered has-text-white">Upcoming Events</h2>
                    <div className="columns is-variable is-4 is-multiline">
                        {currentEvents.map((event, index) => (
                            <div className="column is-3" key={index} style={{ textAlign: 'center' }}>
                                <div className="card-image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <figure className="image" style={{ width: '12rem', height: '20.625rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://via.placeholder.com/150" alt="Event" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </figure>
                                </div>
                                <div className="card-content has-text-centered" style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <p className="title is-4 has-text-white">{event.eventName}</p>
                                    <p className="subtitle is-6 has-text-white">{event.eventDate}</p>
                                    <p className="subtitle is-6 has-text-white">{event.eventLokasi}</p>
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className="buttons is-centered">
                        <button className="button is-light" onClick={handlePrevious} disabled={currentIndex === 0}>
                            Previous
                        </button>
                        <button className="button is-light" onClick={handleNext} disabled={currentIndex >= events2.length - itemsPerPage}>
                            Next
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
