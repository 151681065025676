import Papa from 'papaparse';
import { saveAs } from 'file-saver';

export const exportToCSV = (data, fileName) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileName}.csv`);
};

export const importCSV = (event, setData) => {
    const file = event.target.files[0];
    if (file) {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                setData(result.data);
            },
            error: (error) => {
                console.error('Error parsing CSV file:', error);
            },
        });
    }
};
