import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import '../style/TopBarStyle.css'

function Topbar({ onToggleSidebar }) {
    const [User, setUser] = useState("");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isNotificationDropdownOpen, setNotificationDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    // Get Token Data
    const getTokenData = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const tokenData = JSON.parse(atob(token.split('.')[1]));
                return tokenData;
            } catch (error) {
                console.error('Error parsing token:', error);
                return null;
            }
        } else {
            return null;
        }
    };

    const getTags = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewNotification`);
            console.log(response)
            setNotifications(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Logout
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };


    // Update the current page name based on the URL path
    useEffect(() => {
        const path = location.pathname;
        console.log(path)
        if (path.match(/^\/ActivityLGAddPerLG\/[a-zA-Z0-9]+$/)) {
            setCurrentPage('Add My Life Group Activity');
        } 
        else if (path.match(/^\/ActivityLGListPerLG\/[a-zA-Z0-9]+$/)) {
            setCurrentPage('My Life Group Activity');
        } 
        else if (path.match(/^\/ActivityLGDetails\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Detail Activity Life Group');
        }
        else if (path.match(/^\/LifegroupActivityAttendanceListPerLG\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Lifegroup Activity Attendance');
        }
        else if (path.match(/^\/addLifegroupMember\/[a-zA-Z0-9]+$/)) {
            setCurrentPage('Add Life Group Member');
        } 
        else if (path.match(/^\/viewLifegroup\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Detail Life Group');
        }
        else if (path.match(/^\/editLifegroup\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Edit Life Group');
        }
        else if (path.match(/^\/DiscipleshipJourneyTypeDetail\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Detail Discipleship Journey Graduated');
        }
        else if (path.match(/^\/Detailuser\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Detail User');
        }
        else if (path.match(/^\/UserEdit\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('User Edit');
        }
        else if (path.match(/^\/userlist\/userDetails\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('View Person');
        }
        else if (path.match(/^\/JemaatHistory\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('History');
        }
        else if (path.match(/^\/FamilyMembers\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Family Members');
        }
        else if (path.match(/^\/TagListByPerson\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Tag Person');
        }
        else if (path.match(/^\/UserList\/editUser\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Edit Person');
        }
        else if (path.match(/^\/ActivityLGAttendanceDetail\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Lifegroup Activity Attendance');
        }
        else if (path.match(/^\/ActivityLGAttendance\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Add Lifegroup Activity Attendance');
        }
        else if (path.match(/^\/ActivityLGAttendanceUpdate\/[a-zA-Z0-9-]+$/)) {
            setCurrentPage('Edit Lifegroup Activity Attendance');
        }
        else {
            switch (path) {
                case '/profile':
                    setCurrentPage('Profile');
                    break;
                case '/settings':
                    setCurrentPage('Settings');
                    break;
                case '/Management':
                    setCurrentPage('Management');
                    break;
                case '/Gmail':
                    setCurrentPage('Send Message Gmail');
                    break;
                // Dashboard
                case '/eventDashboard':
                    setCurrentPage('Event Dashboard');
                    break;
                case '/Dashboard':
                    setCurrentPage('General Dashboard');
                    break;
                // List
                case '/EventList':
                    setCurrentPage('Event');
                    break;
                case '/LifegroupList':
                    setCurrentPage('Life Group');
                    break;
                case '/ActivityLGList':
                    setCurrentPage('Life Group Activity');
                    break;
                case '/DiscipleshipJourneyList':
                    setCurrentPage('Discipleship Journey');
                    break;
                case '/DiscipleshipJourneyClassList':
                    setCurrentPage('Discipleship Journey Class');
                    break;
                case '/UserList':
                    setCurrentPage('Person');
                    break;
                // Add
                case '/ActivityLGAdd':
                    setCurrentPage('Create Life Group Activity');
                    break;
                case '/addEvent':
                    setCurrentPage('Add Event');
                    break;
                case '/addLifegroup':
                    setCurrentPage('Add Life Group');
                    break;
                case '/addDiscipleshipJourney':
                    setCurrentPage('Add Discipleship Journey');
                    break;
                case '/addDiscipleshipJourneyClass':
                    setCurrentPage('Add Discipleship Journey Class');
                    break;
                case '/addJemaat':
                    setCurrentPage('Add Person');
                    break;
                case '/AddEventParticipantByRecomendation':
                    setCurrentPage('Add Participant by Recommendation');
                    break;
                default:
                    setCurrentPage('Every Nation');
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        const tokenData = getTokenData();
        if (tokenData) {
            setUser(tokenData.username);
        }
        getTags();
    }, []);

    const handleDropdownToggle = () => {
        setDropdownOpen(prevState => !prevState);
    };

    const handleNotificationDropdownToggle = () => {
        setNotificationDropdownOpen(prevState => !prevState);
    };

    return (
        <div
            className="topbar"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0',
                backgroundColor: '#005EB8',
                borderBottom: '1px solid #ddd',
                position: 'relative',
                height: '12vh',
                color: '#fff',
            }}
            >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button onClick={onToggleSidebar} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17h18M3 12h18M3 7h18"/>
                </svg>
                </button>
                <span className="is-size-4 mb-1 has-text-weight-semibold" style={{ color: '#fff', marginLeft: '10px' }}>{currentPage}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', position: 'relative' }}>
                {/* Notification Icon */}
                <button onClick={handleNotificationDropdownToggle} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" style={{ fill: 'white', marginRight: '8px' }}>
                        <path fill="white" d="M4 8a8 8 0 1 1 16 0v4.697l2 3V20h-5.611a4.502 4.502 0 0 1-8.777 0H2v-4.303l2-3zm5.708 12a2.5 2.5 0 0 0 4.584 0zM12 2a6 6 0 0 0-6 6v5.303l-2 3V18h16v-1.697l-2-3V8a6 6 0 0 0-6-6"/>
                    </svg>
                </button>
                {isNotificationDropdownOpen && (
                    <div style={{
                        position: 'absolute',
                        top: '100%',
                        right: '190px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '1px solid #ddd',
                        borderRadius: '4px ',
                        padding: '0',
                        zIndex: 1000,
                        width: '300px',
                        maxHeight: '300px',
                        overflowY: 'auto',
                    }}>
                        <ul className="notification-list" style={{
                            listStyle: 'none',
                            margin: 0,
                            padding: '8px',
                            textAlign: 'left',
                            backgroundColor: '#fff',
                        }}>
                            {notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <li key={index} className="notification-item" style={{
                                        padding: '8px 0',
                                        borderBottom: '1px solid #ddd',
                                    }}>
                                        <strong>{notification.message}</strong>
                                        <br />
                                        {formatDate(notification.createdAt)}
                                    </li>
                                ))
                            ) : (
                                <li style={{ padding: '8px 0' }}>No notifications</li>
                            )}
                        </ul>
                        <div style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '8px'
                        }}>
                            <button style={{
                                width: '100%',
                                backgroundColor: '#0082FF',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '10px',
                                cursor: 'pointer',
                                fontSize: '16px'
                            }}>
                                View All
                            </button>
                        </div>
                    </div>
                )}

                {/* Profile Icon */}
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <path fill="white" fillRule="evenodd" d="M12 4a8 8 0 0 0-6.96 11.947A4.99 4.99 0 0 1 9 14h6a4.99 4.99 0 0 1 3.96 1.947A8 8 0 0 0 12 4m7.943 14.076A9.959 9.959 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.958 9.958 0 0 0 2.057 6.076l-.005.018l.355.413A9.98 9.98 0 0 0 12 22a9.947 9.947 0 0 0 5.675-1.765a10.055 10.055 0 0 0 1.918-1.728l.355-.413zM12 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6" clipRule="evenodd"/>
                </svg>
                <div className='is-size-5 has-text-weight-semibold'>{User}</div>
                <button
                onClick={handleDropdownToggle}
                style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                    <path fill="white" d="M8.037 11.166L14.5 22.36c.825 1.43 2.175 1.43 3 0l6.463-11.195c.826-1.43.15-2.598-1.5-2.598H9.537c-1.65 0-2.326 1.17-1.5 2.6z"/>
                </svg>
                </button>
                {isDropdownOpen && (
                <div style={{
                    position: 'absolute',
                    top: '100%',
                    right: '0',
                    backgroundColor: '#fff',
                    color: '#000',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    padding: '10px',
                    zIndex: 1000,
                    minWidth: 'max-content',
                    width: `calc(100% - ${48 + 8}px)`,
                }}>
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                    <li><Link to="/profile" style={{ textDecoration: 'none', color: '#000', display: 'block', padding: '8px 16px' }}>Profile</Link></li>
                    <li><a onClick={handleLogout} style={{ textDecoration: 'none', color: '#D24747', display: 'block', padding: '8px 16px', cursor: 'pointer' }}>Logout</a></li>
                    </ul>
                </div>
                )}
            </div>
        </div>

    );
}

export default Topbar;
