import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import '../style/EventAddStyle.css'
import '../style/CheckboxStyle.css';
import Select from 'react-select'

const DiscipleshipJourneyClassAdd = () => {
  const {id} = useParams();
  const [image, setImage] = useState(null);
  const [eventName, setEventName] = useState("");
  // const [eventDate, setEventDate] = useState("");
  const [eventDate, setEventDate] = useState({ day: '', month: '', year: '' });
  const [eventTimeIn, setEventTimeIn] = useState("");
  const [eventTimeOut, setEventTimeOut] = useState("");
  const [eventCapacity, setEventCapacity] = useState("");
  const [eventSetup, seteventSetup] = useState("");
  const [eventAttendanceTotal, seteventAttendanceTotal] = useState("");
  const [eventLokasi, seteventLokasi] = useState("");
  const [eventType, seteventType] = useState("");

  // atribut khusus Discipleship event 
  const[discipleshipJourneyType,setDiscipleshipJourneyType] = useState("");
  

  // atribut khusus Common event 
  const[organizer,setOrganizer] = useState("");

  // atribut khusus Service 
  // Ada series title
  const[sermonTitle,setSermonTitle] = useState("");
  const[preacher,setPreacher] = useState("");

  // Atribut Khusus Activity LG
  const[lifegroupId,setLifegroupId] = useState("");
  const [seriesTitle, setseriesTitle] = useState("");
  const [fasilitator,setfasilitator] =useState("");

   // Untuk Sugest Discipleship Journey
  const [optionDJ, setOptionDJ] = useState([]);
  const [selectedDJ, setSelectedDJ] = useState(null);

  // Untuk Sugest Discipleship Journey
  const [optionLG, setOptionLG] = useState([]);
  const [selectedLG, setSelectedLG] = useState(null);

  // select discipleship jouirney type
  useEffect(() => {
    const fetchDJOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType`);
        const options = response.data.map((dj) => ({
          value: dj.discipleshipJourneyTypeId,
          label: dj.discipleshipJourneyTypeName,
        }));
        console.log(options)
        setOptionDJ(options);
      } catch (error) {
        console.error('Error fetching discipleship journey types:', error);
      }
    };

    fetchDJOptions();
  }, []);
  // Handle select discipleship journey type
  const handleDJChange = (selectedOption) => {
    setSelectedDJ(selectedOption);
    console.log('Selected DJ:', selectedOption);
    setDiscipleshipJourneyType(selectedOption.value);
    setEventName(selectedOption.label);
    console.log(discipleshipJourneyType)
  };



  const navigate = useNavigate();

  const handleImageChange = (e) => {
    // Mengambil file gambar dari input
    const file = e.target.files[0];
    setImage(file);
  };

  // form
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
 // Mendapatkan tanggal hari ini
  var today = new Date();

  // Mendapatkan komponen tanggal, bulan, dan tahun
  var day2 = today.getDate();
  var month2 = today.getMonth() + 1; // Bulan dimulai dari 0
  var year2 = today.getFullYear();

  // Format tanggal sebagai 'YYYY-MM-DD'
  var formattedDate = year2 + '-' + (month2 < 10 ? '0' : '') + month2 + '-' + (day2 < 10 ? '0' : '') + day2;

    const formData = new FormData();
    // const eventDateISO = `${eventDate.year}-${eventDate.month}-${eventDate.day}`;
    //   const { day, month, year } = eventDate;
    //   let date2;
    // if (day && month && year) {
    //   // Gunakan Date.UTC untuk membuat tanggal dalam UTC
    //   date2 = new Date(Date.UTC(year, month - 1, day, 12, 0, 0)); // Set jam ke 12:00:00 UTC
    //   console.log(date2.toISOString()); // Lihat hasilnya dalam bentuk ISO
    //   // Lakukan sesuatu dengan date
    // }

    image && formData.append('eventImageName', image);
    eventName && formData.append('eventName', eventName);
    eventDate && formData.append('eventDate', eventDate);
    eventTimeIn && formData.append('eventTimeIn', eventTimeIn);
    eventTimeOut && formData.append('eventTimeOut', eventTimeOut);
    eventSetup && formData.append('eventSetup', eventSetup);
    eventAttendanceTotal && formData.append('eventAttendanceTotal', eventAttendanceTotal);
    eventCapacity && formData.append('eventCapacity', eventCapacity);
    eventLokasi && formData.append('eventLokasi', eventLokasi);
    seriesTitle && formData.append('seriesTitle', seriesTitle);
    fasilitator && formData.append('fasilitator', fasilitator);
    organizer && formData.append('organizer', organizer);
    sermonTitle && formData.append('sermonTitle', sermonTitle);
    preacher && formData.append('preacher', preacher);
    formData.append('eventType', 'Discipleship Journey');    
    formData.append('discipleshipJourneyTypeId', discipleshipJourneyType);
    formData.append('createdAt', formattedDate);
    formData.append('status', 'active');

  
    try {
  console.log(formData);

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertEventImage`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  // Handle response from the server
  console.log('Server response:', response.data);
  console.log(response.data.eventId);

  navigate(`/DiscipleshipJourneyClassList`);
} catch (error) {
  // Capture and log error details
  if (error.response) {
    // Error from server response with a specific status code
    console.error('Server response error:', error);
  } else if (error.request) {
    // Error in making the request to the server
    console.error('Request error:', error.request);
  } else {
    // Other errors
    console.error('Error uploading image:', error.message);
  }
}
  };
  

  return (
    <div className='column'>
      <form onSubmit={(e) => { handleFormSubmit(e); }}>
        <div className='is-size-4 mb-4 has-text-weight-bold'>Discipleship Journey Event Information</div>
        <hr />
        {/* Discipleship Journey */}
        <label>
            <div className="columns">
                <div className="column is-2">
                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Discipleship Journey Type </div>
                </div>
                <div className="column is-9">
                  <div className="field">
                    <div className="control">
                        <Select 
                        options={optionDJ}
                        value={selectedDJ}
                        onChange={handleDJChange}
                        />
                    </div>
                  </div>
                </div>
            </div>
        </label>
        {/* Nama event */}
        <div className="columns">
          <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Name</div>
          </div>
          <div className="column is-9">
              <div className="field">
                <div className="control">
                    <input type="text" className='input' value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        placeholder='Event Name' />
                </div>
              </div>
          </div>
        </div>
        {/* Lokasi Event */}
        <div className="columns">
          <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Location</div>
          </div>
          <div className="column is-9">
              <div className="field">
                <div className="control">
                    <input type="text" className='input' value={eventLokasi}
                        onChange={(e) => seteventLokasi(e.target.value)}
                        placeholder='Event Location' />
                </div>
              </div>
          </div>
        </div>
        {/* Jam mulai dan akhir */}
        <div className="columns">
          <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Date</div>
          </div>
          <div className="column is-2">
              <div className="field">
                <div className="control">
                    <input type="date" className='input' value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        placeholder='Time In' />
                </div>
              </div>
          </div>
          <div className="column is-1">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time In</div>
          </div>
          <div className="column is-2">
              <div className="field">
                <div className="control">
                    <input type="time" className='input' value={eventTimeIn}
                        onChange={(e) => setEventTimeIn(e.target.value)}
                        placeholder='Time In' />
                </div>
              </div>
          </div>
          <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time Out</div>
          </div>
          <div className="column is-2">
              <div className="field">
                <div className="control">
                    <input type="time" className='input' value={eventTimeOut}
                        onChange={(e) => setEventTimeOut(e.target.value)}
                        placeholder='Time Out' />
                </div>
              </div>
          </div>
        </div>
        <div className='is-size-4 mb-4 has-text-weight-bold'>Capacity, Setup, and Attendance Information</div>
        <hr />
        {/* Setup */}
        <div className="columns">
          <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Setup</div>
          </div>
        <div className="column is-9">
            <div className="control mb-2 radio-container">
                <label className="checkbox is-large">
                    <input
                        type="radio"
                        value={eventSetup === "setup 1" ? "" : "setup 1"}
                        checked={eventSetup === "setup 1"}
                        onChange={(e) => seteventSetup(e.target.value)}
                    />
                    <span className="checkbox-text">setup 1</span>
                </label>
                <label className="checkbox is-large">
                    <input
                        type="radio"
                        value={eventSetup === "setup 2" ? "" : "setup 2"}
                        checked={eventSetup === "setup 2"}
                        onChange={(e) => seteventSetup(e.target.value)}
                    />
                    <span className="checkbox-text">setup 2</span>
                </label>
                <label className="checkbox is-large">
                    <input
                        type="radio"
                        value={eventSetup === "setup 3" ? "" : "setup 3"}
                        checked={eventSetup === "setup 3"}
                        onChange={(e) => seteventSetup(e.target.value)}
                    />
                    <span className="checkbox-text">setup 3</span>
                </label>
                <label className="checkbox is-large">
                    <input
                        type="radio"
                        value={eventSetup === "setup 4" ? "" : "setup 4"}
                        checked={eventSetup === "setup 4"}
                        onChange={(e) => seteventSetup(e.target.value)}
                    />
                    <span className="checkbox-text">Setup 4</span>
                </label>
            </div>
        </div>
        </div>
        <div className="is-flex is-justify-content-flex-end mt-4">
            <button className='button' style={{
              backgroundColor: '#0052CC',
              color: 'white',
              padding: '10px 24px',
              fontWeight: 'bold',
              borderRadius: '8px'
            }}>
              Save
            </button>
        </div>
      </form>
      
    </div>
  );
};

export default DiscipleshipJourneyClassAdd;
