import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/UserAddStyle.css'
import '../style/CheckboxStyle.css';
import Select from 'react-select'
import ConfirmationModal from '../modals/ConfirmationModal';



const AddUser = () => {

    // Untuk data yang ke DB
    
    const [namaDepan, setName] = useState("");
    const [namaTengah, setNamaTengah] = useState("");
    const [namaBelakang, setNamaBelakang] = useState("");
    const [namaLain, setNamaLain] = useState("");
    const [gender, setGender] = useState("");
    const [tanggalLahir, setTanggalLahir] = useState("");
    const [tempatLahir, setTempatLahir] = useState("");
    const [faseHidup, setFaseHidup] = useState("");
    const [statusPerkawinan, setStatusPerkawinan] = useState("");
    const [pasangan, setPasangan] = useState("");
    const [tanggalPerkawinan, setTanggalPerkawinan] = useState("");
    const [nomorTelepon, setNomorTelepon] = useState("");
    const [email, setEmail] = useState("");
    const [lokasiGereja, setLokasiGereja] = useState("");
    const [role, setRole] = useState("");
    const [ayah, setAyah] = useState("");
    const [ibu, setIbu] = useState("");
    const [kerinduan, setKerinduan] = useState("");
    const [komitmenBerjemaat, setKomitmenBerjemaat] = useState("");

    const [provinsi,setProvinsi]= useState("");

    // Untuk Sugest 
    const [suggestedPasangan, setSuggestedPasangan] = useState([]);
    const [suggestedProvinsi, setSuggestedProvinsi] = useState([]);
    const [suggestedKota, setSuggestedKota] = useState([]);
    const [suggestedTag, setSuggestedTag] = useState([]);

    // Untuk Sugest OrangTua
    const [optionAyah, setoptionAyah] = useState([]);
    const [selectedAyah, setselectedAyah] = useState(null);
    const [optionIbu, setoptionIbu] = useState([]);
    const [selectedIbu, setselectedIbu] = useState(null);
    const [optionAll, setoptionAll] = useState([]);
    const [selectedAll, setselectedAll] = useState(null);
    // Ayah
    // select Ayah
    useEffect(() => {
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkan male dan female
                const males = response.data.filter(person => person.gender === 'Male');

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: (
                        <div>
                            <div>
                                {`${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''}`.trim() || 'No Name Available'}
                            </div>
                            <div>
                                {`Kode: ${dj.kodeJemaat}`}
                            </div>
                        </div>
                    ),
                    searchLabel: `${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''} ${dj.kodeJemaat}`.trim()
                }));
                console.log(options);
                setoptionAyah(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };


        fetchDJOptions();
    }, []);
    // Handle select Ayah
    const handleAyahChange = (selectedOption) => {
        setselectedAyah(selectedOption);
        setAyah(selectedOption.value);
    };

    // Ibu
    // select Ibu
    useEffect(() => {
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkana male dan female
                const males = response.data.filter(person => person.gender === 'Female');

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: (
                        <div>
                            <div>
                                {`${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''}`.trim() || 'No Name Available'}
                            </div>
                            <div>
                                {`Kode: ${dj.kodeJemaat}`}
                            </div>
                        </div>
                    ),
                    searchLabel: `${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''} ${dj.kodeJemaat}`.trim()
                }));
                console.log(options)
                setoptionIbu(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchDJOptions();
    }, []);
    // Handle select Ibu
    const handleIbuChange = (selectedOption) => {
        setselectedIbu(selectedOption);
        setIbu(selectedOption.value);
    };

    // All
    // select All
    useEffect(() => {
        const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);

                // Memisahkana male dan female
                const males = response.data;

                const options = males.map((dj) => ({
                    value: dj.personId,
                    label: (
                        <div>
                            <div>
                                {`${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''}`.trim() || 'No Name Available'}
                            </div>
                            <div>
                                {`Kode: ${dj.kodeJemaat}`}
                            </div>
                        </div>
                    ),
                    searchLabel: `${dj.namaDepan || ''} ${dj.namaTengah || ''} ${dj.namaBelakang || ''} ${dj.kodeJemaat}`.trim()
                }));
                console.log(options)
                setoptionAll(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchDJOptions();
    }, []);
    // Handle select All
    const handleAllChange = (selectedOption) => {
        setselectedAll(selectedOption);
        setPasangan(selectedOption.value);
    };

      // Custom filter function for react-select
    const customFilter = (option, searchText) => {
        if (option.data.searchLabel.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
        return false;
    };

    //Use Effect
    useEffect(()=> {
        getJemaat();
        getLokasi();
        getKota();
    },[provinsi]);

    
    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        console.log(response)
        setSuggestedPasangan(response.data);
    };

    const getTag = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTag`);
        setSuggestedTag(response.data);
    };

    // Fetch lokasi
    const getKota = async () => {
            try {
                const response = await axios.get(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinsi}.json` );
                console.log(response.data)
                setSuggestedKota(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
    // Fetch lokasi
    const getLokasi = async () => {
            try {
                const response = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
                console.log(response.data)
                setSuggestedProvinsi(response.data);
                console.log(provinsi)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
    const navigate = useNavigate();

    const saveUser = async (e) => {
        e.preventDefault();
        try {

             // Mengubah format tanggalLahir menjadi string ISO (YYYY-MM-DD)
            const tanggalLahirISO = `${tanggalLahir.year}-${tanggalLahir.month}-${tanggalLahir.day}`;
            const tanggalPerawinanISO = `${tanggalPerkawinan.year}-${tanggalPerkawinan.month}-${tanggalPerkawinan.day}`;

            const userData = {
                namaDepan,
                namaTengah,
                namaBelakang,
                namaLain,
                gender,
                tempatLahir,
                tanggalLahir,
                faseHidup,
                statusPerkawinan,
                pasangan,
                tanggalPerkawinan,
                nomorTelepon,
                email,
                lokasiGereja,
                role:"jemaat",
                ayah,
                ibu,
                kerinduan,
                komitmenBerjemaat,
                dateAdded:new Date(),   
                status:"active"
            };
    
            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            
            await axios.post(`${process.env.REACT_APP_API_URL}/insertPerson`, filteredUserData);
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };
    
return (
    <div className="border">
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Personal Data</div>
                <hr />
                <form onSubmit={saveUser}action=""> 
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Name</div>
                        </div>
                        <div className="column is-9">
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaDepan}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder='First Name' />
                                        </div>
                                    </div>
                                    {/* Nama tengah */}
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaTengah}
                                                onChange={(e) => setNamaTengah(e.target.value)}
                                                placeholder='Middle Name' />
                                        </div>
                                    </div>
                                    {/* Nama belakang */}
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaBelakang}
                                                onChange={(e) => setNamaBelakang(e.target.value)}
                                                placeholder='Last Name' />
                                        </div>
                                    </div>
                                    {/* Nama Lain */}
                                    <label className='control'>
                                        <input type="text"className='input' value ={namaLain} 
                                        onChange={(e)=> setNamaLain(e.target.value)}
                                        placeholder='Another Name' />
                                    </label>
                        </div>
                    </div>
                    {/* Kelamin */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Gender</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2 radio-container">
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={gender === "Male" ? "" : "Male"}
                                    checked={gender === "Male"}
                                    onChange={(e) => setGender(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Male</span>
                                </label>
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={gender === "Female" ? "" : "Female"}
                                    checked={gender === "Female"}
                                    onChange={(e) => setGender(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Female</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* tempat lahir */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Place of Birth</div>
                        </div>
                        <div className="column is-4">
                            <label className='control mb-2'>
                                <select className='input' value={provinsi} onChange={(e) => setProvinsi(e.target.value)}>
                                    <option value="">Nama Provinsi</option>
                                    {/* Check if provinsiList is an array before mapping over it */}
                                    {Array.isArray(suggestedProvinsi) && suggestedProvinsi.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="column is-4 is-offset-1">
                            <label className='control mb-2'>
                                <select className='input' value={tempatLahir} onChange={(e) => setTempatLahir(e.target.value)}>
                                    <option value="">Nama Kota</option>
                                    {/* Check if provinsiList is an array before mapping over it */}
                                    {Array.isArray(suggestedKota) && suggestedKota.map((data) => (
                                        <option key={data.id} value={data.name}>
                                            {data.name} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    {/* tanggal lahir */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Date of Birth</div>
                        </div>
                        <div className="column is-4">
                            <label className='control'>
                                <input type="date"className='input' value ={tanggalLahir} 
                                onChange={(e)=> setTanggalLahir(e.target.value)}
                                placeholder='Another Name' />
                            </label>
                        </div>
                    </div>
                    {/* fase hidup */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Life Phase</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2 radio container">
                            <label className="checkbox is-large"
                            title=" 0 - 10 Tahun">
                                <input
                                type="radio"
                                value={faseHidup === "Anak-anak" ? "" : "Anak-anak"}
                                checked={faseHidup === "Anak-anak"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="checkbox-text is-size-6 has-text-weight-normal">Anak-anak</span>
                            </label>
                            <label className="checkbox is-large"
                            title=" 10 - 12 Tahun">
                                <input
                                type="radio"
                                value={faseHidup === "Pra-remaja/Remaja" ? "" : "Pra-remaja/Remaja"}
                                checked={faseHidup === "Pra-remaja/Remaja"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="checkbox-text is-size-6 has-text-weight-normal">PraRemaja/Remaja</span>
                            </label>
                            <label className="checkbox is-large"
                            title =" SMA dan Kuliah ">
                                <input
                                type="radio"
                                value={faseHidup === "Pemuda" ? "" : "Pemuda"}
                                checked={faseHidup === "Pemuda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="checkbox-text is-size-6 has-text-weight-normal">Pemuda</span>
                            </label>
                            <label className="checkbox is-large"
                            title =" Profesional Muda">
                                <input
                                type="radio"
                                value={faseHidup === "Dewasa Muda" ? "" : "Dewasa Muda"}
                                checked={faseHidup === "Dewasa Muda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="checkbox-text is-size-6 has-text-weight-normal">Dewasa Muda</span>
                            </label>
                            <label className="checkbox is-large"
                            title =" Family Life">
                                <input
                                type="radio"
                                value={faseHidup === "Berkeluarga" ? "" : "Berkeluarga"}
                                checked={faseHidup === "Berkeluarga"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="checkbox-text is-size-6 has-text-weight-normal">Berkeluarga</span>
                            </label>
                            {/* Decease untuk org  yang sudah meningggal , role == admin */}
                            </div>
                        </div>
                    </div>
                    {/* Status dan keluarga */}
                    <div className='is-size-4 mb-3 has-text-weight-bold'>Status and Family</div>
                    <hr />
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Marital Status</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2">
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Belum Menikah" ? "" : "Belum Menikah"}
                                    checked={statusPerkawinan === "Belum Menikah"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Belum Menikah</span>
                                </label>
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Menikah" ? "" : "Menikah"}
                                    checked={statusPerkawinan === "Menikah"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Menikah</span>
                                </label>
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Janda/Duda" ? "" : "Janda/Duda"}
                                    checked={statusPerkawinan === "Janda/Duda"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Janda / duda </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* Wrapper for the entire marriage section, hidden if not married */}
                    <div className={`control ${statusPerkawinan !== "Menikah" ? 'hidden' : ''}`}>
                        {/* Marriage Date */}
                        <div className="columns">
                            <div className="column is-2">
                                <div className='is-size-5 mb-3 has-text-weight-semibold'>Marriage Date</div>
                            </div>
                            <div className="column is-4">
                                <label className='control'>
                                    <input 
                                        type="date" 
                                        className='input' 
                                        value={tanggalPerkawinan} 
                                        onChange={(e) => setTanggalPerkawinan(e.target.value)} 
                                        placeholder='Tanggal Perkawinan' 
                                    />
                                </label>
                            </div>
                        </div>
                        
                        {/* Partner's Name */}
                        <div className="columns">
                            <div className="column is-2">
                                <div className='is-size-5 mb-3 has-text-weight-semibold'>Partner Name</div>
                            </div>
                            <div className="column is-4">
                                <Select
                                    placeholder="Partner Name"
                                    value={selectedAll}
                                    options={optionAll}
                                    onChange={handleAllChange}
                                    isSearchable={true}
                                    filterOption={customFilter}
                                />
                            </div>
                        </div>
                    </div>

                    {/* orang tua */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Father Name</div>
                        </div>
                        <div className="column is-4">
                            <div className="control">
                                <Select
                                    placeholder="Father Name"
                                    value={selectedAyah}
                                    options={optionAyah}
                                    onChange={handleAyahChange}
                                    isSearchable={true}
                                    filterOption={customFilter}
                                />
                        </div>
                    </div>
                    </div>
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Mother Name</div>
                        </div>
                        <div className="column is-4">
                            <Select
                                placeholder="Mother Name"
                                value={selectedIbu}
                                options={optionIbu}
                                onChange={handleIbuChange}
                                isSearchable={true}
                                filterOption={customFilter}
                            />
                        </div>
                    </div>
                    {/* Kontak */}
                    <div className='is-size-4 mb-3 has-text-weight-bold'> Contact </div>
                    <hr />
                    {/* Nomor telepon */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Phone Number</div>
                        </div>
                        <div className="column is-9">
                            <label className='control '>
                                <input 
                                    type="text"
                                    className='input' 
                                    value={nomorTelepon} 
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Menggunakan regular expression untuk memeriksa apakah input adalah nomor telepon yang valid
                                        if (/^\d{0,13}$/.test(value)) {
                                            setNomorTelepon(value); // Jika valid, memperbarui nilai nomorTelepon
                                        }
                                    }}
                                    placeholder='Phone Number'
                                />
                            </label>
                        </div>
                    </div>
                    {/* Email */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Email </div>
                        </div>
                        <div className="column is-9">
                            <label className='control '>
                                <input type="text"className='input' value ={email} 
                                onChange={(e)=> setEmail(e.target.value)}
                                placeholder='Email'
                                />
                            </label>
                        </div>
                    </div>
                    <div className='is-size-4 mb-3 has-text-weight-bold'>Church Congregation Information:</div>
                    <hr />
                    {/* Gereja Lokal */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Local Church </div>                   
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2">
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={lokasiGereja === "Surabaya Barat" ? "" : "Surabaya Barat"}
                                    checked={lokasiGereja === "Surabaya Barat"}
                                    onChange={(e) => setLokasiGereja(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Surabaya Barat </span>
                                </label>
                                <label className="checkbox is-large">
                                    <input
                                    type="radio"
                                    value={lokasiGereja === "Surabaya Timur" ? "" : "Surabaya Timur"}
                                    checked={lokasiGereja === "Surabaya Timur"}
                                    onChange={(e) => setLokasiGereja(e.target.value)}
                                    />
                                    <span className="checkbox-text is-size-6 has-text-weight-normal">Surabaya Timur </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* kerinduan */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Longing </div>  
                        </div>
                        <div className="column is-9">
                            <label className='control mb-2'>
                                <input type="text"className='input' value ={kerinduan} 
                                onChange={(e)=> setKerinduan(e.target.value)}
                                placeholder='Kerinduan'
                                />
                            </label>
                        </div>
                    </div>
                    {/* komitmen berjemaat */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Congregational Commitment </div>  
                        </div>
                        <div className="column is-9">
                            <textarea
                            className='textarea'
                            value={komitmenBerjemaat}
                            onChange={(e) => setKomitmenBerjemaat(e.target.value)}
                            placeholder='Komitmen Berjemaat'
                            />
                        </div>
                    </div>
                    {/* Buttton Save */}
                    <div className="is-flex is-justify-content-flex-end mt-4">
                        <button className='button' style={{
                        backgroundColor: '#0052CC',
                        color: 'white',
                        padding: '10px 24px',
                        fontWeight: 'bold',
                        borderRadius: '8px'
                        }}>
                        Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
)
}
export default AddUser