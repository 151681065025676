import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css';

const ServiceEventAttendance = () => {

    // Untuk data yang ke DB
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    const [image, setImage] = useState(null)
    const [imageName, setName] = useState("");
    const [namaEvent,setNamaEvent] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [eventTimeIn, setEventTimeIn] = useState("");
    const [eventTimeOut, setEventTimeOut] = useState("");
    const [eventCapacity, setEventCapacity] = useState("");
    const [eventSetup, seteventSetup] = useState("");
    const [eventAttendanceTotal, seteventAttendanceTotal] = useState("");
    const [eventLokasi, seteventLokasi] = useState("");
    const [eventType, seteventType] = useState("");
    const[Status,setStatus] = useState("");


    // atribut khusus Discipleship event 
    const [seriesTitle, setseriesTitle] = useState("");
    const [fasilitator,setfasilitator] =useState("");
    
    // atribut khusus Discipleship event 
    const[discipleshipJourneyType,setDiscipleshipJourneyType] = useState("");

    // atribut khusus Common event 
    const[organizer,setOrganizer] = useState("");

    // atribut khusus Service 
    // Ada series title
    const[sermonTitle,setSermonTitle] = useState("");
    const[preacher,setPreacher] = useState("");

    // Atribut Khusus Activity LG
    const[lifegroupId,setLifegroupId] = useState("");

    // Inputan
    const [sessionName, setSessionName] = useState("");
    const [attendanceTotal, setAttendanceTotal] = useState(0);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            console.log(response)
            seteventType(response.data.eventType);
            setEventName(response.data.eventName);
            setEventDate(response.data.eventDate);
            setEventTimeIn(response.data.eventTimeIn);
            setEventTimeOut(response.data.eventTimeOut);
            setEventCapacity(response.data.eventCapacity);
            seteventSetup(response.data.eventSetup);
            seteventAttendanceTotal(response.data.eventAttendanceTotal);
            seteventLokasi(response.data.eventLokasi);
            setseriesTitle(response.data.seriesTitle);
            setfasilitator(response.data.fasilitator);
            setOrganizer(response.data.organizer);
            setSermonTitle(response.data.sermonTitle);
            setPreacher(response.data.preacher);
            setDiscipleshipJourneyType(response.data.discipleshipJourneyType);
            setStatus(response.data.status);
            setLifegroupId(response.data.lifegroupId);
    };

    const handleFormSubmit = async(e) => {
        e.preventDefault();
    
        const formData = new FormData();
        eventName && formData.append('eventName', eventName);
        eventDate && formData.append('eventDate', eventDate);
        eventTimeIn && formData.append('eventTimeIn', eventTimeIn);
        eventTimeOut && formData.append('eventTimeOut', eventTimeOut);
        eventSetup && formData.append('eventSetup', eventSetup);
        eventAttendanceTotal && formData.append('eventAttendanceTotal', eventAttendanceTotal);
        eventCapacity && formData.append('eventCapacity', eventCapacity);
        eventLokasi && formData.append('eventLokasi', eventLokasi);
        seriesTitle && formData.append('seriesTitle', seriesTitle);
        fasilitator && formData.append('fasilitator', fasilitator);
        organizer && formData.append('organizer', organizer);
        sermonTitle && formData.append('sermonTitle', sermonTitle);
        preacher && formData.append('preacher', preacher);
        lifegroupId && formData.append('lifegroupId', lifegroupId);
        eventType && formData.append('eventType', eventType);
        formData.append('discipleshipJourneyTypeId', discipleshipJourneyType);
        formData.append('status', Status);

        

    
        try {
            console.log(formData)
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateEvent/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=>{
        getUserById();
    }, []);

    return (
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Update Attendance</div>
                <form onSubmit={handleFormSubmit} action=''>
                    <div className='is-size-6 mb-2 has-text-weight-bold'>Attendance Total</div>
                    <label className='control'>
                        <input type="number" className='input' value={eventAttendanceTotal} 
                            onChange={(e) => seteventAttendanceTotal(Number(e.target.value))}
                            placeholder='Attendance Total'
                        />
                    </label>
                    <div className="control">
                        <button className='button is-success'>Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ServiceEventAttendance;
