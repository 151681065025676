import React, { useEffect, useRef } from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
    const confirmButtonRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            confirmButtonRef.current?.focus();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal is-active" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalMessage">
            <div className="modal-background" onClick={onClose}></div>
            <div className="modal-card "style={{ backgroundColor: 'white' ,padding : "40px" }}>
                <div className="has-text-centered has-background-white" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="-2 -3 24 24">
                        <path fill="#FF0000" d="m12.8 1.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.2 3.2 0 0 1-1.743.516H3.298C1.477 18 0 16.47 0 14.581c0-.639.173-1.264.498-1.807L7.2 1.613C8.162.01 10.196-.481 11.743.517c.428.276.79.651 1.057 1.096m-2.22.839a1.077 1.077 0 0 0-1.514.365L2.365 13.98a1.17 1.17 0 0 0-.166.602c0 .63.492 1.14 1.1 1.14H16.7c.206 0 .407-.06.581-.172a1.164 1.164 0 0 0 .353-1.57L10.933 2.817a1.1 1.1 0 0 0-.352-.365zM10 14a1 1 0 1 1 0-2a1 1 0 0 1 0 2m0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1"/>
                    </svg>
                    <p className="title is-4">Confirmation Data</p>
                    <p className="title is-4">Are you sure you want to add this data ?</p>
                </div>
                <div className="has-text-centered mt-5">
                    <button className="button is-danger mr-4" onClick={onClose}>Cancel</button>
                    <button className="button is-success " onClick={onConfirm}>Yes</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
