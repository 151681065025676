import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/CheckboxStyle.css';
import Select from 'react-select'
import ConfirmationModal from '../modals/ConfirmationModal';

const DiscipleshiEventAdd = () => {
  const [image, setImage] = useState(null);
  const [eventName, setEventName] = useState("");
  // const [eventDate, setEventDate] = useState("");
  const [eventDate, setEventDate] = useState({ day: '', month: '', year: '' });
  const [eventTimeIn, setEventTimeIn] = useState("");
  const [eventTimeOut, setEventTimeOut] = useState("");
  const [eventCapacity, setEventCapacity] = useState("");
  const [eventSetup, setEventSetup] = useState("");
  const [eventAttendanceTotal, seteventAttendanceTotal] = useState("");
  const [eventLokasi, seteventLokasi] = useState("");
  const [eventType, seteventType] = useState("");

  // atribut khusus Discipleship event 
  const[discipleshipJourneyType,setDiscipleshipJourneyType] = useState("");
  

  // atribut khusus Commonevent 
  const[organizer,setOrganizer] = useState("");

  // atribut khusus Service 
  // Ada series title
  const[sermonTitle,setSermonTitle] = useState("");
  const[preacher,setPreacher] = useState("");

  // Atribut Khusus Activity LG
  const[lifegroupId,setLifegroupId] = useState("");
  const [seriesTitle, setseriesTitle] = useState("");
  const [fasilitator,setfasilitator] =useState("");

   // Untuk Sugest Discipleship Journey
  const [optionDJ, setOptionDJ] = useState([]);
  const [selectedDJ, setSelectedDJ] = useState(null);

  // Untuk Sugest Discipleship Journey
  const [optionLG, setOptionLG] = useState([]);
  const [selectedLG, setSelectedLG] = useState(null);


  const navigate = useNavigate();

  // Modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState(null); // New state for formData

  const handleImageChange = (e) => {
    // Mengambil file gambar dari input
    const file = e.target.files[0];
    setImage(file);
  };

  // form post data
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // console.log(eventName,eventDate,eventTimeIn,eventTimeOut,eventSetup,eventLokasi,eventType,eventCapacity)
    // Validasi form data
    if (!eventName || !eventDate || !eventTimeIn || !eventTimeOut || !eventSetup  || !eventCapacity || !eventLokasi || !eventType ) {
      alert('Harap isi semua field yang diperlukan.');
      return;
    }

    // Mendapatkan tanggal hari ini
    var today = new Date();

    // Mendapatkan komponen tanggal, bulan, dan tahun
    var day2 = today.getDate();
    var month2 = today.getMonth() + 1; // Bulan dimulai dari 0
    var year2 = today.getFullYear();

    // Format tanggal sebagai 'YYYY-MM-DD'
    var formattedDate = year2 + '-' + (month2 < 10 ? '0' : '') + month2 + '-' + (day2 < 10 ? '0' : '') + day2;

    // Initialize formData
    const formData = new FormData();
    

    eventName && formData.append('eventName', eventName);
    eventDate && formData.append('eventDate', eventDate);
    eventTimeIn && formData.append('eventTimeIn', eventTimeIn);
    eventTimeOut && formData.append('eventTimeOut', eventTimeOut);
    eventSetup && formData.append('eventSetup', eventSetup);
    eventAttendanceTotal && formData.append('eventAttendanceTotal', eventAttendanceTotal);
    eventCapacity && formData.append('eventCapacity', eventCapacity);
    eventLokasi && formData.append('eventLokasi', eventLokasi);
    seriesTitle && formData.append('seriesTitle', seriesTitle);
    fasilitator && formData.append('fasilitator', fasilitator);
    organizer && formData.append('organizer', organizer);
    sermonTitle && formData.append('sermonTitle', sermonTitle);
    preacher && formData.append('preacher', preacher);
    lifegroupId && formData.append('lifegroupId', lifegroupId);
    eventType && formData.append('eventType', eventType);
    formData.append('discipleshipJourneyTypeId', discipleshipJourneyType);
    formData.append('createdAt', formattedDate);
    formData.append('status', 'active');

    // Tampilkan modal konfirmasi
    setModalOpen(true);

    // Simpan formData dalam state untuk digunakan di modal
    setFormData(formData);
  };

  const handleModalConfirm = async () => {
    // console.log('masuk')
    setModalOpen(false);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertEventImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        // Handle respon dari server (jika perlu)
        console.log('Server response:', response.data);
        navigate("/EventList");
    } catch (error) {
        // Tangkap dan cetak detail kesalahan
        if (error.response) {
            // Kesalahan dari respons server dengan status kode tertentu
            console.error('Server response error:', error);
        } else if (error.request) {
            // Kesalahan dalam melakukan permintaan ke server
            console.error('Request error:', error.request);
        } else {
            // Kesalahan lainnya
            console.error('Error uploading image:', error.message);
        }
    }
};
    
  // select discipleship jouirney type
  useEffect(() => {
    const fetchDJOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType`);
        const options = response.data.map((dj) => ({
          value: dj.discipleshipJourneyTypeId,
          label: dj.discipleshipJourneyTypeName,
        }));
        console.log(options)
        setOptionDJ(options);
      } catch (error) {
        console.error('Error fetching discipleship journey types:', error);
      }
    };

    fetchDJOptions();
  }, []);

  // Handle select discipleship journey type
  const handleDJChange = (selectedOption) => {
    setSelectedDJ(selectedOption);
    console.log('Selected DJ:', selectedOption);
    setDiscipleshipJourneyType(selectedOption.value);
    console.log(discipleshipJourneyType)
  };

  // select LG
  useEffect(() => {
    const fetchDJOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroup`);
        const options = response.data.map((dj) => ({
          value: dj.lifegroupId,
          label: dj.lifegroupName,
        }));
        console.log(options)
        setOptionLG(options);
      } catch (error) {
        console.error('Error fetching discipleship journey types:', error);
      }
    };

    fetchDJOptions();
  }, []);

  // Handle select LG
  const handleLGChange = (selectedOption) => {
    setSelectedLG(selectedOption);
    console.log('Selected LG:', selectedOption);
    setLifegroupId(selectedOption.value);
    console.log(discipleshipJourneyType)
  };

  const handleCheckboxChange = (e, type) => {
    if (eventType.includes(type)) {
      seteventType(eventType.filter((item) => item !== type));
    } else {
      seteventType([...eventType, type]);
    }
  };


  return (
    <div className="columns"> 
      <ConfirmationModal 
        isOpen={isModalOpen} 
        onConfirm={handleModalConfirm} 
        onClose={() => setModalOpen(false)} 
      />
      <div className='column'>
        <form onSubmit={(e) => { handleFormSubmit(e); }}>
          <div className='is-size-4 mb-4 has-text-weight-semibold'>Event Information</div>
          <hr />
          {/* Nama event */}
          <div className="columns">
            <div className="column is-2">
              <div className="is-size-5 has-text-weight-semibold">Event Name</div>
            </div>
            <div className="column is-8">
              <div className="field">
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    placeholder="Event Name"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Event Type */}
          <div className="columns">
            <div className="column is-2">
              <div className='is-size-5 mb-3 has-text-weight-medium'>Event Type </div>
            </div>
            <div className="column is-9">
              <div className="control mb-2 radio-container">
                {/* <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventType"
                    value="Discipleship Journey"
                    checked={eventType === "Discipleship Journey"}
                    onChange={(e) => seteventType(e.target.value)}
                  />
                  <span className="checkbox-text is-size-5 has-text-weight-semibold">Discipleship Journey</span>
                </label> */}
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventType"
                    value="CommonEvent"
                    checked={eventType === "CommonEvent"}
                    onChange={(e) => seteventType(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Common Event</span>
                </label>
                {/* <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventType"
                    value="Activity Lifegroup"
                    checked={eventType === "Activity Lifegroup"}
                    onChange={(e) => seteventType(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Activity Lifegroup</span>
                </label> */}
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventType"
                    value="Service"
                    checked={eventType === "Service"}
                    onChange={(e) => seteventType(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Service</span>
                </label>
              </div>
            </div>
          </div>
          {/* Lokasi Event */}
          <div className="columns">
            <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Location</div>
            </div>
            <div className="column is-8">
              <div className="field">
                <div className="control">
                  <input type="text" className='input' value={eventLokasi}
                    onChange={(e) => seteventLokasi(e.target.value)}
                    placeholder='Event Location' />
                </div>
              </div>
            </div>
          </div>
        {/* Tanggal Event */}
          <div className="columns">
            <div className="column is-2">
              <div className='is-size-5 mb-3 has-text-weight-semibold'>Tanggal Acara</div>
            </div>
            <div className="column is-3">
              <div className="field">
                <div className="control">
                  <input
                    type="date"
                    className="input custom-placeholder"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    placeholder="Tanggal Acara"
                  />
                </div>
              </div>
            </div>
            <div className="column is-1">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time In</div>
            </div>
            <div className="column is-2">
              <div className="field">
                <div className="control">
                  <input type="time" className='input' value={eventTimeIn}
                    onChange={(e) => setEventTimeIn(e.target.value)}
                    placeholder='Time In' />
                </div>
              </div>
            </div>
            <div className="column is-1">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time Out</div>
            </div>
            <div className="column is-2">
              <div className="field">
                <div className="control">
                  <input type="time" className='input' value={eventTimeOut}
                    onChange={(e) => setEventTimeOut(e.target.value)}
                    placeholder='Time Out' />
                </div>
              </div>
            </div>
          </div>
          <div className='is-size-4 mb-4 has-text-weight-semibold'>Capacity, Setup, and Attendance Information</div>
          <hr />
          {/* Kapasitas */}
          <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Capacity</div>
            </div>
            <div className="column is-3">
                <div className="field">
                  <div className="control">
                      <input type="number" className='input' value={eventCapacity}
                          onChange={(e) => setEventCapacity(e.target.value)}
                          placeholder='Capacity' />
                  </div>
                </div>
            </div>
            <div className="column is-1">
              <div className=' is-size-5 has-text-weight-semibold'>Setup</div>
            </div>
            <div className="column is-6 ">
              <div className="control  radio-container">
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventSetup"
                    value="setup 1"
                    checked={eventSetup === "setup 1"}
                    onChange={(e) => setEventSetup(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Setup 1</span>
                </label>
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventSetup"
                    value="setup 2"
                    checked={eventSetup === "setup 2"}
                    onChange={(e) => setEventSetup(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Setup 2</span>
                </label>
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventSetup"
                    value="setup 3"
                    checked={eventSetup === "setup 3"}
                    onChange={(e) => setEventSetup(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Setup 3</span>
                </label>
                <label className="checkbox is-large">
                  <input
                    type="radio"
                    name="eventSetup"
                    value="setup 4"
                    checked={eventSetup === "setup 4"}
                    onChange={(e) => setEventSetup(e.target.value)}
                  />
                  <span className="checkbox-text is-size-6 has-text-weight-semibold">Setup 4</span>
                </label>
              </div>
            </div>
          </div>
          {/* Setup */}
          {/* <div className="columns">
            <div className="column is-2">
              <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Setup</div>
            </div>
            <div className="column is-9">
                  <div className="control mb-2 radio-container">
                      <label className="radio is-large">
                          <input
                          type="radio"
                          value={eventSetup === "setup 1" ? "" : "setup 1"}
                          checked={eventSetup === "setup 1"}
                          onChange={(e) => seteventSetup(e.target.value)}
                          />
                          <span className="radio-text">setup 1</span>
                      </label>
                      <label className="radio is-large">
                          <input
                          type="radio"
                          value={eventSetup === "setup 2" ? "" : "setup 2"}
                          checked={eventSetup === "setup 2"}
                          onChange={(e) => seteventSetup(e.target.value)}
                          />
                          <span className="radio-text">setup 2</span>
                      </label>
                      <label className="radio is-large">
                          <input
                          type="radio"
                          value={eventSetup === "setup 3" ? "" : "setup 3"}
                          checked={eventSetup === "setup 3"}
                          onChange={(e) => seteventSetup(e.target.value)}
                          />
                          <span className="radio-text">setup 3</span>
                      </label>
                      <label className="radio is-large">
                          <input
                          type="radio"
                          value={eventSetup === "setup 4" ? "" : "setup 4"}
                          checked={eventSetup === "setup 4"}
                          onChange={(e) => seteventSetup(e.target.value)}
                          />
                          <span className="radio-text">Setup 4</span>
                      </label>
                  </div>
            </div>  
          </div> */}
          {/* Optional Attributes */}
          {/* CommonEvent */}
          {/* Organizer */}
          <div className='is-size-4 mb-4 has-text-weight-semibold'>{eventType} Information</div>
          <hr />
          <label className={`control ${eventType != "CommonEvent" ? 'hidden' : ''}`}>
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Organizer</div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="Text" className='input' value={organizer}
                              onChange={(e) => setOrganizer(e.target.value)}
                              placeholder='organizer' />
                      </div>
                    </div>
                  </div>
              </div>
          </label>
          {/* Service */}
          <label className={`control ${eventType != "Service" ? 'hidden' : ''}`}>
            {/* Series title */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="text" className='input' value={seriesTitle}
                              onChange={(e) => setseriesTitle(e.target.value)}
                              placeholder='Series title' />
                      </div>
                    </div>
                  </div>
              </div>
              {/* Sermon title */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Sermon Title </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="text" className='input' value={sermonTitle}
                              onChange={(e) => setSermonTitle(e.target.value)}
                              placeholder='Sermon title' />
                      </div>
                    </div>
                  </div>
              </div>
              {/* Preacher */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Preacher </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="text" className='input' value={preacher}
                              onChange={(e) => setPreacher(e.target.value)}
                              placeholder='Preacher' />
                      </div>
                    </div>
                  </div>
              </div>
          </label>
          {/* Activity LG */}
          <label className={`control ${eventType != "Activity Lifegroup" ? 'hidden' : ''}`}>
              {/* LG Name */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Lifegroup Name</div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                        <Select 
                          value={selectedLG}
                          options={optionLG}
                          onChange={handleLGChange}
                        />
                      </div>
                    </div>
                  </div>
              </div>
              {/* Series Title */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="text" className='input' value={seriesTitle} 
                              onChange={(e) => setseriesTitle(e.target.value)}
                              placeholder='Series Title' />
                      </div>
                    </div>
                  </div>
              </div>
              {/* Fasilitator */}
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Fasilitator</div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <input type="text" className='input' value={fasilitator} 
                              onChange={(e) => setfasilitator(e.target.value)}
                              placeholder='Fasilitator' />
                      </div>
                    </div>
                  </div>
              </div>

          </label>
          {/* Discipleship Journey */}
          <label className={`control ${eventType != "Discipleship Journey" ? 'hidden' : ''}`}>
              <div className="columns">
                  <div className="column is-2">
                          <div className=' is-size-5 mb-3 has-text-weight-semibold'>Discipleship Journey Type </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                          <Select 
                          options={optionDJ}
                          value={selectedDJ}
                          onChange={handleDJChange}
                          />
                      </div>
                    </div>
                  </div>
              </div>
          </label>
          <div className="is-flex is-justify-content-flex-end mt-4">
            <button className='button' style={{
              backgroundColor: '#0052CC',
              color: 'white',
              padding: '10px 24px',
              fontWeight: 'bold',
              borderRadius: '8px'
            }}>
              Save
            </button>
          </div>
        </form>
        
      </div>
    </div>
  );
};

export default DiscipleshiEventAdd;
