import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";

const TagList = () => {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getTags();
    }, []);

    const getTags = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTag`);
            console.log(response)
            setTags(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteTag = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTag/${id}`);
            setTags(tags.filter(tag => tag.tagId !== id));
        } catch (error) {
            console.error(error);
        }
    };

    const updateTag = async (id, status) => {
        console.log(status,id)
        // Cek status dan lakukan pembaruan yang sesuai
        if (status === 'active') {
            // Logika untuk status 'active'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateTag/${id}`, { status: 'deactive' });
            console.log(`success update Tag ${id} to inactive status`);
        } else {
           // Logika untuk status 'dective'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateTag/${id}`, { status: 'active' });
            console.log(`success update Tag ${id} to active status`);
        }
        // Lakukan update ke server atau state management
        // Refresh halaman setelah pembaruan
        window.location.reload(); // Reload halaman untuk menyegarkan data
    };

        

    const columns = useMemo(() => [
        {
            Header: 'Tag Name',
            accessor: 'tagName',
            Cell: ({ row }) => (
                <Link to={`/TagListByTag/${row.original.tagId}`}>
                    {row.original.tagName}
                </Link>
            )
        },
        {
            Header: 'Action',
            accessor: 'actions',
            Cell: ({ row }) => {
                console.log(row.original)
                const { tagId, status } = row.original;
                const isActive = status === 'active'; // Check if status is 'active'
                
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '36px' }}>
                        <button
                            onClick={() => updateTag(tagId, status)} // Pass both the event ID and status
                            style={{ 
                                background: 'none', 
                                border: 'none', 
                                cursor: 'pointer', 
                                padding: '0',
                                color: '#0082FF' // Set text color to match button color
                            }}
                        >
                            {isActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                    <path fill="#00A84D" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                    <path fill="#FF0000" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                        <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                    </path>
                                </svg>
                            )}
                        </button>
                    </div>
                );
            }
        }
    ], []);

    const data = useMemo(() => tags, [tags]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className="is-size-4 mb-4 ml-1 has-text-weight-bold">Tag</div>
                <div className="custom-button" onClick={() => navigate('/addTag')}>Add Tag</div>
                <div className="columns mt-2">
                    <div className="column is-2">
                        <div className="field is-grouped">
                            <div className="select">
                                <select
                                    value={pageSize}
                                    onChange={e => setPageSize(Number(e.target.value))}
                                    className="select"
                                >
                                    {[10, 25, 50, 100].map(size => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="control">
                                <label className="label ml-2">entries per page</label>
                            </div>
                        </div>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                    </div>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TagList;
