import React, { useState, useEffect } from "react";
import axios from "axios";
import { Chart, registerables } from 'chart.js';
import { Bar, Line } from "react-chartjs-2";
import "../style/DashboardStyle.css";

Chart.register(...registerables);

const Dashboard = () => {
    // Service Attendace
    // Date Untuk service
    const [startDateService, setstartDateService] = useState([]);
    const [endDateService, setendDateService] = useState([]);
    const [countservice, setCountService] = useState([]);
    const [dateservice, setDateService] = useState([]);

    //  Event By Date
    const [startDateEventByDate, setstartDateEventByDate] = useState([]);
    const [endDateEventByDate, setendDateEventByDate] = useState([]);
    const [counteventByDate, setCounteventByDate] = useState([]);
    const [dateEventByDate, setDateEventByDate] = useState([]);

    //  LG Activity By Date
    const [startDateLGACtivityByDate, setstartDateLGACtivityByDate] = useState([]);
    const [endDateLGACtivityByDate, setendDateLGACtivityByDate] = useState([]);
    const [countLGActivityByDate, setCountLGActivityByDate] = useState([]);
    const [dateLGACtivityByDate, setDateLGACtivityByDate] = useState([]);

    //  LG Leader By Date
    const [startDateLGLeaderByDate, setstartDateLGLeaderByDate] = useState([]);
    const [endDateLGLeaderByDate, setendDateLGLeaderByDate] = useState([]);
    const [countLGLeaderByDate, setCountLGLeaderByDate] = useState([]);
    const [dateLGLeaderByDate, setDateLGLeaderByDate] = useState([]);

    // Card
    const [startDateCardByDate, setstartDateCardByDate] = useState([]);
    const [endDateCardByDate, setendDateCardByDate] = useState([]);
    const [LGActiveThisDay, setLGActiveThisDay] = useState([]);
    const [LGActiveBeforeDay, setLGActiveBeforeDay] = useState([]);
    const [LGMemberThisDay, setLGMemberThisDay] = useState([]);
    const [LGMemberBeforeDay, setLGMemberBeforeDay] = useState([]);
    const [LGACtiveThisDay, setLGACtiveThisDay] = useState([]);
    const [LGACtiveBeforeDay, setLGACtiveBeforeDay] = useState([]);

    // Username
    const [Username, setUsername] = useState("")

    // Menentukan range
    const [range, setRange] = useState('');

    const [user, setUser] = useState(null);

    const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

    // Dashboard
    
    // Sunday Service Attendance Chart
    const getService = async (startDate, endDate) => {
        try {
            console.log(startDate,endDate);
            const request = `${process.env.REACT_APP_API_URL}/viewEventService/${formatDate(startDate)}/${formatDate(endDate)}`
            console.log(request);
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewEventService/${formatDate(startDate)}/${formatDate(endDate)}`);
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            const formattedDates = eventData.map(event => {
                const date = new Date(event.eventDate); // Konversi tanggal string menjadi objek Date
                return date.toLocaleDateString(); // Gunakan toLocaleDateString untuk memformat tanggal
            });
            const counts = eventData.map(event => event.eventAttendanceTotal); // Ambil total attendance dari setiap event
            setDateService(formattedDates); // Set array tanggal yang telah diformat ke dalam state
            setCountService(counts); // Set array total attendance ke dalam state

        } catch (error) {
            console.log(error);
        }
    };

    // Event 
    const getEventByDate = async (startDate, endDate) => {

        try {
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewEventByDate/${startDate}/${endDate}`);
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            console.log(startDate, endDate)
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const eventsCountPerWeek = {};

            // Menghitung selisih hari antara startDate dan endDate
            const differenceInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));


            if (differenceInDays === 7) {
                // Inisialisasi objek untuk menyimpan jumlah acara setiap hari
                const eventsCountPerDay = {};

                // Hitung jumlah acara dalam satu minggu dari hari ini
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Mendapatkan tanggal satu minggu yang lalu

                // Filter acara yang terjadi dalam satu minggu terakhir
                const eventsInPastWeek = eventData.filter(event => {
                    const eventDate = new Date(event.eventDate);
                    return eventDate >= oneWeekAgo;
                });

                // Membuat struktur data yang merepresentasikan semua hari dalam satu minggu terakhir
                for (let i = 0; i < 8; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const formattedDate = date.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    eventsCountPerDay[formattedDate] = 0; // Inisialisasi jumlah acara untuk setiap hari dengan nilai nol
                }

                // Mengisi jumlah acara yang sesuai untuk setiap hari yang memiliki acara
                eventsInPastWeek.forEach(event => {
                    const eventDate = new Date(event.eventDate);
                    const formattedDate = eventDate.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    if (eventsCountPerDay[formattedDate] !== undefined) {
                        eventsCountPerDay[formattedDate]++;
                    }
                });

                const dates = Object.keys(eventsCountPerDay);
                const counts = Object.values(eventsCountPerDay);

                setCounteventByDate(dates);
                setDateEventByDate(counts);
            }
            else if (differenceInDays <= 31) {
                // Jika rentang tanggal kurang dari atau sama dengan 31 hari (sekitar satu bulan)

                // Salin nilai endDate agar tidak mengubah nilai aslinya
                const akhir = new Date(endDate);
                akhir.setDate(akhir.getDate() - 30);

                const minggu = [];
                const today = new Date();
                let currentDate = new Date(startDate); // Gunakan startDate sebagai titik awal iterasi

                //ganti +7 dan buatq if untuk melakukan pengeekan jika mulai bukan senin maka 1 week dari hari - minggu  
                while (currentDate <= endDate) {
                    const weekStartDate = new Date(currentDate);
                    let weekEndDate = new Date(currentDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 7);

                    // Jika tanggal akhir minggu melebihi hari ini, atur tanggal akhir minggu menjadi hari ini
                    if (weekEndDate > today) {
                        weekEndDate = new Date(today);
                    }

                    minggu.push([weekStartDate, weekEndDate]); // Tambahkan rentang minggu dalam bentuk array

                    currentDate.setDate(currentDate.getDate() + 7);
                }
                
                 // Inisialisasi setiap rentang minggu dengan nilai 0
                minggu.forEach(([start, end]) => {
                    const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[weekKey] = 0;
                });

                console.log("Data acara:");
                console.log(eventData);

                eventData.forEach(event => {
                    console.log("Tanggal acara:", event.eventDate);
                    const eventDate = new Date(event.eventDate);
                    let eventProcessed = false; // Tambahkan variabel boolean untuk menandai apakah tanggal acara sudah diproses

                for (const [start, end] of minggu) {
                    console.log("Rentang minggu yang diuji:", start, "-", end);
                    if (eventDate >= start && eventDate <= end) {
                        console.log("Tanggal acara cocok dengan rentang minggu! " + start, "-", end);
                        const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                        console.log(eventsCountPerWeek)
                        eventsCountPerWeek[weekKey]++;
                        console.log(eventsCountPerWeek)
                        console.log(weekKey)
                        eventProcessed = true;
                        break;
                    }
                }
                console.log(eventsCountPerWeek)

                if (!eventProcessed) {
                    console.log("Tanggal acara tidak cocok dengan rentang minggu:", eventDate);
                    // Temukan rentang minggu terdekat
                    let nearestWeek;
                    let nearestDiff = Infinity;
                    for (const [start, end] of minggu) {
                        const diffStart = Math.abs(eventDate - start);
                        const diffEnd = Math.abs(eventDate - end);
                        const minDiff = Math.min(diffStart, diffEnd);
                        if (minDiff < nearestDiff) {
                            nearestDiff = minDiff;
                            nearestWeek = [start, end];
                        }
                    }
                    // Masukkan tanggal acara ke rentang minggu terdekat
                    const [nearestStart, nearestEnd] = nearestWeek;
                    const nearestWeekKey = nearestStart.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[nearestWeekKey]++;
                    }
                });

                console.log("Hasil pencatatan acara per minggu:");
                console.log(eventsCountPerWeek);

                const dates = Object.keys(eventsCountPerWeek);
                const counts = Object.values(eventsCountPerWeek);
                setCounteventByDate(dates);
                setDateEventByDate(counts);
            }
            else if (differenceInDays <= 366) {
                // Jika rentang tanggal kurang dari atau sama dengan 365 hari (sekitar satu tahun)
                const startYear = startDate.getFullYear(); // Tahun awal
                const endYear = endDate.getFullYear(); // Tahun akhir

                // Inisialisasi objek untuk menyimpan jumlah acara per bulan
                const eventsCountPerMonth = {};

                // Loop melalui setiap tahun dalam rentang waktu
                for (let year = startYear; year <= endYear; year++) {
                    const startMonth = (year === startYear) ? startDate.getMonth() : 0; // Bulan awal
                    const endMonth = (year === endYear) ? endDate.getMonth() : 11; // Bulan akhir

                    // Loop melalui setiap bulan dalam tahun yang sedang diproses
                    for (let month = startMonth; month <= endMonth; month++) {
                        const firstDayOfMonth = new Date(year, month, 1); // Tanggal pertama bulan
                        const lastDayOfMonth = new Date(year, month + 1, 0); // Tanggal terakhir bulan

                        // Inisialisasi jumlah acara pada bulan ini
                        let eventCountThisMonth = 0;

                        // Loop melalui setiap acara
                        eventData.forEach(event => {
                            const eventDate = new Date(event.eventDate);
                            // Periksa apakah acara berada dalam rentang bulan yang sedang diproses
                            if (eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth) {
                                eventCountThisMonth++;
                            }
                        });

                        // Simpan jumlah acara pada bulan ini ke objek eventsCountPerMonth
                        const monthKey = `${year}-${month + 1}`; // Format key "tahun-bulan"
                        eventsCountPerMonth[monthKey] = eventCountThisMonth;
                    }
                }

                console.log("Hasil pencatatan acara per bulan:");
                console.log(eventsCountPerMonth);

                // Ambil tanggal-tanggal dan jumlah acara per bulan
                const months = Object.keys(eventsCountPerMonth);
                const eventCounts = Object.values(eventsCountPerMonth);

                // Lakukan sesuatu dengan data ini, misalnya, kirim ke fungsi setCounteventByMonth dan setDateEventByMonth
                setCounteventByDate(months);
                setDateEventByDate(eventCounts);
            }

            else {
                console.log("Range of dates is not set" + differenceInDays);
                setCounteventByDate(0);
                setDateEventByDate(0);

            }

        } catch (error) {
            console.log(error);
        }
    };

    // LG Activity 
    const getLGActivityByDate = async (startDate, endDate) => {
        try {
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewLGActivityByDate/${formatDate(startDate)}/${formatDate(endDate)}`);
            const request = `${process.env.REACT_APP_API_URL}/viewLGActivityByDate/${formatDate(startDate)}/${formatDate(endDate)}`;
            console.log(request)
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            console.log(startDate, endDate)
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const eventsCountPerWeek = {};

            // Menghitung selisih hari antara startDate dan endDate
            const differenceInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));


            if (differenceInDays === 7) {
                // Inisialisasi objek untuk menyimpan jumlah acara setiap hari
                const eventsCountPerDay = {};

                // Hitung jumlah acara dalam satu minggu dari hari ini
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 6); // Mendapatkan tanggal satu minggu yang lalu

                // Filter acara yang terjadi dalam satu minggu terakhir
                const eventsInPastWeek = eventData.filter(event => {
                    const eventDate = new Date(event.eventDate);
                    return eventDate >= oneWeekAgo;
                });

                // Membuat struktur data yang merepresentasikan semua hari dalam satu minggu terakhir
                for (let i = 0; i < 7; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const formattedDate = date.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    eventsCountPerDay[formattedDate] = 0; // Inisialisasi jumlah acara untuk setiap hari dengan nilai nol
                }

                // Mengisi jumlah acara yang sesuai untuk setiap hari yang memiliki acara
                eventsInPastWeek.forEach(event => {
                    const eventDate = new Date(event.eventDate);
                    const formattedDate = eventDate.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    if (eventsCountPerDay[formattedDate] !== undefined) {
                        eventsCountPerDay[formattedDate]++;
                    }
                });
                console.log(eventsCountPerDay)
                const dates = Object.keys(eventsCountPerDay);
                const counts = Object.values(eventsCountPerDay);

                console.log("event:"+eventsCountPerDay)

                setCountLGActivityByDate(dates);
                setDateLGACtivityByDate(counts);
            }
            else if (differenceInDays <= 31) {
                // Jika rentang tanggal kurang dari atau sama dengan 31 hari (sekitar satu bulan)

                // Salin nilai endDate agar tidak mengubah nilai aslinya
                const akhir = new Date(endDate);
                akhir.setDate(akhir.getDate() - 30);

                const minggu = [];
                const today = new Date();
                let currentDate = new Date(startDate); // Gunakan startDate sebagai titik awal iterasi

                while (currentDate <= endDate) {
                    const weekStartDate = new Date(currentDate);
                    let weekEndDate = new Date(currentDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 6);

                    // Jika tanggal akhir minggu melebihi hari ini, atur tanggal akhir minggu menjadi hari ini
                    if (weekEndDate > today) {
                        weekEndDate = new Date(today);
                    }

                    minggu.push([weekStartDate, weekEndDate]); // Tambahkan rentang minggu dalam bentuk array

                    currentDate.setDate(currentDate.getDate() + 7);
                }

                // Inisialisasi setiap rentang minggu dengan nilai 0
                minggu.forEach(([start, end]) => {
                    const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[weekKey] = 0;
                });

                console.log("Data acara:");
                console.log(eventData);

                eventData.forEach(event => {
                    console.log("Tanggal acara:", event.eventDate);
                    const eventDate = new Date(event.eventDate);
                    let eventProcessed = false; // Tambahkan variabel boolean untuk menandai apakah tanggal acara sudah diproses

                    for (const [start, end] of minggu) {
                    console.log("Rentang minggu yang diuji:", start, "-", end);
                    if (eventDate >= start && eventDate <= end) {
                        console.log("Tanggal acara cocok dengan rentang minggu! " + start, "-", end);
                        const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                        console.log(eventsCountPerWeek)
                        eventsCountPerWeek[weekKey]++;
                        console.log(eventsCountPerWeek)
                        console.log(weekKey)
                        eventProcessed = true;
                        break;
                    }
                }
                console.log(eventsCountPerWeek)

                if (!eventProcessed) {
                    console.log("Tanggal acara tidak cocok dengan rentang minggu:", eventDate);
                    // Temukan rentang minggu terdekat
                    let nearestWeek;
                    let nearestDiff = Infinity;
                    for (const [start, end] of minggu) {
                        const diffStart = Math.abs(eventDate - start);
                        const diffEnd = Math.abs(eventDate - end);
                        const minDiff = Math.min(diffStart, diffEnd);
                        if (minDiff < nearestDiff) {
                            nearestDiff = minDiff;
                            nearestWeek = [start, end];
                        }
                    }
                    // Masukkan tanggal acara ke rentang minggu terdekat
                    const [nearestStart, nearestEnd] = nearestWeek;
                    const nearestWeekKey = nearestStart.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[nearestWeekKey]++;
                    }
                });

                console.log("Hasil pencatatan acara per minggu:");
                console.log(eventsCountPerWeek);

                const dates = Object.keys(eventsCountPerWeek);
                const counts = Object.values(eventsCountPerWeek);
                setCountLGActivityByDate(dates);
                setDateLGACtivityByDate(counts);
            }
            else if (differenceInDays <= 366) {
                // Jika rentang tanggal kurang dari atau sama dengan 365 hari (sekitar satu tahun)
                const startYear = startDate.getFullYear(); // Tahun awal
                const endYear = endDate.getFullYear(); // Tahun akhir

                // Inisialisasi objek untuk menyimpan jumlah acara per bulan
                const eventsCountPerMonth = {};

                // Loop melalui setiap tahun dalam rentang waktu
                for (let year = startYear; year <= endYear; year++) {
                    const startMonth = (year === startYear) ? startDate.getMonth() : 0; // Bulan awal
                    const endMonth = (year === endYear) ? endDate.getMonth() : 11; // Bulan akhir

                    // Loop melalui setiap bulan dalam tahun yang sedang diproses
                    for (let month = startMonth; month <= endMonth; month++) {
                        const firstDayOfMonth = new Date(year, month, 1); // Tanggal pertama bulan
                        const lastDayOfMonth = new Date(year, month + 1, 0); // Tanggal terakhir bulan

                        // Inisialisasi jumlah acara pada bulan ini
                        let eventCountThisMonth = 0;

                        // Loop melalui setiap acara
                        eventData.forEach(event => {
                            const eventDate = new Date(event.eventDate);
                            // Periksa apakah acara berada dalam rentang bulan yang sedang diproses
                            if (eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth) {
                                eventCountThisMonth++;
                            }
                        });

                        // Simpan jumlah acara pada bulan ini ke objek eventsCountPerMonth
                        const monthKey = `${year}-${month + 1}`; // Format key "tahun-bulan"
                        eventsCountPerMonth[monthKey] = eventCountThisMonth;
                    }
                }

                console.log("Hasil pencatatan acara per bulan:");
                console.log(eventsCountPerMonth);

                // Ambil tanggal-tanggal dan jumlah acara per bulan
                const months = Object.keys(eventsCountPerMonth);
                const eventCounts = Object.values(eventsCountPerMonth);

                // Lakukan sesuatu dengan data ini, misalnya, kirim ke fungsi setCounteventByMonth dan setDateEventByMonth
                setCountLGActivityByDate(months);
                setDateLGACtivityByDate(eventCounts);
            }

            else {
                console.log("Range of dates is not set" + differenceInDays);
                setCounteventByDate(0);
                setDateEventByDate(0);

            }

        } catch (error) {
            console.log(error);
        }
    };

    // LG Leader 
    const getLGLeaderByDate = async (startDate, endDate) => {
        try {
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewLGLeaderByDateUser/${formatDate(startDate)}/${formatDate(endDate)}`);
            console.log(responseUser.data);
            const eventData = responseUser.data; // Simpan data respons ke dalam variabel
            console.log(startDate, endDate)
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const eventsCountPerWeek = {};

            // Menghitung selisih hari antara startDate dan endDate
            const differenceInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));


            if (differenceInDays === 7) {
                // Inisialisasi objek untuk menyimpan jumlah acara setiap hari
                const eventsCountPerDay = {};

                // Hitung jumlah acara dalam satu minggu dari hari ini
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 6); // Mendapatkan tanggal satu minggu yang lalu

                // Filter acara yang terjadi dalam satu minggu terakhir
                const eventsInPastWeek = eventData.filter(event => {
                    const eventDate = new Date(event.createdAt);
                    return eventDate >= oneWeekAgo;
                });

                // Membuat struktur data yang merepresentasikan semua hari dalam satu minggu terakhir
                for (let i = 0; i < 7; i++) {
                    const date = new Date(oneWeekAgo);
                    date.setDate(oneWeekAgo.getDate() + i);
                    const formattedDate = date.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    eventsCountPerDay[formattedDate] = 0; // Inisialisasi jumlah acara untuk setiap hari dengan nilai nol
                }

                // Mengisi jumlah acara yang sesuai untuk setiap hari yang memiliki acara
                eventsInPastWeek.forEach(event => {
                    const eventDate = new Date(event.createdAt);
                    const formattedDate = eventDate.toLocaleDateString('en-GB'); // Menggunakan opsi 'en-GB' untuk format "dd-mm-yyyy"
                    if (eventsCountPerDay[formattedDate] !== undefined) {
                        eventsCountPerDay[formattedDate]++;
                    }
                });

                const dates = Object.keys(eventsCountPerDay);
                const counts = Object.values(eventsCountPerDay);

                console.log(dates)
                console.log(counts)
                setCountLGLeaderByDate(dates);
                setDateLGLeaderByDate(counts);
            }
            else if (differenceInDays <= 31) {
                // Jika rentang tanggal kurang dari atau sama dengan 31 hari (sekitar satu bulan)

                // Salin nilai endDate agar tidak mengubah nilai aslinya
                const akhir = new Date(endDate);
                akhir.setDate(akhir.getDate() - 30);

                const minggu = [];
                const today = new Date();
                let currentDate = new Date(startDate); // Gunakan startDate sebagai titik awal iterasi

                while (currentDate <= endDate) {
                    const weekStartDate = new Date(currentDate);
                    let weekEndDate = new Date(currentDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 6);

                    // Jika tanggal akhir minggu melebihi hari ini, atur tanggal akhir minggu menjadi hari ini
                    if (weekEndDate > today) {
                        weekEndDate = new Date(today);
                    }

                    minggu.push([weekStartDate, weekEndDate]); // Tambahkan rentang minggu dalam bentuk array

                    currentDate.setDate(currentDate.getDate() + 7);
                }

                // Inisialisasi setiap rentang minggu dengan nilai 0
                minggu.forEach(([start, end]) => {
                    const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[weekKey] = 0;
                });

                console.log("Data acara:");
                console.log(eventData);

                eventData.forEach(event => {
                    console.log("Tanggal acara:", event.createdAt);
                    const eventDate = new Date(event.createdAt);
                    let eventProcessed = false;

                for (const [start, end] of minggu) {
                    console.log("Rentang minggu yang diuji:", start, "-", end);
                    if (eventDate >= start && eventDate <= end) {
                        console.log("Tanggal acara cocok dengan rentang minggu! " + start, "-", end);
                        const weekKey = start.toISOString().slice(5, 10); // Only take MM-DD
                        console.log(eventsCountPerWeek)
                        eventsCountPerWeek[weekKey]++;
                        console.log(eventsCountPerWeek)
                        console.log(weekKey)
                        eventProcessed = true;
                        break;
                    }
                }
                console.log(eventsCountPerWeek)

                if (!eventProcessed) {
                    console.log("Tanggal acara tidak cocok dengan rentang minggu:", eventDate);
                    // Temukan rentang minggu terdekat
                    let nearestWeek;
                    let nearestDiff = Infinity;
                    for (const [start, end] of minggu) {
                        const diffStart = Math.abs(eventDate - start);
                        const diffEnd = Math.abs(eventDate - end);
                        const minDiff = Math.min(diffStart, diffEnd);
                        if (minDiff < nearestDiff) {
                            nearestDiff = minDiff;
                            nearestWeek = [start, end];
                        }
                    }
                    // Masukkan tanggal acara ke rentang minggu terdekat
                    const [nearestStart, nearestEnd] = nearestWeek;
                    const nearestWeekKey = nearestStart.toISOString().slice(5, 10); // Only take MM-DD
                    eventsCountPerWeek[nearestWeekKey]++;
                    }
                });

                console.log("Hasil pencatatan acara per minggu:");
                console.log(eventsCountPerWeek);

                const dates = Object.keys(eventsCountPerWeek);
                const counts = Object.values(eventsCountPerWeek);
                setCountLGLeaderByDate(dates);
                setDateLGLeaderByDate(counts);
            }
            else if (differenceInDays <= 366) {
                // Jika rentang tanggal kurang dari atau sama dengan 365 hari (sekitar satu tahun)
                const startYear = startDate.getFullYear(); // Tahun awal
                const endYear = endDate.getFullYear(); // Tahun akhir

                // Inisialisasi objek untuk menyimpan jumlah acara per bulan
                const eventsCountPerMonth = {};

                // Loop melalui setiap tahun dalam rentang waktu
                for (let year = startYear; year <= endYear; year++) {
                    const startMonth = (year === startYear) ? startDate.getMonth() : 0; // Bulan awal
                    const endMonth = (year === endYear) ? endDate.getMonth() : 11; // Bulan akhir

                    // Loop melalui setiap bulan dalam tahun yang sedang diproses
                    for (let month = startMonth; month <= endMonth; month++) {
                        const firstDayOfMonth = new Date(year, month, 1); // Tanggal pertama bulan
                        const lastDayOfMonth = new Date(year, month + 1, 0); // Tanggal terakhir bulan

                        // Inisialisasi jumlah acara pada bulan ini
                        let eventCountThisMonth = 0;

                        // Loop melalui setiap acara
                        eventData.forEach(event => {
                            const eventDate = new Date(event.createdAt);
                            // Periksa apakah acara berada dalam rentang bulan yang sedang diproses
                            if (eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth) {
                                eventCountThisMonth++;
                            }
                        });

                        // Simpan jumlah acara pada bulan ini ke objek eventsCountPerMonth
                        const monthKey = `${year}-${month + 1}`; // Format key "tahun-bulan"
                        eventsCountPerMonth[monthKey] = eventCountThisMonth;
                    }
                }

                console.log("Hasil pencatatan acara per bulan:");
                console.log(eventsCountPerMonth);

                // Ambil tanggal-tanggal dan jumlah acara per bulan
                const months = Object.keys(eventsCountPerMonth);
                const eventCounts = Object.values(eventsCountPerMonth);

                // Lakukan sesuatu dengan data ini, misalnya, kirim ke fungsi setCounteventByMonth dan setDateEventByMonth
                setCountLGLeaderByDate(months);
                setDateLGLeaderByDate(eventCounts);
            }

            else {
                console.log("Range of dates is not set" + differenceInDays);
                setCounteventByDate(0);
                setDateEventByDate(0);

            }

        } catch (error) {
            console.log(error);
        }
    };

    // Card

    // hitung per lg bukan per activity
    // LG Active
    const getLGActive = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLGActiveByDate/${formatDate(startDate)}/${formatDate(endDate)}`);
            console.log(response.data)
            setLGActiveThisDay(response.data.eventsEndDate.length)
            setLGActiveBeforeDay(response.data.eventsStartDate.length)
            
        } catch (error) {
            console.log(error);
        }
    };

    // new LG Member
    const getLGMember = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLGMemberByDate/${formatDate(startDate)}/${formatDate(endDate)}`);
            console.log(startDate)
            console.log(endDate)
            console.log(response)
            setLGMemberBeforeDay(response.data.lifeGroupMemberEndDate.length)  
            console.log(LGMemberBeforeDay)          
            setLGMemberThisDay(response.data.lifeGroupMemberStartDate.length)        

        } catch (error) {
            console.log(error);
        }
    };
    // Active in LG
    const getActiveInLg = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListByDate/${formatDate(startDate)}/${formatDate(endDate)}`);
            console.log(response)
            const personIdCounts = response.data.attendanceListEnd.reduce((counts, entry) => {
                counts[entry.personId] = (counts[entry.personId] || 0) + 1;
                return counts;
            }, {});
            const personIdCountsStart = response.data.attendanceListStart.reduce((counts, entry) => {
                counts[entry.personId] = (counts[entry.personId] || 0) + 1;
                return counts;
            }, {});
            const uniquePersonIdsCount = Object.keys(personIdCounts).length;
            const uniquePersonIdsCountStart = Object.keys(personIdCountsStart).length;
            console.log('Jumlah personId unik:', uniquePersonIdsCount);
            console.log('Jumlah personId unik:', uniquePersonIdsCountStart);


            setLGACtiveThisDay(uniquePersonIdsCount)            
            setLGACtiveBeforeDay(uniquePersonIdsCountStart)    
            
        } catch (error) {
            console.log(error);
        }
    };
    // Discipleship Journey Start
    const getDiscJourneyStart = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscJourneyByDate/${startDate}/${endDate}`);
            console.log(response)
            console.log(endDate)
            // setDJStartThisDay(response.data.countEndDate)            
            // setDJStartBeforeDay(response.data.countStartDate)      
            
        } catch (error) {
            console.log(error);
        }
    };

    const ServiceChartComponent = ({ dataY, dataX }) => {
        const data = {
            labels: dateservice, // Label untuk setiap bar
            datasets: [
                {
                    label: 'Count', // Label untuk dataset secara keseluruhan
                    data: countservice, // Menggunakan prop 'countservice' langsung
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
                }
            ]
        };

        const options = {
            scales: {
                y: {
                    type: 'linear',
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Attendance Count', // Label untuk sumbu Y
                        font: {
                            size: 16,
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'Date', // Label untuk sumbu X
                        font: {
                            size: 16,
                        }
                    }
                }
            }
        };

        return <Line data={data} options={options} />;
    };


    const ChartComponent = ({ dataY, dataX, namaChartY, namaChartX }) => {
        const data = {
            labels: dataY, // Label untuk setiap bar
            datasets: [
                {
                    label: 'Count', // Label untuk dataset secara keseluruhan
                    data: dataX, // Menggunakan prop 'countservice' langsung
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
                }
            ]
        };

        const options = {
            scales: {
                y: {
                    type: 'linear',
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: namaChartY, // Label untuk sumbu Y
                        font: {
                            size: 16,
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: namaChartX, // Label untuk sumbu X
                        font: {
                            size: 16,
                        }
                    }
                }
            }
        };

        return <Line data={data} options={options} />;
    };

    // Untuk button periode events
    const handleRangeChangEventByDate = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateEventByDate(currentDate)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateEventByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateEventByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateEventByDate(new Date(startDate)); // Set the new start date
                break;
            default:
                break;
        }
        getEventByDate(startDate, currentDate);

    };

    // Untuk button periode sunday service
    const handleRangeChange = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateService(currentDate)
        console.log(endDateService)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateService(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateService(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 month
                setstartDateService(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        getService(startDate, currentDate);
    };

    // Untuk button lGActivity
    const handleRangeChangeLGActivity = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateLGACtivityByDate(currentDate)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateLGACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateLGACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateLGACtivityByDate(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        console.log(startDate,currentDate)
        getLGActivityByDate(startDate, currentDate);
    };

    // Untuk button LGLeader
    const handleRangeChangeLGLeader = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateLGLeaderByDate(currentDate)
        console.log(endDateService)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateLGLeaderByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateLGLeaderByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateLGLeaderByDate(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        getLGLeaderByDate(startDate, currentDate);
    };

     // section kanan dashboard
    const handleRangeChangeCardSection = (selectedRange) => {
        const currentDate = new Date(); // Get current date
        let startDate = new Date(currentDate);
        setendDateCardByDate(currentDate)
        console.log(endDateService)
        switch (selectedRange) {
            case '1W':
                startDate.setDate(startDate.getDate() - 7); // Update the start date by subtracting 7 days
                setstartDateCardByDate(new Date(startDate)); // Set the new start date
                break;
            case '1M':
                startDate.setMonth(startDate.getMonth() - 1); // Update the start date by subtracting 1 month
                setstartDateCardByDate(new Date(startDate)); // Set the new start date
                break;
            case '1Y':
                startDate.setFullYear(startDate.getFullYear() - 1); // Update the start date by subtracting 1 year
                setstartDateCardByDate(new Date(startDate)); // Set the new start date
                break;
            // Tambahkan case lain sesuai dengan kebutuhan, misalnya untuk rentang waktu 6 bulan, 1 tahun, dst.
            default:
                break;
        }
        getLGActive(startDate, currentDate);
        getLGMember(startDate, currentDate);
        getActiveInLg(startDate, currentDate);
    };

    // get Token Data
    const getTokenData = () => {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token) {
            try {
                const tokenData = JSON.parse(atob(token.split('.')[1])); // Parsing bagian payload dari token
                return tokenData;
            } catch (error) {
                console.error('Error parsing token:', error);
                return null;
            }
        } else {
            return null;
        }
    };

    const getUsername = () => {
        const tokenData = getTokenData();
        if (tokenData) {
            console.log(tokenData);
            setUsername(tokenData.username);
        } else {
            return null; 
        }
    }


    useEffect(() => {
        getUsername();
    }, []);

    
    return (
        <div className="columns">
            <div className="column">
                <div className="columns">
                    <div className="column is-4">
                        {/* Section 1 Chart Sunday Service */}
                        <div className="card mt-2">
                            <div className='is-size-5 mb-4 has-text-weight-bold'>Sunday Service Attendance</div>
                            <div>
                                {/* Tambahkan tombol untuk memilih rentang waktu */}
                                <div className="buttons">
                                    <button className={`button ${range === '1M' ? 'is-primary' : ''}`} onClick={() => handleRangeChange('1M')}>1M</button>
                                    <button className={`button ${range === '1Y' ? 'is-primary' : ''}`} onClick={() => handleRangeChange('1Y')}>1Y</button>
                                    {/* Tambahkan tombol-tombol lainnya sesuai dengan kebutuhan */}
                                </div>
                            </div>
                            <div id="chart-container">
                                <ServiceChartComponent dataY={countservice} dataX={dateservice} /> { }
                            </div>
                        </div>
                    </div>
                    {/* Section 2 */}
                    <div className="columns is-multiline container" style={{ height: '100%' }}>
                        {/* Lifegroup Summary */}
                        <div className="column is-full">
                            <div className="card" style={{ height: '100%' }}>
                                <div className='is-size-5 mb-4 has-text-weight-bold'>Lifegroup Summary</div>
                                <div className="buttons">
                                    <button className={`button ${range === '1W' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeCardSection('1W')}>1W</button>
                                    <button className={`button ${range === '1M' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeCardSection('1M')}>1M</button>
                                    <button className={`button ${range === '1Y' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeCardSection('1Y')}>1Y</button>
                                    {/* Tambahkan tombol-tombol lainnya sesuai dengan kebutuhan */}
                                </div>
                            </div>
                        </div>

                        {/* Card */}
                        <div className="columns is-multiline" style={{ width: '100%' }}>
                            {/* Lifegroup Active */}
                            <div className="column is-one-third">
                                <div className="card" style={{ margin: '20px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: '10px', height: 'auto' }}>
                                    <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17.55 12L14 8.45l1.425-1.4l2.125 2.125l4.25-4.25l1.4 1.425zM9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m-8 8v-2.8q0-.85.438-1.562T2.6 14.55q1.55-.775 3.15-1.162T9 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T17 17.2V20z"/>
                                        </svg>
                                        <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>Lifegroup Active :</div>
                                        <div className="is-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>{LGActiveThisDay} Lifegroup</div>
                                            <div className={`is-size-5 mb-1 mt-1 has-text-weight-semibold ${LGActiveThisDay - LGActiveBeforeDay < 0 ? 'has-text-danger' : LGActiveThisDay - LGActiveBeforeDay === 0 ? '' : 'has-text-success'}`}>
                                                {LGActiveThisDay - LGActiveBeforeDay < 0 ? "-" : LGActiveThisDay - LGActiveBeforeDay === 0 ? "" : "+"}{Math.abs(LGActiveThisDay - LGActiveBeforeDay)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Active in LG */}
                            <div className="column is-one-third">
                                <div className="card" style={{ margin: '20px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: '10px', height: 'auto' }}>
                                    <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17.55 12L14 8.45l1.425-1.4l2.125 2.125l4.25-4.25l1.4 1.425zM9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m-8 8v-2.8q0-.85.438-1.562T2.6 14.55q1.55-.775 3.15-1.162T9 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T17 17.2V20z"/>
                                        </svg>
                                        <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>Active In LG :</div>
                                        <div className="is-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>{LGACtiveThisDay} Person</div>
                                            <div className={`is-size-5 mb-1 mt-1 has-text-weight-semibold ${LGACtiveThisDay - LGACtiveBeforeDay < 0 ? 'has-text-danger' : LGACtiveThisDay - LGACtiveBeforeDay === 0 ? '' : 'has-text-success'}`}>
                                                {LGACtiveThisDay - LGACtiveBeforeDay < 0 ? "-" : LGACtiveThisDay - LGACtiveBeforeDay === 0 ? "" : "+"}{Math.abs(LGACtiveThisDay - LGACtiveBeforeDay)}
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            {/* New LG Members */}
                            <div className="column is-one-third">
                                <div className="card" style={{ margin: '20px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: '10px', height: 'auto' }}>
                                        <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17.55 12L14 8.45l1.425-1.4l2.125 2.125l4.25-4.25l1.4 1.425zM9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m-8 8v-2.8q0-.85.438-1.562T2.6 14.55q1.55-.775 3.15-1.162T9 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T17 17.2V20z"/>
                                        </svg>
                                        <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>New LG Members :</div>
                                        <div className="is-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='is-size-6 mb-1 mt-1 has-text-weight-semibold'>{LGMemberThisDay} Person</div>
                                            <div className={`is-size-5 mb-1 mt-1 has-text-weight-semibold ${LGMemberThisDay - LGMemberBeforeDay < 0 ? 'has-text-danger' : LGMemberThisDay - LGMemberBeforeDay === 0 ? '' : 'has-text-success'}`}>
                                                {LGMemberThisDay - LGMemberBeforeDay < 0 ? "-" : LGMemberThisDay - LGMemberBeforeDay === 0 ? "" : "+"}{Math.abs(LGMemberThisDay - LGMemberBeforeDay)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="columns">
                    {/* COunt LG Leader */}
                    <div className="column">
                        <div className="card">
                            <div className='is-size-5 mb-4 has-text-weight-bold'>Count Lifegroup Leaders </div>
                            <div className="buttons">
                                <button className={`button ${range === '1W' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGLeader('1W')}>1W</button>
                                <button className={`button ${range === '1M' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGLeader('1M')}>1M</button>
                                <button className={`button ${range === '1Y' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGLeader('1Y')}>1Y</button>
                                {/* Tambahkan tombol-tombol lainnya sesuai dengan kebutuhan */}
                            </div>
                            <ChartComponent dataX={dateLGLeaderByDate} dataY={countLGLeaderByDate} namaChartX={"LG Leader"} namaChartY={"Date"} /> {/* Render ChartComponent */}
                        </div> 
                    </div>
                    {/* LG Activity */}
                    <div className="column">
                        <div className="card">
                            <div className='is-size-5 mb-4 has-text-weight-bold'>LG Activity</div>
                            <div className="buttons">
                                <button className={`button ${range === '1W' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGActivity('1W')}>1W</button>
                                <button className={`button ${range === '1M' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGActivity('1M')}>1M</button>
                                <button className={`button ${range === '1Y' ? 'is-primary' : ''}`} onClick={() => handleRangeChangeLGActivity('1Y')}>1Y</button>
                                {/* Tambahkan tombol-tombol lainnya sesuai dengan kebutuhan */}
                            </div>
                            <ChartComponent dataX={dateLGACtivityByDate} dataY={countLGActivityByDate} namaChartY={"LG Activity"} namaChartX={"Date"} /> {/* Render ChartComponent */}
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
