import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../style/UserAddStyle.css';
import '../style/CheckboxStyle.css';
import ConfirmationModal from '../modals/ConfirmationModal';



const AddLifegroup = () => {
    const [lifegroupName, setLifegroupName] = useState("");
    const [lifegroupLeader, setLifegroupLeader] = useState("");
    const [lifegroupCategory, setLifegroupCategory] = useState("");
    const [optionPerson, setOptionPerson] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

     // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchPersonOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
                console.log(response);
                const options = response.data.map((dj) => ({
                    value: dj.personId,
                    label: [
                        dj.namaDepan || '',
                        dj.namaTengah || '',
                        dj.namaBelakang || ''
                    ].filter(Boolean).join(' ').trim()
                }));
                setOptionPerson(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
        };

        fetchPersonOptions();
    }, []);

    const handlePersonChange = (selectedOption) => {
        setSelectedPerson(selectedOption);
        setLifegroupLeader(selectedOption.value);
        setErrors((prevErrors) => ({ ...prevErrors, lifegroupLeader: "" }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!lifegroupName) {
            newErrors.lifegroupName = "Lifegroup name is required";
        } else if (lifegroupName.length < 3 || lifegroupName.length > 100) {
            newErrors.lifegroupName = "Lifegroup name must be between 3 and 100 characters";
        } else if (/[^a-zA-Z0-9 ]/.test(lifegroupName)) {
            newErrors.lifegroupName = "Lifegroup name can only contain letters and numbers";
        }

        if (lifegroupCategories.length === 0) {
            newErrors.lifegroupCategory = "Please select at least one lifegroup category";
        }

        if (!lifegroupLeader) {
            newErrors.lifegroupLeader = "Please select a lifegroup leader";
        }
        console.log(newErrors)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveUser = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setIsModalOpen(true);
    };

    // const saveUser = async (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) return;

    //     try {
    //         const userData = {
    //             lifegroupName,
    //             lifegroupLeader,
    //             lifegroupCategory: getSelectedCategoriesString(),
    //         };

    //         const filteredUserData = Object.fromEntries(
    //             Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    //         );

    //         const response = await axios.post('${process.env.REACT_APP_API_URL}/insertLifeGroup', filteredUserData);
    //         const lifegroupId = response.data.lifegroupId;

    //         const LeaderData = {
    //             lifegroupId,
    //             personId: lifegroupLeader,
    //             status: "active",
    //         };

    //         const filteredLeaderData = Object.fromEntries(
    //             Object.entries(LeaderData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    //         );

    //         await axios.post('${process.env.REACT_APP_API_URL}/insertLifeGroupMember', filteredLeaderData);
    //         navigate("/");
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const confirmSaveUser = async () => {
        try {
            const userData = {
                lifegroupName,
                lifegroupLeader,
                lifegroupCategory: getSelectedCategoriesString(),
                status: 'active'
            };

            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertLifeGroup`, filteredUserData);
            const lifegroupId = response.data.lifegroupId;

            const LeaderData = {
                lifegroupId,
                personId: lifegroupLeader,
                status: "active",
            };

            const filteredLeaderData = Object.fromEntries(
                Object.entries(LeaderData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );

            await axios.post(`${process.env.REACT_APP_API_URL}/insertLifeGroupMember`, filteredLeaderData);
            navigate("/");
        } catch (error) {
            console.log(error);
        } finally {
            setIsModalOpen(false);
        }
    };

    const [lifegroupCategories, setLifegroupCategories] = useState([]);

    const handleCheckboxChange = (category) => {
        if (lifegroupCategories.includes(category)) {
            setLifegroupCategories(lifegroupCategories.filter(item => item !== category));
        } else {
            setLifegroupCategories([...lifegroupCategories, category]);
        }
        setErrors((prevErrors) => ({ ...prevErrors, lifegroupCategory: "" }));
    };

    const getSelectedCategoriesString = () => {
        return lifegroupCategories.join(', ');
    };

    const handleNameChange = (e) => {
        setLifegroupName(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, lifegroupName: "" }));
    };

    return (
        <div className="columns is-mobile mr-2">
            <ConfirmationModal 
                isOpen={isModalOpen} 
                onConfirm={confirmSaveUser} 
                onClose={() => setIsModalOpen(false)} 
            />
            <div className="column">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Life Group Information</div>
                <hr/>
                <form onSubmit={handleSaveUser} action="">
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                        </div>
                        <div className="column">
                            <label className='control'>
                                <input
                                    type="text"
                                    className='input'
                                    value={lifegroupName}
                                    onChange={handleNameChange}
                                    placeholder='Lifegroup Name'
                                />
                                {errors.lifegroupName && <p className="help is-danger">{errors.lifegroupName}</p>}
                            </label>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Category</div>
                        </div>
                        <div className="column">
                            <div className="control mb-2">
                                {["SMP", "SMA", "UNI", "Young Pro", "Family", "Senior"].map(category => (
                                    <label className="checkbox" key={category}>
                                        <input
                                            type="checkbox"
                                            name="lifegroupCategory"
                                            value={category}
                                            checked={lifegroupCategories.includes(category)}
                                            onChange={() => handleCheckboxChange(category)}
                                        />
                                        <span className="checkbox-text">{category}</span>
                                    </label>
                                ))}
                                {errors.lifegroupCategory && <p className="help is-danger">{errors.lifegroupCategory}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div className='is-size-5 mb-2 has-text-weight-semibold'>Leader</div>
                        </div>
                        <div className="column">
                            <Select
                                value={selectedPerson}
                                onChange={handlePersonChange}
                                options={optionPerson}
                            />
                            {errors.lifegroupLeader && <p className="help is-danger">{errors.lifegroupLeader}</p>}
                        </div>
                    </div>
                    <div className="is-flex is-justify-content-flex-end mt-4">
                        <button className='button' style={{
                            backgroundColor: '#0052CC',
                            color: 'white',
                            padding: '10px 24px',
                            fontWeight: 'bold',
                            borderRadius: '8px'
                        }}>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddLifegroup;
