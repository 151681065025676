import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams ,Link } from 'react-router-dom';
import Select from 'react-select'

const DiscipleEventUpdate = () => {
    const [image, setImage] = useState(null)
    const [imageName, setName] = useState("");
    const [namaEvent,setNamaEvent] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [eventTimeIn, setEventTimeIn] = useState("");
    const [eventTimeOut, setEventTimeOut] = useState("");
    const [eventCapacity, setEventCapacity] = useState("");
    const [eventSetup, seteventSetup] = useState("");
    const [eventSetup2, setEventSetup2] = useState("");
    const [eventAttendanceTotal, seteventAttendanceTotal] = useState("");
    const [eventLokasi, seteventLokasi] = useState("");
    const [eventType, seteventType] = useState("");
    const[Status,setStatus] = useState("");


    // atribut khusus Discipleship event 
    const [seriesTitle, setseriesTitle] = useState("");
    const [fasilitator,setfasilitator] =useState("");
    
    // atribut khusus Discipleship event 
    const[discipleshipJourneyType,setDiscipleshipJourneyType] = useState("");

    // atribut khusus Common event 
    const[organizer,setOrganizer] = useState("");

    // atribut khusus Service 
    // Ada series title
    const[sermonTitle,setSermonTitle] = useState("");
    const[preacher,setPreacher] = useState("");

    // Atribut Khusus Activity LG
    const[lifegroupId,setLifegroupId] = useState("");
    // series title
    // fasilitator

    const [Session, setSession] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    
    // Function to format date to ISO string (YYYY-MM-DD)
    const formatDateToISO = (dateString) => {
        const dateObj = new Date(dateString);
        return dateObj.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
    };


    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(()=>{
        getUserById();
    }, [id]);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            console.log(response)
            seteventType(response.data.eventType);
            setEventName(response.data.eventName);
            setEventDate(response.data.eventDate);
            setEventTimeIn(response.data.eventTimeIn);
            setEventTimeOut(response.data.eventTimeOut);
            setEventCapacity(response.data.eventCapacity);
            setEventSetup2(response.data.eventSetup);
            seteventAttendanceTotal(response.data.eventAttendanceTotal);
            seteventLokasi(response.data.eventLokasi);
            setseriesTitle(response.data.seriesTitle);
            setfasilitator(response.data.fasilitator);
            setOrganizer(response.data.organizer);
            setSermonTitle(response.data.sermonTitle);
            setPreacher(response.data.preacher);
            setDiscipleshipJourneyType(response.data.discipleshipJourneyType);
            setStatus(response.data.status);
            setLifegroupId(response.data.lifegroupId);
    };

    const handleFormSubmit = async(e) => {
        e.preventDefault();
    
        const formData = new FormData();
        eventName && formData.append('eventName', eventName);
        eventDate && formData.append('eventDate', eventDate);
        eventTimeIn && formData.append('eventTimeIn', eventTimeIn);
        eventTimeOut && formData.append('eventTimeOut', eventTimeOut);
        eventSetup2 && formData.append('eventSetup', eventSetup2);
        eventAttendanceTotal && formData.append('eventAttendanceTotal', eventAttendanceTotal);
        eventCapacity && formData.append('eventCapacity', eventCapacity);
        eventLokasi && formData.append('eventLokasi', eventLokasi);
        seriesTitle && formData.append('seriesTitle', seriesTitle);
        fasilitator && formData.append('fasilitator', fasilitator);
        organizer && formData.append('organizer', organizer);
        sermonTitle && formData.append('sermonTitle', sermonTitle);
        preacher && formData.append('preacher', preacher);
        lifegroupId && formData.append('lifegroupId', lifegroupId);
        eventType && formData.append('eventType', eventType);
        formData.append('discipleshipJourneyTypeId', discipleshipJourneyType);
        formData.append('status', Status);
        console.log(eventSetup2)

        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateEvent/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (eventType === 'Service' || eventType === 'CommonEvent') {
                navigate("/EventList");
            } else {
                navigate("/");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleImageChange = (e) => {
        // Mengambil file gambar dari input
        const file = e.target.files[0];
        setImage(file);
    };

        // select discipleship jouirney type
        // Untuk Sugest Discipleship Journey
        const [optionDJ, setOptionDJ] = useState([]);
        const [selectedDJ, setSelectedDJ] = useState(null);
        useEffect(() => {
            const fetchDJOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType`);
                const options = response.data.map((dj) => ({
                value: dj.discipleshipJourneyTypeId,
                label: dj.discipleshipJourneyTypeName,
                }));
                console.log(options)
                setOptionDJ(options);
            } catch (error) {
                console.error('Error fetching discipleship journey types:', error);
            }
            };

            fetchDJOptions();
        }, []);
        // Handle select discipleship journey type
        const handleDJChange = (selectedOption) => {
            setSelectedDJ(selectedOption);
            console.log('Selected DJ:', selectedOption);
            setDiscipleshipJourneyType(selectedOption.value);
            console.log(discipleshipJourneyType)
        };


return (
<div className="column">
    <div className='is-size-4 mb-3 has-text-weight-bold'>Detail Event</div>
    <hr />
    <div className='is-size-4 mb-4 has-text-weight-semibold'>Event Information</div>
    <hr />
    <form onSubmit={(e) => { handleFormSubmit(e); }}>
        {/* Event Name */}
        <label className={`control ${eventType == "Activity Lifegroup" ? 'hidden' : ''}`}>
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Name</div>
                </div>
                <div className="column is-3">
                        <label className='control mb-2'>
                            <input
                                type="text"
                                className='input'
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                            />
                        </label>
                </div>
            </div>
        </label>
        {/* Event Type */}
        <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-semibold'>Event Type</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <div className="select is-fullwidth">
                            <select
                                value={eventType}
                                onChange={(e) => seteventType(e.target.value)}
                            >
                                <option value="Common Event">Common Event</option>
                                <option value="Discipleship Journey">Discipleship Journey</option>
                                <option value="Service">Service</option>
                                <option value="Activity Lifegroup">Activity Lifegroup</option>
                            </select>
                        </div>
                    </label>
                </div>
            </div>
        {/* Event Date */}
       {/* Event Date */}
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-semibold'>Event Date</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="date"
                            className='input'
                            value={eventDate ? formatDateToISO(eventDate) : ''}
                            onChange={(e) => setEventDate(e.target.value)}
                        />
                    </label>
                </div>
            </div>
        {/* Event Location */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Location</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={eventLokasi}
                        onChange={(e) => seteventLokasi(e.target.value)}
                    />
                </label>
            </div>
        </div>
        {/* Time in and out */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time In</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="time"
                        className='input'
                        value={eventTimeIn}
                        onChange={(e) => setEventTimeIn(e.target.value)}
                    />
                </label>
            </div>
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Time Out</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="time"
                        className='input'
                        value={eventTimeOut}
                        onChange={(e) => setEventTimeOut(e.target.value)}
                    />
                </label>
            </div>
        </div>
        <label className={`control ${eventType == "Activity Lifegroup" ? 'hidden' : ''}`}>
    <div className='is-size-4 mb-4 has-text-weight-semibold'>Informasi Kapasitas, Setup, dan Kehadiran</div>
    <hr />
    {/* Kapasitas */}
    <div className="columns">
        <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Capacity</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="number"
                    className='input'
                    value={eventCapacity}
                    onChange={(e) => setEventCapacity(e.target.value)}
                />
            </label>
        </div>
    </div>
    {/* Event Setup */}
    <div className="columns">
        <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Setup Event</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <div className="select is-fullwidth">
                    <select
                        value={eventSetup2}
                        onChange={(e) => setEventSetup2(e.target.value)}
                    >
                        <option value="setup 1">setup 1</option>
                        <option value="setup 2">setup 2</option>
                        <option value="setup 3">setup 3</option>
                        <option value="setup 4">setup 4</option>    
                    </select>
                </div>
            </label>
        </div>
    </div>
</label>
        <div className='is-size-4 mb-4 has-text-weight-semibold'>Detail {eventType}</div>
        <hr />
        {/* Common Event */}
        {/* Organizer */}
        <label className={`control ${eventType != "Common Event" ? 'hidden' : ''}`}>
            <div className="columns">
                <div className="column is-2">
                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Organizer</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={organizer}
                            onChange={(e) => setOrganizer(e.target.value)}
                        />
                    </label>
                </div>
            </div>
        </label>
        {/* Service */}
        <label className={`control ${eventType != "Service" ? 'hidden' : ''}`}>
            {/* Series Title */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={seriesTitle}
                            onChange={(e) => setseriesTitle(e.target.value)}                    />
                    </label>
                </div>
            </div>
            {/* Sermon Title */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Sermon Title</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={sermonTitle}
                            onChange={(e) => setSermonTitle(e.target.value)}                    />
                    </label>
                </div>
            </div>
            {/* Preacher */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Preacher</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={preacher}
                            onChange={(e) => setPreacher(e.target.value)}
                        />
                    </label>
                </div>
            </div>
        </label>
        {/* Activity LG */}
        <label className={`control ${eventType != "Activity Lifegroup" ? 'hidden' : ''}`}>
            {/* LG Name */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Lifegroup Name</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={lifegroupId} //ganti value ke LG
                            onChange={(e) => setLifegroupId(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            {/* Series Title */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={seriesTitle} //ganti value ke LG
                            onChange={(e) => setseriesTitle(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            {/* Fasilitator */}
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Fasilitator</div>
                </div>
                <div className="column is-3">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={fasilitator} //ganti value ke LG
                            onChange={(e) => setfasilitator(e.target.value)}
                        />
                    </label>
                </div>
            </div>
        </label>
        {/* DiscJourney */}
        {/* Discipleship Journey */}
            <label className={`control ${eventType != "Discipleship Journey" ? 'hidden' : ''}`}>
                <div className="columns">
                    <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Discipleship Journey Type </div>
                    </div>
                    <div className="column is-3">
                        <div div className="field">
                        <div className="control">
                            <Select 
                            options={optionDJ}
                            value={selectedDJ}
                            onChange={handleDJChange}
                            />
                        </div>
                    </div>
                        </div>
                </div>
            </label>
        <button className='button is-success'>Save</button>
    </form>
</div>
)
}
export default DiscipleEventUpdate