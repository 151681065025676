import React, { useState } from "react";
import '../style/ManagementStyle.css';
import TagList from "../../src/components/TagList";
import MessageList from "../../src/components/MessageList.js";
import ListUser from "../../src/components/UserList.js";



const Management = () => {
    const [activeComponent, setActiveComponent] = useState(null);

    // const renderComponent = (component) => {
    //     setActiveComponent(component);
    // };

    const [activeCard, setActiveCard] = useState(null);

    const handleCardClick = (component, card) => {
        setActiveComponent(component);
        setActiveCard(card);
    };

    return (
        <div className="columns">
            <div className="column">
                <div className='is-size-4 mb-4 ml-1 has-text-weight-light'> Menu </div>
                <div className="columns">
                    <div className="column is-3">
                        <div
                            className={`card custom-card ${activeCard === 'Users' ? 'active' : ''}`}
                            onClick={() => handleCardClick('ListUser', 'Users')}
                        >
                            <div className="columns is-vcentered">
                                <div className="column is-narrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={`svg-icon is-size-1 ${activeCard === 'Users' ? 'active-icon' : ''}`}>
                                        <path fill={activeCard === 'Users' ? 'white' : '#005EB8'} d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                                    </svg>
                                </div>
                                <div className="column">
                                    <div className={`is-size-6 has-text-weight-semibold ${activeCard === 'Users' ? 'has-text-white' : 'has-text-custom-blue'}`}>
                                        Users
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-3">
                        <div
                            className={`card custom-card ${activeCard === 'Messages' ? 'active' : ''}`}
                            onClick={() => handleCardClick('MessagesList', 'Messages')}
                        >
                            <div className="columns is-vcentered">
                                <div className="column is-narrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={`svg-icon is-size-1 ${activeCard === 'Messages' ? 'active-icon' : ''}`}>
                                        <path fill={activeCard === 'Messages' ? 'white' : '#005EB8'} fillRule="evenodd" d="M3.879 5.879C3 6.757 3 8.172 3 11v2c0 2.828 0 4.243.879 5.121C4.757 19 6.172 19 9 19h6c2.828 0 4.243 0 5.121-.879C21 17.243 21 15.828 21 13v-2c0-2.828 0-4.243-.879-5.121C19.243 5 17.828 5 15 5H9c-2.828 0-4.243 0-5.121.879m2.676 2.289a1 1 0 0 0-1.11 1.664l5.446 3.63a2 2 0 0 0 2.218 0l5.446-3.63a1 1 0 0 0-1.11-1.664L12 11.798z" clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="column">
                                    <div className={`is-size-6 has-text-weight-semibold ${activeCard === 'Messages' ? 'has-text-white' : 'has-text-custom-blue'}`}>
                                        Messages
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-3">
                        <div
                            className={`card custom-card ${activeCard === 'TagList' ? 'active' : ''}`}
                            onClick={() => handleCardClick('TagList', 'TagList')}
                        > 
                            <div className="columns is-vcentered">
                                <div className="column is-narrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={`svg-icon is-size-1 ${activeCard === 'TagList' ? 'active-icon' : ''}`}>
                                        <path fill={activeCard === 'TagList' ? 'white' : '#005EB8'} d="M5.5 7A1.5 1.5 0 0 1 4 5.5A1.5 1.5 0 0 1 5.5 4A1.5 1.5 0 0 1 7 5.5A1.5 1.5 0 0 1 5.5 7m15.91 4.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.11 0-2 .89-2 2v7c0 .55.22 1.05.59 1.41l8.99 9c.37.36.87.59 1.42.59s1.05-.23 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.56-.23-1.06-.59-1.42"/>
                                    </svg>
                                </div>
                                <div className="column">
                                    <div className={`is-size-6 has-text-weight-semibold ${activeCard === 'TagList' ? 'has-text-white' : 'has-text-custom-blue'}`}>
                                        Tag
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Render komponen yang dipilih */}
                <div className="columns">
                    <div className="column">
                        {activeComponent === 'ListUser' && <ListUser />}
                        {activeComponent === 'MessagesList' && <MessageList />}
                        {activeComponent === 'TagList' && <TagList />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Management;
