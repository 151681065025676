import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import GmailModal from '../modals/GmailModal';

const GmailChooseReceiver = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleCheckboxChange = (email) => {
        setSelectedUsers((prevSelectedUsers) =>
            prevSelectedUsers.includes(email)
                ? prevSelectedUsers.filter((e) => e !== email)
                : [...prevSelectedUsers, email]
        );
    };

    const handleNextButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(false);
        navigate("/GmailSendMessage", { state: { selectedUsers } });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Check',
                accessor: 'check',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={selectedUsers.includes(row.original.email)}
                        onChange={() => handleCheckboxChange(row.original.email)}
                    />
                ),
            },
            {
                Header: 'Name',
                accessor: 'namaDepan',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
        ],
        [selectedUsers]
    );

    const data = useMemo(() => users, [users]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    return (
        <div>
            <div className="is-size-4 mb-3 has-text-weight-bold">Pilih User</div>
            <div className="field is-flex is-justify-content-space-between is-align-items-center">
                <div className="control" style={{ marginRight: '10px' }}>
                    <div className="select">
                        <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                            {[10, 25, 50, 100].map(size => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <label className="label">entries per page</label>
                <div className="control" style={{ marginLeft: 'auto' }}>
                    <input
                        className="custom-input"
                        type="text"
                        value={globalFilter || ''}
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder="Search"
                    />
                </div>
            </div>

            <table {...getTableProps()} className="table is-striped is-fullwidth mt-5">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: '1', textAlign: 'left' }}>
                    <span>
                        <strong>
                            Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                        </strong>
                    </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                    <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    <span style={{ margin: '0 10px' }}>
                        <button className='custom-button'>
                            {pageIndex + 1}
                        </button>
                    </span>
                    <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                </div>
                <div style={{ flex: '1', textAlign: 'right' }}>
                    <span>
                        <strong>
                            Page: {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                </div>
            </div>

            <div className="is-flex is-justify-content-flex-end mt-4">
                <button
                    className="button"
                    style={{
                        backgroundColor: '#005EB8',
                        borderRadius: '0.5rem',
                        padding: '1rem 3rem',
                        color: '#FFFFFF',
                    }}
                    onClick={handleNextButtonClick}
                >
                    Next
                </button>
            </div>

            {isModalOpen && (
                <GmailModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    updatedData={{
                        subject: 'Sending Message to Selected Users',
                        message: `You are about to send a message to ${selectedUsers.length} users.`,
                    }}
                    selectedUsers={selectedUsers} // Pass selected users to modal
                />
            )}
        </div>
    );
};

export default GmailChooseReceiver;
