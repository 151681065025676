import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ConfirmationModal from '../modals/ConfirmationModal';



const ActivityLifegroupAddGeneral = () => {
  const { id } = useParams();
  const [eventDate, setEventDate] = useState("");
  const [eventTimeIn, setEventTimeIn] = useState("");
  const [eventTimeOut, setEventTimeOut] = useState("");
  const [eventCapacity, setEventCapacity] = useState("");
  const [eventSetup, setEventSetup] = useState("");
  const [eventAttendanceTotal, setEventAttendanceTotal] = useState("");
  const [eventLokasi, setEventLokasi] = useState("");

  // atribut khusus Service
  const [sermonTitle, setSermonTitle] = useState("");
  const [preacher, setPreacher] = useState("");

  // Atribut Khusus Activity LG
  const [lifegroupId, setLifegroupId] = useState("");
  const [seriesTitle, setSeriesTitle] = useState("");
  const [fasilitator, setFasilitator] = useState("");

  // State untuk pesan error
  const [errors, setErrors] = useState({});

  // Untuk Sugest Discipleship Journey
  const [optionLG, setOptionLG] = useState([]);
  const [selectedLG, setSelectedLG] = useState(null);
  const [duplicateNames, setDuplicateNames] = useState(new Map());

  // Fasilitator
  const [optionPerson, setOptionPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  
  // History Function
  const fetchHistory = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewActivityLGHistory/${id}`);
      console.log(response);
      setEventDate(response.data.eventDate);
      setEventTimeIn(response.data.eventTimeIn);
      setEventTimeOut(response.data.eventTimeOut);
      setEventLokasi(response.data.eventLokasi);
      setSeriesTitle(response.data.seriesTitle);
      setFasilitator(response.data.fasilitator);
    } catch (error) {
      console.error('Error fetching history:', error);
      setEventDate('');
      setEventTimeIn('');
      setEventTimeOut('');
      setEventLokasi('');
      setSeriesTitle('');
      setFasilitator('');
    }
  };

  // Insert Data
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    // Validasi Time Out harus lebih besar dari Time In
    if (eventTimeIn >= eventTimeOut) {
      alert('Time Out must be greater than Time In');
      return;
    }

    const formData = new FormData();
    eventDate && formData.append('eventDate', eventDate);
    eventTimeIn && formData.append('eventTimeIn', eventTimeIn);
    eventTimeOut && formData.append('eventTimeOut', eventTimeOut);
    eventSetup && formData.append('eventSetup', eventSetup);
    eventAttendanceTotal && formData.append('eventAttendanceTotal', eventAttendanceTotal);
    eventCapacity && formData.append('eventCapacity', eventCapacity);
    eventLokasi && formData.append('eventLokasi', eventLokasi);
    seriesTitle && formData.append('seriesTitle', seriesTitle);
    fasilitator && formData.append('fasilitator', fasilitator);
    sermonTitle && formData.append('sermonTitle', sermonTitle);
    preacher && formData.append('preacher', preacher);
    formData.append('lifegroupId', lifegroupId);
    formData.append('eventType', 'Activity Lifegroup');
    formData.append('status', 'active');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertEventImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Server response:', response.data);
      const eventId = response.data.Image.eventId;

      if (eventId) {
        // Menampilkan modal konfirmasi dengan Bulma
        const modal = document.createElement('div');
        modal.className = 'modal is-active';
        modal.innerHTML = `
          <div class="modal-background"></div>
          <div class="modal-content">
            <div class="box">
              <p>Data berhasil ditambahkan. Apakah Anda ingin mengisi presensi sekarang?</p>
              <div class="buttons">
                <button class="button is-success" id="presensiBtn">Presensi</button>
                <button class="button is-success" id="okBtn">Tidak</button>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(modal);

        // Fungsi untuk menutup modal
        const closeModal = () => {
          document.body.removeChild(modal);
        };

        // Event listener untuk tombol-tombol dalam modal
        document.getElementById('presensiBtn').addEventListener('click', () => {
          closeModal();
          navigate(`/ActivityLGAttendance/${eventId}`, { state: { lifegroupId: lifegroupId } });
        });

        document.getElementById('okBtn').addEventListener('click', () => {
          closeModal();
          navigate('/ActivityLGList');
        });

        // Menambahkan event listener untuk modal background
        modal.querySelector('.modal-background').addEventListener('click', closeModal);

      } else {
        console.error('Event ID is not present in the response.');
      }
    } catch (error) {
      if (error.response) {
        console.error('Server response error:', error);
      } else if (error.request) {
        console.error('Request error:', error.request);
      } else {
        console.error('Error uploading image:', error.message);
      }
    }
    finally {
      setIsModalOpen(false);
    }
  };

  // Validasi Form
  const validateForm = () => {
    const newErrors = {};

    // Validasi Time Out harus lebih besar dari Time In
    if (eventTimeIn >= eventTimeOut) {
      newErrors.eventTimeOut = 'Time Out must be greater than Time In';
    }

    if (!lifegroupId) {
      newErrors.lifegroupId = "Lifegroup ID is required";
    }

    if (!seriesTitle) {
      newErrors.seriesTitle = "Series Title is required";
    }

    if (!fasilitator) {
      newErrors.fasilitator = "Fasilitator is required";
    }

    // Validasi Event Date
    if (!eventDate) {
      newErrors.eventDate = "Event Date is required";
    }

    // Validasi Event Location
    if (!eventLokasi) {
      newErrors.eventLokasi = "Event Location is required";
    }

    // Validasi Time In
    if (!eventTimeIn) {
      newErrors.eventTimeIn = "Time In is required";
    }

    // Validasi Time Out
    if (!eventTimeOut) {
      newErrors.eventTimeOut = "Time Out is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Menghapus pesan error jika nilai field sudah valid
  useEffect(() => {
    
    if (errors.eventDate && eventDate.day && eventDate.month && eventDate.year) {
      const newErrors = { ...errors };
      delete newErrors.eventDate;
      setErrors(newErrors);
    }
    if (errors.eventLokasi && eventLokasi) {
      const newErrors = { ...errors };
      delete newErrors.eventLokasi;
      setErrors(newErrors);
    }
    if (errors.eventTimeIn && eventTimeIn) {
      const newErrors = { ...errors };
      delete newErrors.eventTimeIn;
      setErrors(newErrors);
    }
    if (errors.eventTimeOut && eventTimeOut) {
      const newErrors = { ...errors };
      delete newErrors.eventTimeOut;
      setErrors(newErrors);
    }
    if (errors.lifegroupId && lifegroupId) {
      const newErrors = { ...errors };
      delete newErrors.lifegroupId;
      setErrors(newErrors);
    }
    if (errors.seriesTitle && seriesTitle) {
      const newErrors = { ...errors };
      delete newErrors.seriesTitle;
      setErrors(newErrors);
    }
    if (errors.fasilitator && fasilitator) {
      const newErrors = { ...errors };
      delete newErrors.fasilitator;
      setErrors(newErrors);
    }
  }, [eventDate, eventLokasi, eventTimeIn, eventTimeOut, lifegroupId, seriesTitle, fasilitator]);

  // Fetch Lifegroup Options
  useEffect(() => {
    const fetchLGOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifegroupWithLeader`);
        console.log(response)
        
        const allOptions = response.data.map((lg) => {
          const firstName = lg.leaderNamaDepan || '';
          const middleName = lg.leaderNamaTengah || '';
          const lastName = lg.leaderNamaBelakang || '';
          const lifegroupName = lg.lifegroupName || '';

          return {
            value: lg.lifegroupId,
            label: `${firstName} ${middleName} ${lastName}`.trim(),
            lifegroupName: lifegroupName 
          };
        });

        // Create a map to detect duplicates
        const nameMap = new Map();
        allOptions.forEach(option => {
          const name = option.label;
          if (nameMap.has(name)) {
            nameMap.get(name).push(option);
          } else {
            nameMap.set(name, [option]);
          }
        });

        // Prepare options to include one instance of each duplicate name
        const uniqueOptions = [];
        nameMap.forEach((options, name) => {
          uniqueOptions.push(options[0]); // Add only one instance of the duplicate names
        });

        setOptionLG(uniqueOptions);
        setDuplicateNames(nameMap); // Store the map for later use
      } catch (error) {
        console.error('Error fetching lifegroups:', error);
      }
    };

    fetchLGOptions();
  }, []);

  const handleLGChange = (selectedOption) => {
    const duplicates = duplicateNames.get(selectedOption.label);

    if (duplicates && duplicates.length > 1) {
      showModal(duplicates);
    } else {
      selectLifegroup(selectedOption);
    }
  };

  const selectLifegroup = (selectedOption) => {
    fetchHistory(selectedOption.value);
    setSelectedLG(selectedOption);
    setLifegroupId(selectedOption.value);

    // Clear any existing errors related to lifegroupId
    if (errors.lifegroupId && selectedOption.value) {
      const newErrors = { ...errors };
      delete newErrors.lifegroupId;
      setErrors(newErrors);
    }

    // Fetch person options when a Lifegroup is selected
    fetchPersonOptions(selectedOption.value);
  };

  // Define a function to fetch person options
  const fetchPersonOptions = async (lifegroupId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberWithPersonDetails/${lifegroupId}`);
      const options = response.data.map((dj) => ({
        value: dj.lgMemberId,
        label: `${dj.namaDepan || ''} ${dj.PersonMiddleName || ''} ${dj.PersonLastName || ''}`.trim() || 'No Name Available',
        searchLabel: `${dj.namaDepan || ''} ${dj.PersonMiddleName || ''} ${dj.PersonLastName || ''}`.trim()
      }));
      setOptionPerson(options);
    } catch (error) {
      console.error('Error fetching persons:', error);
    }
  };

  // UseEffect to fetch person options when `id` changes
  useEffect(() => {
    if (id) {
      fetchPersonOptions(id);
    }
  }, [id]);


  const showModal = (duplicates) => {
    const modal = document.createElement('div');
    modal.className = 'modal is-active';
    modal.innerHTML = `
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <p>Multiple Lifegroups found with the same name. Please select the correct one:</p>
          <div class="buttons">
            ${duplicates.map((option, index) => `
              <button class="button is-success" id="duplicateOption${index}">
                ${option.label.split(' ')[0]} - ${option.lifegroupName}
              </button>
            `).join('')}
            <button class="button is-danger" id="closeModalBtn">Close</button>
          </div>
        </div>
      </div>
    `;
    document.body.appendChild(modal);

    duplicates.forEach((option, index) => {
      document.getElementById(`duplicateOption${index}`).addEventListener('click', () => {
        selectLifegroup(option);
        closeModal();
      });
    });

    document.getElementById('closeModalBtn').addEventListener('click', () => {
      closeModal();
    });

    const closeModal = () => {
      document.body.removeChild(modal);
    };
  };

  const handlePersonChange = (newValue, actionMeta) => {
    console.log('Action Meta:', actionMeta); // Tambahkan ini untuk debugging
    if (actionMeta.action === 'create-option') {
      displayPersonModal(newValue.label);
    } else {
      setSelectedPerson(newValue);
      setFasilitator(newValue ? newValue.label : "");
      setErrors((prevErrors) => ({ ...prevErrors, fasilitator: "" }));
    }
  };

   // Function to display modal with search result
  const displayPersonModal = async (personName) => {
    const modal = document.createElement('div');
    modal.className = 'modal is-active';
    modal.innerHTML = `
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" id="modal-content">
          <p>Checking if "${personName}" exists...</p>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    `;
    document.body.appendChild(modal);

    const closeModal = () => {
      document.body.removeChild(modal);
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
      const people = response.data;

      const nameParts = personName.toLowerCase().split(' ');

      const matchedPeople = people.filter(person => {
        const fullNameParts = [
          person.namaDepan?.toLowerCase() || '',
          person.namaTengah?.toLowerCase() || '',
          person.namaBelakang?.toLowerCase() || ''
        ].filter(Boolean);

        return nameParts.some(part => fullNameParts.includes(part));
      });

      const modalContent = document.getElementById('modal-content');
      // Jika matchedPeople.length > 0
      if (matchedPeople.length > 0) {
        modalContent.innerHTML = `
          <div class="has-text-centered">
              <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
                  <g fill="#005EB8">
                      <path d="M12 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1m0 10a1 1 0 1 0 0 2a1 1 0 0 0 0-2"/>
                      <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2M4 12a8 8 0 1 0 16 0a8 8 0 0 0-16 0" clip-rule="evenodd"/>
                  </g>
              </svg>
              <p class="title is-4">Confirmation Person</p>
              <p class="title is-4" style="color: #191619;">Which Person</p>
          </div>
          <div id="person-checkboxes" class="box">
              ${matchedPeople.map(person => `
                  <div>
                      <input type="radio" name="person" id="person-${person.personId}" value="${person.personId}">
                      <label for="person-${person.personId}">${person.namaDepan || ''} ${person.namaTengah || ''} ${person.namaBelakang || ''}</label>
                  </div>
              `).join('')}
              <div>
                  <input type="radio" name="person" id="add-new-entry" value="new">
                  <label for="add-new-entry">Add ${personName} to entry</label>
              </div>
          </div>
          <div class="is-flex is-justify-content-flex-end pt-2">
              <button class="button" id="saveBtn" style="background-color: #005EB8; color: white;">Save</button>
          </div>
        `;

        document.getElementById('saveBtn')?.addEventListener('click', () => {
            const selectedCheckboxes = document.querySelectorAll('#person-checkboxes input:checked');
            const selectedIds = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
            const isAddNewEntryChecked = document.getElementById('add-new-entry').checked;
            
            console.log(`selectedIds: ${selectedIds}`);
            console.log(`isAddNewEntryChecked: ${isAddNewEntryChecked}`);
            
            if (selectedIds.length > 0) {
                console.log('Selected IDs found');
                console.log(isAddNewEntryChecked)
                if (isAddNewEntryChecked == false) {
                    setSelectedPerson({ value: selectedPerson.personId, label: `${selectedPerson.namaDepan || ''} ${selectedPerson.namaTengah || ''} ${selectedPerson.namaBelakang || ''}` });
                    setFasilitator(selectedPerson.personId); // Set fasilitator to personId
                }
                else  {
                console.log('Add New Entry selected');
                setSelectedPerson({ value: personName, label: personName });
                setFasilitator(personName);
                }
            }  else {
                console.log('No selection made');
                setSelectedPerson({ value: '', label: '' });
                setFasilitator('');
            }

            closeModal();
        });

    }
      else {
          modalContent.innerHTML = `
            <div class="has-text-centered">
                <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="-2 -3 24 24">
                    <path fill="#FF0000" d="m12.8 1.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.2 3.2 0 0 1-1.743.516H3.298C1.477 18 0 16.47 0 14.581c0-.639.173-1.264.498-1.807L7.2 1.613C8.162.01 10.196-.481 11.743.517c.428.276.79.651 1.057 1.096m-2.22.839a1.077 1.077 0 0 0-1.514.365L2.365 13.98a1.17 1.17 0 0 0-.166.602c0 .63.492 1.14 1.1 1.14H16.7c.206 0 .407-.06.581-.172a1.164 1.164 0 0 0 .353-1.57L10.933 2.817a1.1 1.1 0 0 0-.352-.365zM10 14a1 1 0 1 1 0-2a1 1 0 0 1 0 2m0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1"/>
                </svg>
                <p class="title is-4">Confirmation Person</p>
                <p class="title is-4" style="color: #191619;">Are you sure you want to add this person?</p>
                <input class="input" type="text" value="${personName}" readonly style="width: 50%;">
            </div>
            <div class="is-flex is-justify-content-flex-end pt-2">
                <button class="button is-success" id="confirmAddBtn">Add</button>
            </div>
          `;

          document.getElementById('confirmAddBtn')?.addEventListener('click', () => {
              // Handle the add logic here, if needed
              setSelectedPerson({ value: personName, label: personName });
              setFasilitator(personName);
              closeModal();
          });
      }


      document.getElementById('cancelAddBtn')?.addEventListener('click', () => {
        closeModal();
      });

      document.querySelector('.modal-close').addEventListener('click', () => {
        closeModal();
      });

    } catch (error) {
      console.error('Error fetching person data:', error);
      const modalContent = document.getElementById('modal-content');
      modalContent.innerHTML = `
        <p>Error fetching data. Please try again later.</p>
        <button class="button" id="closeErrorBtn">Close</button>
      `;
      document.getElementById('closeErrorBtn').addEventListener('click', () => {
        closeModal();
      });
    }
  };

  const handleSaveUser = (e) => {
        console.log('masuk')
        e.preventDefault();
        if (!validateForm()) return;
        setIsModalOpen(true);
    };

  return (
    <div className='column'>
      <ConfirmationModal 
          isOpen={isModalOpen} 
          onConfirm={handleFormSubmit} 
          onClose={() => setIsModalOpen(false)} 
      />
      <form onSubmit={handleSaveUser}>
        {/* LG Name */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Life Group Name</div>
          </div>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <Select
                  value={selectedLG}
                  options={optionLG}
                  onChange={handleLGChange}
                  placeholder="Select Life Group Name"
                />
              </div>
            </div>
            {errors.lifegroupId && <p className='help is-danger'>{errors.lifegroupId}</p>}
          </div>
        </div>
        <div className='is-size-4 mb-2 has-text-weight-semibold'>Life Group Event Information</div>
        <hr />
        {/* Lokasi Event */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Event Location</div>
          </div>
          <div className="column is-9">
            <div className="field">
              <div className="control">
                <input
                  type="text"
                  className={`input ${errors.eventLokasi ? 'is-danger' : ''}`}
                  value={eventLokasi}
                  onChange={(e) => setEventLokasi(e.target.value)}
                  placeholder='Event Location'
                />
              </div>
              {errors.eventLokasi && <p className='help is-danger'>{errors.eventLokasi}</p>}
            </div>
          </div>
        </div> 
        <div className="columns">
          {/* Date */}
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Date</div>
          </div>
          <div className="column is-3">
            <input
              type="date"
              className={`input ${errors.eventDate ? 'is-danger' : ''}`}
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              placeholder='Date'
            />
          </div>
           {/* Time */}
          <div className="column is-1">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Time</div>
          </div>
          <div className="column is-2">
            <input
              type="time"
              className={`input ${errors.eventTimeIn ? 'is-danger' : ''}`}
              value={eventTimeIn}
              onChange={(e) => setEventTimeIn(e.target.value)}
            />
          </div>
          <div className="column is-1">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>To</div>
          </div>
          <div className="column is-2">
            <input
              type="time"
              className={`input ${errors.eventTimeOut ? 'is-danger' : ''}`}
              value={eventTimeOut}
              onChange={(e) => setEventTimeOut(e.target.value)}
            />
          </div>
        </div>       
        <div className='is-size-4 mb-4 has-text-weight-semibold'>Capacity, Setup, and Attendance Information</div>
        <hr />        
        {/* Series Title */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
          </div>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <input
                  type="text"
                  className={`input ${errors.seriesTitle ? 'is-danger' : ''}`}
                  value={seriesTitle}
                  onChange={(e) => setSeriesTitle(e.target.value)}
                  placeholder='Series Title'
                />
              </div>
              {errors.seriesTitle && <p className='help is-danger'>{errors.seriesTitle}</p>}
            </div>
          </div>
        </div>
        
        {/* Fasilitator */}
        <div className="columns">
          <div className="column is-2">
            <div className='is-size-5 mb-3 has-text-weight-semibold'>Fasilitator</div>
          </div>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <CreatableSelect
                  isClearable
                  options={optionPerson}
                  value={selectedPerson}
                  onChange={handlePersonChange}
                  placeholder='Select or Enter Fasilitator'
                />
              </div>
              {errors.fasilitator && <p className='help is-danger'>{errors.fasilitator}</p>}
            </div>
          </div>
        </div> 
        <div className="is-flex is-justify-content-flex-end mt-4">
          <button className='button' style={{
            backgroundColor: '#0052CC',
            color: 'white',
            padding: '10px 24px',
            fontWeight: 'bold',
            borderRadius: '8px'
          }}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActivityLifegroupAddGeneral;
