import React, { useEffect, useState } from 'react';
import { useNavigate,Link, useParams} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const MessageListDetailPerson = () => {
    const [users, setUsers] = useState([]);
    const [tag, settag] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUsers();
        getDetailTag()
    }, []);

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);


    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewMessagePersonJoin/${id}`);
            console.log(response)
            setUsers(response.data);
            console.log(users)
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const getDetailTag = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewMessageById/${id}`);
            console.log(response)
            settag(response.data);
            console.log(tag)
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTag/${id}`);
            navigate("/MessageListDetailPerson");
        } catch (error) {
            console.error(error);
        }
    };
    
    

    return (
        <div className="colum">
            <h1>Message Detail</h1>
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Subject Content</div>
                </div>
                <div className="column is-3">
                        <label className='control mb-2'>
                            <input
                                type="text"
                                className='input'
                                value={tag.Message || tag.Subject || ''}
                                readOnly={true} // Menjadikan input menjadi readonly
                            />
                        </label>
                </div>
            </div>
            <div className="columns">
                <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Body Content</div>
                </div>
                <div className="column is-3">
                        <label className='control mb-2'>
                            <input
                                type="text"
                                className='input'
                                value={tag.Message || tag.Body || ''}
                                readOnly={true} // Menjadikan input menjadi readonly
                            />
                        </label>
                </div>
            </div>
                <div className='is-size-4 mb-3 has-text-weight-bold'>List Person in Tag</div>
                <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Person Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.MessagePersonId}>
                                <td>
                                    <Link to={`/MessageDetail/${user.person_details.namaDepan}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                                    <button className="button is-danger is-small" onClick={() => deleteUser(user.tagId)}>Delete</button>
                                </td>
                                <td>{user.PenerimaId}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    );
};

export default MessageListDetailPerson;
