import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'


const SessionAdd = () => {

    // Untuk data yang ke DB
    
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    // Inputan
    
    const [SessionName, setSessionName] = useState("");
    const [SessionDate, setSessionDate] = useState("");
    
    // Untuk Sugest 
    const [suggested, setSuggested] = useState([]);
    let jemaatList = "";
    
    // Fetch list jemaat
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggested(response.data);
        console.log(response)
        jemaatList = response.data;
        console.log(jemaatList);
    };
    
    // Fetch Detail Event
    const getEvent = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
        setEventName(response.data.eventName);
        setEventId(response.data.eventId);
    };

    const saveSession = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                eventId,
                sessionName:SessionName,
                sessionDate:SessionDate,
            };
    
            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData);
            await axios.post(`${process.env.REACT_APP_API_URL}/insertSession`, filteredUserData);
            navigate(`/eventList/EventDetail/${id}`);
        } catch (error) {
            console.log(error);
        }
    };
  
    //Use Effect
    useEffect(()=> {
        getJemaat();
        getEvent();
    },[]);

    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Add Session</div>
            {/* Nama */}
            <div className='is-size-5 mb-2 has-text-weight-bold'>Nama Event</div>
            <div className="field">
                <label className='control'>
                    <input
                        type="text"
                        className='input'
                        value={eventName} 
                        readOnly={true} 
                        placeholder='Nama Event'
                    />
                </label>
            </div>
            <form onSubmit={saveSession}action=''>
            <div className=' is-size-6 mb-2 has-text-weight-bold'>Nama Session</div>
                <label className='control'>
                    <input type="text"className='input' value ={SessionName} 
                    onChange={(e)=> setSessionName(e.target.value)}
                    placeholder='Nama Session'
                    />
                </label>
            <div className="control">
             {/* Tanggal Event */}
                <div className=' is-size-6 mb-2 has-text-weight-bold'>Tanggal Event</div>
                    <label className='control'>
                        <input type="date"className='input' value ={SessionDate} 
                        onChange={(e)=> setSessionDate(e.target.value)}
                        placeholder='Tanggal Lahir'/>
                    </label>
                <button className='button is-success'>Save</button>
            </div>
            </form>
        </div>
    </div>
)
}
export default SessionAdd