import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useTable } from 'react-table';
import "../style/tableStyle.css";
import "../style/UserListStyle.css";
import { exportToCSV } from '../Utils/csvUtils';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateUserStatus = async (id, status) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updatePerson/${id}`, { status });
            getUsers();
        } catch (error) {
            console.error(error);
        }
    };

    const columns = useMemo(() => [
        {
            Header: 'Full Name',
            accessor: 'fullName',
            Cell: ({ row }) => {
                const namaDepan = row.original.namaDepan || '';
                const namaTengah = row.original.namaTengah || '';
                const namaBelakang = row.original.namaBelakang || '';
                
                return (
                    <Link to={`userDetails/${row.original.personId}`}>
                        {`${namaDepan} ${namaTengah} ${namaBelakang}`.trim()}
                    </Link>
                );
            },
        },
        {
            Header: 'Gender',
            accessor: 'gender',
        },
        {
            Header: 'Place, Date of Birth',
            accessor: 'birthInfo',
            Cell: ({ row }) => `${row.original.tempatLahir}, ${formatDate(row.original.tanggalLahir)}`,
        },
        {
            Header: 'Phone Number',
            accessor: 'nomorTelepon',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Life Phase',
            accessor: 'faseHidup',
        },
        {
            Header: 'Church Location',
            accessor: 'lokasiGereja',
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <Link to={`userDetails/${row.original.personId}`} className="button is-info is-small mb-2" style={{ marginRight: '10px' }}>
                        Details
                    </Link>
                    {row.original.status === 'active' ? (
                        <button className="button is-warning is-small mb-2" onClick={() => updateUserStatus(row.original.personId, 'deactive')}>
                            Deactivate
                        </button>
                    ) : (
                        <button className="button is-success is-small mb-2" onClick={() => updateUserStatus(row.original.personId, 'active')}>
                            Activate
                        </button>
                    )}
                </>
            ),
        },
    ], []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    const filteredUsers = useMemo(() => {
        return users.filter(user => {
            const namaDepan = user.namaDepan?.toLowerCase() || '';
            const namaTengah = user.namaTengah?.toLowerCase() || '';
            const namaBelakang = user.namaBelakang?.toLowerCase() || '';
            const email = user.email?.toLowerCase() || '';

            const query = searchQuery.toLowerCase();
            return namaDepan.includes(query) ||
                    namaTengah.includes(query) ||
                    namaBelakang.includes(query) ||
                    email.includes(query);
        });
    }, [users, searchQuery]);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: currentUsers });

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    // Pagination controls logic
    const previousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const canPreviousPage = currentPage > 1;
    const canNextPage = currentPage < totalPages;
    const pageIndex = currentPage - 1;

    return (
        <div className="columns">
            <div className="column">
                <div className="columns">
                    <div className="column is-6">
                        <div className="custom-button mr-2" onClick={() => navigate('/addJemaat')}>Add Jemaat</div>
                        <button className="custom-button mr-2" onClick={() => exportToCSV(users, 'User')}>Export to CSV</button>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                    placeholder="Search Name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={usersPerPage}
                                onChange={(e) => setUsersPerPage(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                <div className="table-container2">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Custom Pagination Controls */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {filteredUsers.length ? indexOfFirstUser + 1 : 0} to {filteredUsers.length ? indexOfLastUser : 0}
                            </strong>
                        </span>
                    </div> 
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={previousPage} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {currentPage}
                            </button>
                        </span>
                        <button className='button' onClick={nextPage} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {currentPage} of {totalPages}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;
