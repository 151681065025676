import React, { useEffect, useState } from 'react';
import { useNavigate,Link,useParams} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const MessageListDetail = () => {
    const [users, setUsers] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);


    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewMessagePersonByMessageId/${id}`);
            console.log(response)
            setUsers(response.data);
            console.log(users)
            setDataFetched(true); // Set flag to indicate data fetching is complete
        } catch (error) {
            console.error(error);
        }
    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deletePerson/${id}`);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };
    
    

    return (
        <div className="colum">
            <h1>Message Details2</h1>
            
                <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Message</th>
                            <th>Social Media</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.messageId}>
                                <td>
                                    <Link to={`DetailMessage/${user.messageId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                                    <button className="button is-danger is-small" onClick={() => deleteUser(user.personId)}>Delete</button>
                                </td>
                                <td>{user.Message}</td>
                                <td>{user.socialMedia}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            
        </div>
    );
};

export default MessageListDetail;
