
//  Format  07 July 2000

export const formatDate_DDMMMMYYYY = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('id-ID', options);
};



