import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'


const TagAdd = () => {

    // Untuk data yang ke DB
    
    const [eventName, setEventName] = useState("");
    const [eventMember, setEventMember] = useState("");
    const [eventId, setEventId] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    // Inputan
    
    const [SessionName, setSessionName] = useState("");
    
    const saveSession = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                tagName:SessionName,
                status:"active",
            };
    
            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData);
            await axios.post(`${process.env.REACT_APP_API_URL}/insertTag`, filteredUserData);
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    
return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Add Tag</div>
            <form onSubmit={saveSession}action=''>
            <div className=' is-size-6 mb-2 has-text-weight-bold'>Tag Name</div>
                <label className='control'>
                    <input type="text"className='input' value ={SessionName} 
                    onChange={(e)=> setSessionName(e.target.value)}
                    placeholder='Tag Name'
                    />
                </label>
            <div className="control">
                <button className='button is-success'>Save</button>
            </div>
            </form>
        </div>
    </div>
)
}
export default TagAdd