import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";

const JemaatFamily = () => {
    const [users, setUsers] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [Father, setFather] = useState({ personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' });
    const [Mother, setMother] = useState({ personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' });
    const [Siblings, setSiblings] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (dataFetched) {
            // Data fetching is complete
        }
    }, [dataFetched]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/personFamily/${id}`);
            setFather(response.data.father || { personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' });
            setMother(response.data.mother || { personId: '', namaDepan: '', namaTengah: '', namaBelakang: '' });
            setSiblings(response.data.siblings || []);
            setUsers(response.data);
            setDataFetched(true);
        } catch (error) {
            console.error(error);
            alert('Tidak ada data keluarga ditemukan.');
        }
    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deletePerson/${id}`);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    };

    const formatName = (person) => {
        return [person.namaDepan, person.namaTengah, person.namaBelakang].filter(Boolean).join(' ');
    };

    // Define columns
    const columns = React.useMemo(() => [
        {
            Header: 'Action',
            accessor: 'personId', // accessor is the "key" in the data
            Cell: ({ row }) => (
                <div>
                    <Link to={`/MessageDetailPerson/${row.original.MessageId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                    <button className="button is-danger is-small" onClick={() => deleteUser(row.original.personId)}>Delete</button>
                </div>
            ),
        },
        {
            Header: 'Name',
            accessor: 'personName', // Define a custom accessor
        },
        {
            Header: 'Family Relation',
            accessor: 'relation',
            Cell: () => 'Siblings',
        },
    ], []);

    // Prepare data
    const data = React.useMemo(() => Siblings.map(user => ({
        ...user,
        personName: formatName(user),
        relation: 'Siblings',
    })), [Siblings]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div className="colum">
            {/* Father */}
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-bold'>Father Name</div>
                </div>
                <div className="column is-9">
                    <div className='input' placeholder='Tempat'>
                        {formatName(Father)}
                    </div>
                </div>
            </div>
            {/* Mother */}
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-bold'>Mother Name</div>
                </div>
                <div className="column is-9">
                    <div className='input' placeholder='Tempat'>
                        {formatName(Mother)}
                    </div>
                </div>
            </div>
            <hr />
            {/* Siblings Table */}
            <table {...getTableProps()} className="table is-striped is-fullwidth mt-5">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default JemaatFamily;
