import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import '../style/UserAddStyle.css';

const LifegroupDetails = () => {
    const [lifegroupName, setLifegroupName] = useState("Loading...");
    const [lifegroupLeader, setLifegroupLeader] = useState("Loading...");
    const [lifegroupCategory, setLifegroupCategory] = useState("Loading...");
    const [lgMembers, setLgMembers] = useState([]);

    const [notification, setNotification] = useState(null);
    
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getLifegroupDetails();
        getLGMember();
    }, []);

    const getLifegroupDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${id}`);
            setLifegroupName(response.data.lifegroupName);
            setLifegroupCategory(response.data.lifegroupCategory);

            const responseLeader = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${response.data.lifegroupLeader}`);
            setLifegroupLeader(responseLeader.data.namaDepan);
        } catch (error) {
            console.log("Error fetching lifegroup details:", error.response);
            resetState();
        }
    };

    const getLGMember = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupMemberWithPersonDetails/${id}`);
            setLgMembers(response.data);
        } catch (error) {
            console.log("Error fetching lifegroup members:", error.response);
            resetState();
        }
    };

    const changeMemberStatus = async (memberId, currentStatus) => {
        try {
            const newStatus = currentStatus === 'active' ? 'deactivate' : 'active';
            const confirmChange = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
            
            if (confirmChange) {
                await axios.patch(`${process.env.REACT_APP_API_URL}/updateLifeGroupMember/${memberId}`, { status: newStatus });
                setNotification(`Lifegroup Member status changed to ${newStatus}`);
                getLGMember(); // Refresh the members list after updating status
            }
        } catch (error) {
            console.error(error);
        }
    };

    const resetState = () => {
        setLifegroupName("No data available");
        setLifegroupLeader("No data available");
        setLifegroupCategory("No data available");
        setLgMembers([]);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Full Name',
                accessor: row => `${row.namaDepan || ""} ${row.PersonMiddleName || ""} ${row.namaBelakang || ""}`, // accessor can be a function
            },
            {
                Header: 'Status',
                accessor: 'status', 
                Cell: ({ value }) => {
                    const status = value.toLowerCase();
                    const statusStyle = {
                        color: status === 'active' ? 'green' : status === 'deactivate' ? 'red' : 'black',
                        fontWeight: 'bold',
                        textTransform: 'capitalize' // Capitalizes the first letter of the status
                    };

                    return <span style={statusStyle}>{status}</span>;
                }
            },
            {
                Header: 'Action',
                accessor: 'lgMemberId',
                Cell: ({ value, row }) => (
                    <button
                        className="button"
                        style={{
                            backgroundColor: '#2D98FF',
                            color: 'white',
                            padding: '10px 24px',
                            borderRadius: '8px',
                        }}
                        onClick={() => changeMemberStatus(value, row.original.status)}
                    >
                        Change Status
                    </button>

                ),
            },
        ],
        []
    );

    const data = React.useMemo(() => lgMembers, [lgMembers]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        pageOptions
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useSortBy,
        usePagination
    );

    return (
        <div className="columns">
            {/* Lifegroup Profile */}
            <div className='column is-5 card mr-2 '>
                <div className='is-size-4 mb-5 has-text-weight-bold has-text-centered'>Lifegroup Profile</div>
                <hr />
                <div className='is-size-5 mb-5 has-text-weight-semibold has-text-centered'>Lifegroup Name</div>
                <label className='control' style={{ display: 'flex', justifyContent: 'center', margin: '0 2rem' }}>
                    <input
                        type="text"
                        className='input is-size-6'
                        value={lifegroupName}
                        readOnly={true}
                        placeholder='Lifegroup Name'
                        style={{
                            textAlign: 'center',
                            width: '100%', // Menjadikan field lebih besar dan dapat disesuaikan
                            maxWidth: '25rem', // Membatasi ukuran maksimum jika diperlukan
                        }}
                    />
                </label>
                <div className='is-size-5 mb-5 has-text-weight-semibold has-text-centered'>Lifegroup Leader</div>
                <label className='control' style={{ display: 'flex', justifyContent: 'center', margin: '0 2rem' }}>
                    <input
                        type="text"
                        className='input is-size-6'
                        value={lifegroupLeader}
                        readOnly={true}
                        placeholder='Lifegroup Leader Name'
                        style={{
                            textAlign: 'center',
                            width: '100%', // Makes the field larger and adaptable
                            maxWidth: '25rem', // Sets a max width if needed
                        }}
                    />
                </label>
                <div className='is-size-5 mb-5 has-text-weight-semibold has-text-centered'>Lifegroup Category</div>
                <label className='control' style={{ display: 'flex', justifyContent: 'center', margin: '0 2rem' }}>
                    <input
                        type="text"
                        className='input is-size-6'
                        value={lifegroupCategory}
                        readOnly={true}
                        placeholder='Lifegroup Category'
                        style={{
                            textAlign: 'center',
                            width: '100%', // Menjadikan field lebih besar dan dapat disesuaikan
                            maxWidth: '25rem', // Membatasi ukuran maksimum jika diperlukan
                        }}
                    />
                </label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link
                            to={`/editLifegroup/${id}`}
                            className="button"
                            style={{
                                backgroundColor: '#0052CC',
                                color: 'white',
                                padding: '10px 24px',
                                fontWeight: 'bold',
                                borderRadius: '8px',
                                marginRight: '10px'
                            }}
                        >
                            Edit Lifegroup
                        </Link>
                    </div>
            </div>
            {/* Lifegroup Member */}
            <div className='column is-7 card mr-6 pr-6'>
                <div className='is-size-4 mb-5 has-text-weight-bold has-text-centered'>Lifegroup Members</div>
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>

                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add sort indicator */}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link
                        to={`/addLifegroupMember/${id}`}
                        className="button"
                        style={{
                            backgroundColor: '#0052CC',
                            color: 'white',
                            padding: '10px 24px',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            marginRight: '10px',
                            marginTop: '1rem',
                            marginBottom: '1rem'
                        }}
                    >
                        Add LG Member
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LifegroupDetails;
