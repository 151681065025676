import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import '../style/UserAddStyle.css'


const UserUpdate = () => {

    // Untuk data yang ke DB
    
    const [namaDepan, setName] = useState("");
    const [namaTengah, setNamaTengah] = useState("");
    const [namaBelakang, setNamaBelakang] = useState("");
    const [namaLain, setNamaLain] = useState("");
    const [gender, setGender] = useState("");
    const [tanggalLahir, setTanggalLahir] = useState("");
    const [tempatLahir, setTempatLahir] = useState("");
    const [faseHidup, setFaseHidup] = useState("");
    const [statusPerkawinan, setStatusPerkawinan] = useState("");
    const [pasangan, setPasangan] = useState("");
    const [tanggalPerkawinan, setTanggalPerkawinan] = useState("");
    const [nomorTelepon, setNomorTelepon] = useState("");
    const [email, setEmail] = useState("");
    const [lokasiGereja, setLokasiGereja] = useState("");
    const [role, setRole] = useState("");
    const [ayah, setAyah] = useState("");
    const [ibu, setIbu] = useState("");
    const [kerinduan, setKerinduan] = useState("");
    const [komitmenBerjemaat, setKomitmenBerjemaat] = useState("");

    // Untuk Sugest 
    const [suggestedPasangan, setSuggestedPasangan] = useState([]);
    let jemaatList = "";

    const { id } = useParams();
    const navigate = useNavigate();
    
    //Use Effect
    useEffect(()=> {
        getJemaat();
        getDetailJemaat();
    },[]);

    // detail jemaat
    const getDetailJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
        setName(response.data.namaDepan);
        setNamaTengah(response.data.namaTengah);
        setNamaBelakang(response.data.namaBelakang);
        setNamaLain(response.data.namaLain);
        setGender(response.data.gender);
        setTanggalLahir(response.data.tanggalLahir);
        setTempatLahir(response.data.tempatLahir);
        setFaseHidup(response.data.faseHidup);
        setStatusPerkawinan(response.data.statusPerkawinan);
        setPasangan(response.data.pasangan);
        setTanggalPerkawinan(response.data.tanggalPerkawinan);
        setNomorTelepon(response.data.nomorTelepon);
        setEmail(response.data.email);
        setLokasiGereja(response.data.lokasiGereja);
        setKomitmenBerjemaat(response.data.komitmenBerjemaat);
        setAyah(response.data.ayah);
        setIbu(response.data.ibu);
    };
    // Fetch nama jemaat yang ada untuk suggested pasangan
    const getJemaat = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewPerson`);
        setSuggestedPasangan(response.data);
        jemaatList = response.data;
        // console.log(jemaatList);
    };

    
    const saveUser = async (e) => {
        e.preventDefault();
        try {

             // Mengubah format tanggalLahir menjadi string ISO (YYYY-MM-DD)
            const tanggalLahirISO = `${tanggalLahir.year}-${tanggalLahir.month}-${tanggalLahir.day}`;

            const userData = {
                namaDepan,
                namaTengah,
                namaBelakang,
                namaLain,
                gender,
                tempatLahir,
                tanggalLahir:tanggalLahirISO,
                faseHidup,
                statusPerkawinan,
                pasangan,
                tanggalPerkawinan,
                nomorTelepon,
                email,
                lokasiGereja,
                role:"jemaat",
                ayah,
                ibu,
                kerinduan,
                komitmenBerjemaat,
                dateAdded:new Date(),
            };
    
            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            
            await axios.post(`${process.env.REACT_APP_API_URL}/insertPerson`, filteredUserData);
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    const updateUser = async(e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/updatePerson/${id}`,{
                namaDepan,
                namaTengah,
                namaBelakang,
                namaLain,
                gender,
                tanggalLahir,
                tempatLahir,
                faseHidup,
                statusPerkawinan,
                pasangan,
                tanggalPerkawinan,
                nomorTelepon,
                email,
                lokasiGereja,
                role,
                komitmenBerjemaat
            });
            navigate("/")
        } catch (error) {
            console.log(error);
        }
    };
    
return (
    <div className="border">
        <div className="columns is-mobile mr-2">
            <div className="column ">
                <div className='is-size-4 mb-3 has-text-weight-bold'>Edit Jemaat</div>
                <hr />
                <div className='is-size-4 mb-3 has-text-weight-semibold'>Data Pribadi</div>
                <hr />
                <form onSubmit={saveUser}action=""> 
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama</div>
                        </div>
                        <div className="column is-9">
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaDepan}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder='Nama Depan' />
                                        </div>
                                    </div>
                                    {/* Nama belakang */}
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaBelakang}
                                                onChange={(e) => setNamaBelakang(e.target.value)}
                                                placeholder='Nama Belakang' />
                                        </div>
                                    </div>
                                     {/* Nama tengah */}
                                    <div className="field">
                                        <div className="control">
                                            <input type="text" className='input' value={namaTengah}
                                                onChange={(e) => setNamaTengah(e.target.value)}
                                                placeholder='Nama Tengah' />
                                        </div>
                                    </div>
                                    {/* Nama Lain */}
                                    <label className='control'>
                                        <input type="text"className='input' value ={namaLain} 
                                        onChange={(e)=> setNamaLain(e.target.value)}
                                        placeholder='Nama Lain' />
                                    </label>
                        </div>
                    </div>
                    {/* Kelamin */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Jenis Kelamin</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2 radio-container">
                                <label className="radio is-large">
                                    <input
                                    type="radio"
                                    value={gender === "Laki-laki" ? "" : "Laki-laki"}
                                    checked={gender === "Laki-laki"}
                                    onChange={(e) => setGender(e.target.value)}
                                    />
                                    <span className="radio-text">Laki-laki</span>
                                </label>
                                <label className="radio is-large">
                                    <input
                                    type="radio"
                                    value={gender === "Perempuan" ? "" : "Perempuan"}
                                    checked={gender === "Perempuan"}
                                    onChange={(e) => setGender(e.target.value)}
                                    />
                                    <span className="radio-text">Perempuan</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* tempat lahir */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Tempat Lahir</div>
                        </div>
                        <div className="column is-9">
                            <label className='control'>
                                <input type="text"className='input' value ={tempatLahir} 
                                onChange={(e)=> setTempatLahir(e.target.value)}
                                placeholder='Tempat Lahir'
                                />
                            </label>
                        </div>
                    </div>
                    {/* tanggal lahir */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Tanggal Lahir</div>
                        </div>
                        <div className="column is-9">
                            <label className='control'>
                                        <div className="field is-horizontal">
                                            <div className="field-body">
                                                {/* Field Hari */}
                                                <div className="field">
                                                    <div className="control">
                                                        {/* <input type="number" className='input' value={tanggalLahir.day}
                                                            onChange={(e) => setTanggalLahir({ ...tanggalLahir, day: e.target.value })}
                                                            placeholder='Day' /> */}
                                                    </div>
                                                </div>
                                                {/* Field Bulan */}
                                                <div className="field">
                                                    <div className="control">
                                                        {/* <input type="number" className='input' value={tanggalLahir.month}
                                                            onChange={(e) => setTanggalLahir({ ...tanggalLahir, month: e.target.value })}
                                                            placeholder='Month' /> */}
                                                    </div>
                                                </div>
                                                {/* Field Tahun */}
                                                <div className="field">
                                                    <div className="control">
                                                        {/* <input type="number" className='input' value={tanggalLahir.year}
                                                            onChange={(e) => setTanggalLahir({ ...tanggalLahir, year: e.target.value })}
                                                            placeholder='Year' /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </label>
                        </div>
                    </div>
                    {/* fase hidup */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Fase Hidup</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2 radio container">
                            <label className="radio is-large"
                            title=" 0 - 10 Tahun">
                                <input
                                type="radio"
                                value={faseHidup === "Anak-anak" ? "" : "Anak-anak"}
                                checked={faseHidup === "Anak-anak"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="radio-text">Anak-anak</span>
                            </label>
                            <label className="radio is-large"
                            title=" 10 - 12 Tahun">
                                <input
                                type="radio"
                                value={faseHidup === "Pra-remaja/Remaja" ? "" : "Pra-remaja/Remaja"}
                                checked={faseHidup === "Pra-remaja/Remaja"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="radio-text">PraRemaja/Remaja</span>
                            </label>
                            <label className="radio is-large"
                            title =" SMA dan Kuliah ">
                                <input
                                type="radio"
                                value={faseHidup === "Pemuda" ? "" : "Pemuda"}
                                checked={faseHidup === "Pemuda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="radio-text">Pemuda</span>
                            </label>
                            <label className="radio is-large"
                            title =" Profesional Muda">
                                <input
                                type="radio"
                                value={faseHidup === "Dewasa Muda" ? "" : "Dewasa Muda"}
                                checked={faseHidup === "Dewasa Muda"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="radio-text">Dewasa Muda</span>
                            </label>
                            <label className="radio is-large"
                            title =" Family Life">
                                <input
                                type="radio"
                                value={faseHidup === "Berkeluarga" ? "" : "Berkeluarga"}
                                checked={faseHidup === "Berkeluarga"}
                                onChange={(e) => setFaseHidup(e.target.value)}
                                />
                                <span className="radio-text">Berkeluarga</span>
                            </label>
                            {/* Decease untuk org  yang sudah meningggal , role == admin */}
                            </div>
                        </div>
                    </div>
                    {/* Status dan keluarga */}
                    <div className='is-size-4 mb-3 has-text-weight-semibold'>Status dan keluarga</div>
                    <hr />
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Status Perkawinan</div>
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2">
                                <label className="radio">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Belum Menikah" ? "" : "Belum Menikah"}
                                    checked={statusPerkawinan === "Belum Menikah"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="radio-text">Belum Menikah</span>
                                </label>
                                <label className="radio">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Menikah" ? "" : "Menikah"}
                                    checked={statusPerkawinan === "Menikah"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="radio-text">Menikah</span>
                                </label>
                                <label className="radio">
                                    <input
                                    type="radio"
                                    value={statusPerkawinan === "Janda/Duda" ? "" : "Janda/Duda"}
                                    checked={statusPerkawinan === "Janda/Duda"}
                                    onChange={(e) => setStatusPerkawinan(e.target.value)}
                                    />
                                    <span className="radio-text">Janda / duda </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <label className={`control ${statusPerkawinan != "Menikah" ? 'hidden' : ''}`}>
                                {/* tanggal perkawinan */}
                                <div className="columns">
                                    <div className="column is-2">
                                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Tanggal Perkawinan</div>
                                    </div>
                                    <div className="column is-9">
                                        {/* Tanggal Perkawinan */}
                                        <label className='control'>
                                            <input type="date"className='input' value ={tanggalPerkawinan} 
                                            onChange={(e)=> setTanggalPerkawinan(e.target.value)}
                                            placeholder='Tanggal Perkawinan'
                                            />
                                        </label>
                                    </div>
                                </div>
                                {/* nama pasangan */}
                                <div className="columns">
                                    <div className="column is-2">
                                        <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Pasangan</div>
                                    </div>
                                    <div className="column is-9">
                                         {/* Nama Pasangan */}
                                        <label className='control mb-2'>
                                            <select className='input' value={pasangan} onChange={(e) => setPasangan(e.target.value)}>
                                                <option value="">Nama Pasangan</option>
                                                {/* Check if jemaatList is an array before mapping over it */}
                                                {Array.isArray(suggestedPasangan) && suggestedPasangan.map((jemaat) => (
                                                    <option key={jemaat.personId} value={jemaat.personId}>
                                                        {jemaat.namaDepan} {/* Menggunakan nilai jemaat.namaDepan sebagai teks opsi */}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                        </label>
                    {/* orang tua */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Ayah</div>
                        </div>
                        <div className="column is-9">
                            <label className='control mb-2'>
                                <input type="text"className='input' value ={ayah} 
                                onChange={(e)=> setAyah(e.target.value)}
                                placeholder='Nama Ayah'
                                />
                            </label>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nama Ibu</div>
                        </div>
                        <div className="column is-9">
                            <label className='control mb-2'>
                                <input type="text"className='input' value ={ibu} 
                                onChange={(e)=> setAyah(e.target.value)}
                                placeholder='Nama Ibu'
                                />
                            </label>
                        </div>
                    </div>
                    {/* Kontak */}
                    <div className='is-size-4 mb-3 has-text-weight-semibold'> Kontak </div>
                    <hr />
                    {/* Nomor telepon */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Nomor Telepon</div>
                        </div>
                        <div className="column is-9">
                            <label className='control '>
                                <input type="text"className='input' value ={nomorTelepon} 
                                onChange={(e)=> setNomorTelepon(e.target.value)}
                                placeholder='Nomor Telepon'
                                />
                            </label>
                        </div>
                    </div>
                    {/* Email */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Email </div>
                        </div>
                        <div className="column is-9">
                            <label className='control '>
                                <input type="text"className='input' value ={email} 
                                onChange={(e)=> setEmail(e.target.value)}
                                placeholder='Email'
                                />
                            </label>
                        </div>
                    </div>
                    <div className='is-size-4 mb-3 has-text-weight-semibold'>Informasi Jemaat:</div>
                    <hr />
                    {/* Gereja Lokal */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Gereja Lokal </div>                   
                        </div>
                        <div className="column is-9">
                            <div className="control mb-2">
                                <label className="radio">
                                    <input
                                    type="radio"
                                    value={lokasiGereja === "Surabaya Barat" ? "" : "Surabaya Barat"}
                                    checked={lokasiGereja === "Surabaya Barat"}
                                    onChange={(e) => setLokasiGereja(e.target.value)}
                                    />
                                    <span className="radio-text">Surabaya Barat </span>
                                </label>
                                <label className="radio">
                                    <input
                                    type="radio"
                                    value={lokasiGereja === "Surabaya Timur" ? "" : "Surabaya Timur"}
                                    checked={lokasiGereja === "Surabaya Timur"}
                                    onChange={(e) => setLokasiGereja(e.target.value)}
                                    />
                                    <span className="radio-text">Surabaya Timur </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* kerinduan */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Kerinduan </div>  
                        </div>
                        <div className="column is-9">
                            <label className='control mb-2'>
                                <input type="text"className='input' value ={kerinduan} 
                                onChange={(e)=> setKerinduan(e.target.value)}
                                placeholder='Kerinduan'
                                />
                            </label>
                        </div>
                    </div>
                    {/* komitmen berjemaat */}
                    <div className="columns">
                        <div className="column is-2">
                            <div className=' is-size-5 mb-3 has-text-weight-semibold'> Komitmen Berjemaat </div>  
                        </div>
                        <div className="column is-9">
                            <textarea
                            className='textarea'
                            value={komitmenBerjemaat}
                            onChange={(e) => setKomitmenBerjemaat(e.target.value)}
                            placeholder='Komitmen Berjemaat'
                            />
                        </div>
                    </div>
                    {/* Buttton Save */}
                    <div className="control">
                        <button className='button mt-2 is-success'>Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
)
}
export default UserUpdate