import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { useState } from 'react';
// Import Login
import Login from "./components/login.js";
import UserRegister from "./components/UserRegister.js";
// Import Dashboard
import Dashboard from "./components/Dashboard.js";
import DashboardEvent from "./components/DashboardEvent.js";
import DashboardLG from "./components/DashboardLG.js";
import DashboardDiscipleshipJourney from "./components/DashboardDiscipleshipJourney.js";
// Import User
import UserList from "./components/JemaatList.js";
import AddUser from "./components/JemaatAdd.js";
import EditUser from "./components/JemaatEdit.js";
import UserDetails from "./components/JemaatDetails.js";
import UserUpdate from "./components/UserUpdate.js";
import JemaatFamily from "./components/JemaatFamily.js";
import JemaatHistory from "./components/JemaatHistory.js";
// Import Event
import DiscipleshiEventAdd from "./components/EventAdd.js";
import DiscipleEventUpdate from "./components/EventEdit.js";
import EventList from "./components/EventList.js";
import EventDetail from "./components/EventDetail.js";
import EventParticipantAdd from "./components/EventParticipantAdd.js";
import EventParticipantAddByRecomendation from "./components/EventParticipantAddByRecomendation.js";
import DiscipleshipJourneyTypeAdd from "./components/DiscipleshipJourneyTypeAdd.js";
import DiscipleshipJourneyList from "./components/DiscipleshipJourneyList.js";
import SessionAdd from "./components/SessionAdd.js";
import AttendanceListAdd from "./components/AttendanceListAdd.js";
import AttendanceListDetails from "./components/AttendanceListDetails.js";
import DiscipleshipJourneyListClass from "./components/DiscipleshipJourneyListClass.js";
import DiscipleshipJourneyResult from "./components/DiscipleshipJourneyResult.js";
import LifegroupActivityList from "./components/LifegroupActivityList.js";
import ServiceEventDetail from "./components/ServiceEventDetail.js";
import ServiceEventAttendance from "./components/ServiceEventAttendance.js";
import AttendanceListUpdate from "./components/AttendanceListUpdate.js";

// Import LifeGroup
import AddLifegroup from "./components/LifegroupAdd.js";
import LifegroupDetails from "./components/LifegroupDetail.js";
import LifegroupMembersAdd from "./components/LifegroupMemberAdd.js";
import EditLifegroup from "./components/LifegroupEdit.js";
import LifegroupList from "./components/LifegroupList.js";
import Sidebar from './components/Sidebar.js'
// Tag
import TagAdd from "./components/TagAdd.js";
import TagList from "./components/TagList.js";
import JemaatTagAdd from "./components/JemaatTagAdd.js";
import TagListByPerson from "./components/TagListByPerson.js";
import TagListDetail from "./components/TagListDetaill.js";
// Import user telegram
import UserTelegramList from "./components/UserTelegramList.js";
import UserTelegramSendMessage from "./components/UserTelegramSendMessage.js";
import UserTelegramChooseReceiver from "./components/UserTelegramChooseReceiver.js";
// gmail
import GmailChooseReceiver from "./components/GmailChooseReceiver.js";
// Messages
import MessageList from "./components/MessageList.js";
import MessageListDetail from "./components/MessageListDetail.js";
// Import Management
import Management from "./components/Mangement.js";
// Event registration 
import EventAttendanceList from "./components/EventAttendanceList.js";
// USER
import ListUser from "./components/UserList.js";
import DetailUser from "./components/UserDetail.js";
import UserEdit from "./components/UserEdit.js";
import UserRegisterDataJemaat from "./components/UserRegisterDataJemaat.js";
// Home
import Home from "./components/Home.js";
// Activity Lifegroup
import ActivityLifegroupAdd from "./components/ActivityLifegroupAdd.js";
import ActivityLifegroupSessionAdd from "./components/ActivityLifegroupSessionAdd.js";
import ActivityLifegroupDetails from "./components/ActivityLifegroupDetails.js";
import ActivityLifegroupAttendanceListAdd from "./components/ActivityLifegroupAttendanceListAdd.js";
import ActivityLifegroupAddGeneral from "./components/ActivityLifegroupAdd.js";
import LifegroupActivityListPerLG from "./components/LifegroupActivityListPerLG.js";
import ActivityLifegroupAddPerLifegroup from "./components/ActivityLifegroupAddPerLifegroup.js";
import LifegroupActivityAttendanceDetail from "./components/LifegroupActivityAttendanceDetails.js";
import ActivityLifegroupAttendanceListUpdate from "./components/ActivityLifegroupAttendanceUpdate.js";
import LifegroupActivityAttendanceListPerLG from "./components/LifegroupActivityAttendanceListPerLG.js";

import DashboardMyLG from "./components/DashboardMyLG.js";

import ProtectedRoute from "./components/ProtectedRoute.js";

import './style/AppStyle.css'
import MessageListDetailPerson from "./components/MessageListDetailPerson.js";
import DiscipleshipJourneyClassAdd from "./components/DiscipleshipJourneyClassAdd.js";
import DiscipleshipJourneyTypeDetails from "./components/DiscipleshipJourneyTypeDetails.js";
import DiscipleshipJourneyResultView from "./components/DiscipleshipJourneyResultView.js";
import DiscipleshipJourneyTypeEdit from "./components/DiscipleshipJourneyTypeEdit.js";

import Topbar from "./components/Topbar.js";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}
function AppContent() {  
  // Gunakan useLocation hook untuk mendapatkan lokasi saat ini
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  // Tentukan apakah sidebar dan topbar harus ditampilkan berdasarkan lokasi saat ini
  const shouldShowSidebar = !(
    location.pathname === '/login' ||
    location.pathname.startsWith('/register/') ||
    location.pathname === '/registerDataJemaat'
  );

  const shouldShowTopbar = !(
    location.pathname === '/login' ||
    location.pathname.startsWith('/register/') ||
    location.pathname === '/registerDataJemaat'
  );

  // Fungsi untuk toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  };

  return (  
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        {shouldShowSidebar && isSidebarVisible && <Sidebar />}
        <div
          className="content"
            style={{
              marginLeft: shouldShowSidebar && isSidebarVisible ? '15.625rem' : '0',
              flex: 1,
              overflow: 'auto',
              boxSizing: 'border-box',
              height: '100%', // Ensure content fills the available height
              display: 'flex',
              flexDirection: 'column', //npm Arrange content in a column
            }}
        >
          {shouldShowTopbar && <Topbar onToggleSidebar={toggleSidebar} />}
          <div className="fieldd" style={{ flex: 1,}}>

          <Routes>
            <Route
              path="/login"
              element={<Login/>}
            />
            <Route path="/register/:id" element={<UserRegister />} />
            <Route path="/registerDataJemaat" element={<UserRegisterDataJemaat />} />

            
            {/* Dashboard */}
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/eventDashboard" element={<ProtectedRoute><DashboardEvent /></ProtectedRoute>} />
            <Route path="/LifegroupDashboard" element={<ProtectedRoute><DashboardLG /></ProtectedRoute>} />
            <Route path="/DiscipleshipJourneyDashboard" element={<ProtectedRoute><DashboardDiscipleshipJourney /></ProtectedRoute>} />
            <Route path="/MyDashboardLg/:id" element={<ProtectedRoute><DashboardMyLG /></ProtectedRoute>} />

            
            {/* Home */}
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            
            {/* Jemaat */}
            <Route path="UserList" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
            <Route path="addJemaat" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
            <Route path="userList/editUser/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
            <Route path="userList/userDetails/:id" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
            <Route path="userUpdate/:id" element={<ProtectedRoute><UserUpdate /></ProtectedRoute>} />
            <Route path="FamilyMembers/:id" element={<ProtectedRoute><JemaatFamily /></ProtectedRoute>} />
            <Route path="JemaatHistory/:id" element={<ProtectedRoute><JemaatHistory /></ProtectedRoute>} />


            
            {/* User */}
            <Route path="ListUser" element={<ProtectedRoute><ListUser /></ProtectedRoute>} />
            <Route path="DetailUser/:id" element={<ProtectedRoute><DetailUser /></ProtectedRoute>} />
            <Route path="UserEdit/:id" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />


            {/* Event */}
            <Route path="addEvent" element={<ProtectedRoute><DiscipleshiEventAdd /></ProtectedRoute>} />
            <Route path="EditEvent/:id" element={<ProtectedRoute><DiscipleEventUpdate /></ProtectedRoute>} />
            <Route path="EventDetail/:id" element={<ProtectedRoute><EventDetail /></ProtectedRoute>} />
            <Route path="EventList" element={<ProtectedRoute><EventList /></ProtectedRoute>} />
            <Route path="ServiceEventDetail/:id" element={<ProtectedRoute><ServiceEventDetail/></ProtectedRoute>} />
            <Route path="ServiceEventAttendance/:id" element={<ProtectedRoute><ServiceEventAttendance /></ProtectedRoute>} />

            {/* Disc Journey */}
            <Route path="AddDiscipleshipJourney" element={<ProtectedRoute><DiscipleshipJourneyTypeAdd /></ProtectedRoute>} />
            <Route path="DiscipleshipJourneyClassList" element={<ProtectedRoute><DiscipleshipJourneyListClass /></ProtectedRoute>} />
            <Route path="ClassResult/:id" element={<ProtectedRoute><DiscipleshipJourneyResult /></ProtectedRoute>} />
            <Route path="ClassResultView/:id" element={<ProtectedRoute><DiscipleshipJourneyResultView /></ProtectedRoute>} />

            {/* Type */}
            <Route path="DiscipleshipJourneyList" element={<ProtectedRoute><DiscipleshipJourneyList /></ProtectedRoute>} />
            <Route path="AddDiscipleshipJourneyClass" element={<ProtectedRoute><DiscipleshipJourneyClassAdd /></ProtectedRoute>} />
            <Route path="DiscipleshipJourneyTypeDetail/:id" element={<ProtectedRoute><DiscipleshipJourneyTypeDetails /></ProtectedRoute>} />
            <Route path="DiscipleshipJourneyTypeEdit/:id" element={<ProtectedRoute><DiscipleshipJourneyTypeEdit /></ProtectedRoute>} />

            {/* Session */}
            <Route path="AddSession/:id" element={<ProtectedRoute><SessionAdd /></ProtectedRoute>} />

            {/* Tag */}
            <Route path="AddTag" element={<ProtectedRoute><TagAdd /></ProtectedRoute>} />
            <Route path="TagList" element={<ProtectedRoute><TagList /></ProtectedRoute>} />
            <Route path="JemaatTagAdd/:id" element={<ProtectedRoute><JemaatTagAdd /></ProtectedRoute>} />
            <Route path="TagListByPerson/:id" element={<ProtectedRoute><TagListByPerson /></ProtectedRoute>} />
            <Route path="TagListByTag/:id" element={<ProtectedRoute><TagListDetail /></ProtectedRoute>} />
            
            {/* Event Participant */}
            <Route path="AddEventParticipant/:id" element={<ProtectedRoute><EventParticipantAdd /></ProtectedRoute>} />
            <Route path="AddEventParticipantByRecomendation" element={<ProtectedRoute><EventParticipantAddByRecomendation /></ProtectedRoute>} />

            
            {/* Event registration */}
            <Route path="EventRegistration/:id" element={<ProtectedRoute><EventAttendanceList /></ProtectedRoute>} />
            
            {/* Absen */}
            <Route path="AddAttendanceList/:id" element={<ProtectedRoute><AttendanceListAdd /></ProtectedRoute>} />
            <Route path="UpdateAttendanceList/:id" element={<ProtectedRoute><AttendanceListUpdate /></ProtectedRoute>} />
            <Route path="DetailAttendanceList/:id" element={<ProtectedRoute><AttendanceListDetails /></ProtectedRoute>} />
            
            {/* Lifegroup */}
            <Route path="addLifegroup" element={<ProtectedRoute><AddLifegroup /></ProtectedRoute>} />
            <Route path="viewLifegroup/:id" element={<ProtectedRoute><LifegroupDetails /></ProtectedRoute>} />
            <Route path="EditLifegroup/:id" element={<ProtectedRoute><EditLifegroup /></ProtectedRoute>} />
            <Route path="addLifegroupMember/:id" element={<ProtectedRoute><LifegroupMembersAdd /></ProtectedRoute>} />
            <Route path="LifegroupList" element={<ProtectedRoute><LifegroupList /></ProtectedRoute>} />

            {/* Activity Lifegroup */}
            <Route path="AddActivityLg/:id" element={<ProtectedRoute><ActivityLifegroupAdd /></ProtectedRoute>} />
            <Route path="SessionAddActivityLg/:id" element={<ProtectedRoute><ActivityLifegroupSessionAdd /></ProtectedRoute>} />
            <Route path="ActivityLGList" element={<ProtectedRoute><LifegroupActivityList /></ProtectedRoute>} />
            <Route path="ActivityLGAdd" element={<ProtectedRoute><ActivityLifegroupAddGeneral /></ProtectedRoute>} />
            <Route path="ActivityLGListPerLG/:id" element={<ProtectedRoute><LifegroupActivityListPerLG /></ProtectedRoute>} />
            <Route path="ActivityLGAddPerLG/:id" element={<ProtectedRoute><ActivityLifegroupAddPerLifegroup /></ProtectedRoute>} />
            <Route path="ActivityLGDetails/:id" element={<ProtectedRoute><ActivityLifegroupDetails /></ProtectedRoute>} />
            <Route path="ActivityLGAttendance/:id" element={<ProtectedRoute><ActivityLifegroupAttendanceListAdd /></ProtectedRoute>} />
            <Route path="ActivityLGAttendanceDetail/:id" element={<ProtectedRoute><LifegroupActivityAttendanceDetail /></ProtectedRoute>} />
            <Route path="ActivityLGAttendanceUpdate/:id" element={<ProtectedRoute><ActivityLifegroupAttendanceListUpdate /></ProtectedRoute>} />
            <Route path="LifegroupActivityAttendanceListPerLG/:id" element={<ProtectedRoute><LifegroupActivityAttendanceListPerLG /></ProtectedRoute>} />

            {/* User telegram */}
            <Route path="ListUserTelegram" element={<ProtectedRoute><UserTelegramList /></ProtectedRoute>} />
            <Route path="SendMessageTelegram" element={<ProtectedRoute><UserTelegramSendMessage /></ProtectedRoute>} />
            <Route path="ChooseReceiverTelegram" element={<ProtectedRoute><UserTelegramChooseReceiver /></ProtectedRoute>} />
            
            {/* Gmail */}
            <Route path="Gmail" element={<ProtectedRoute><GmailChooseReceiver /></ProtectedRoute>} />
            
            {/* Message */}
            <Route path="MessagesList" element={<ProtectedRoute><MessageList /></ProtectedRoute>} />
            <Route path="MessageDetail/:id" element={<ProtectedRoute><MessageListDetail /></ProtectedRoute>} />
            <Route path="MessageDetailPerson/:id" element={<ProtectedRoute><MessageListDetailPerson /></ProtectedRoute>} />
            
            {/* Management */}
            <Route path="Management" element={<ProtectedRoute><Management /></ProtectedRoute>} />
            
          </Routes>
        </div>
      </div>
      </div>
      </div>
  );
}

export default App;      