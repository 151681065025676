import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserTelegramChooseReceiver = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserTelegram`);
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleCheckboxChange = (userId) => {
        // Toggle the user's selection status
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter(id => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const handleNextButtonClick = async (e) => {
        console.log(selectedUsers);
        // Send messages to selected users
        try {
            navigate("/SendMessageTelegram", { state: { selectedUsers } });
        } catch (error) {
            console.error('Error sending messages:', error);
        }
    };


    return (
        <div>
            <div className="is-size-4 mb-3 has-text-weight-bold">Send Message</div>
            <hr />
            <div className="is-size-4 mb-3 has-text-weight-semibold">Pilih User</div>
            <hr />
            <table id="dataTable" className="table is-striped is-fullwidth mt-5">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ChatRoomId</th>
                        <th>Check</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.chatRoomId}>
                            <td>{user.personName}</td>
                            <td>{user.chatRoomId}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user.chatRoomId)}
                                    onChange={() => handleCheckboxChange(user.chatRoomId)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button className="button is-primary" onClick={handleNextButtonClick}>Next</button>
        </div>
    );
};

export default UserTelegramChooseReceiver;
