import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import '../style/UserAddStyle.css'


const DiscipleshipJourneyTypeAdd = () => {

    const navigate = useNavigate();

    // Untuk data yang ke DB

    const [discipleshipJourneyName, setDiscipleshipJourneyName] = useState("");
    const [discipleshipJourneyPrereq, setDiscipleshipJourneyPrereq] = useState("");

    // Untuk Sugest
    const [optionPrereq, setOptionPrereq] = useState([]);
    const [selectedPrereq, setSelectedPrereq] = useState(null);

    // sugest pre req
    const [suggestDiscipleshipJourney, setSuggestDiscipleshipJourney] = useState("");
    
    // sugest function
    // Fetch list kelas
    const getDisciplesipJourney = async () => {
        const response = await axios.get('${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType');
        setSuggestDiscipleshipJourney(response.data);
        console.log(response)
    };
    
    // Select Prerequisites
    useEffect(() => {
        const fetchPrereqOptions = async () => {
        try {
            const response = await axios.get('${process.env.REACT_APP_API_URL}/viewDiscipleshipJourneyType');
            const options = response.data.map((dj) => ({
            value: dj.discipleshipJourneyTypeId,
            label: dj.discipleshipJourneyTypeName,
            }));
            setOptionPrereq(options);
        } catch (error) {
            console.error('Error fetching discipleship journey types:', error);
        }
        };

        fetchPrereqOptions();
    }, []);

    // Handle select discipleship journey type
    const handlePrereqChange = (selectedOption) => {
        setSelectedPrereq(selectedOption);
        console.log('Selected DJ:', selectedOption);
        setDiscipleshipJourneyPrereq(selectedOption.value);
    };

    const saveDiscipleshipJourney = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                discipleshipJourneyTypeName:discipleshipJourneyName,
                prereq:discipleshipJourneyPrereq,
                status:'active'
            };
    
            // Filter hanya properti yang memiliki nilai
            const filteredUserData = Object.fromEntries(
                Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
            );
            console.log(filteredUserData);
            await axios.post(`${process.env.REACT_APP_API_URL}/insertDiscipleshipJourneyType`, filteredUserData);
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    //Use Effect
    useEffect(()=> {
        getDisciplesipJourney();
    },[]);

return (
    <div className="columns is-mobile mr-2">
        <div className="column ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Discipleship Journey Information</div>
            <hr />
            <form onSubmit={saveDiscipleshipJourney}action=''>
            {/* Nama */}
            <div className="columns">
                <div className="column is-4">
                    <div className='is-size-5 mb-2 has-text-weight-semibold'>Discipleship Journey Name</div>
                </div>
                <div className="column is-8">
                    <input type="text"className='input' value ={discipleshipJourneyName} 
                        onChange={(e)=> setDiscipleshipJourneyName(e.target.value)}
                        placeholder='Discipleship Journey Name'
                        />
                </div>
            </div>
            {/* Prereq */}
            <div className="columns ">
                <div className="column is-4">
                    <div className=' is-size-5 mb-2 has-text-weight-semibold'>Prereq (syarat)</div>
                </div>
                <div className="column">
                        <Select
                            value={selectedPrereq}
                            onChange={handlePrereqChange}
                            options={optionPrereq}
                        />
                </div>
            </div>
            <div className="is-flex is-justify-content-flex-end mt-4">
                <button className='button' style={{
                    backgroundColor: '#0052CC',
                    color: 'white',
                    padding: '10px 24px',
                    fontWeight: 'bold',
                    borderRadius: '8px'
                }}>
                    Save
                </button>
            </div>
            </form>
        </div>
    </div>
)
}
export default DiscipleshipJourneyTypeAdd