import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';
import "../style/tableStyle.css";
import { exportToCSV } from '../Utils/csvUtils';


const LifegroupList = () => {
    const [Lifegroups, setLifegroups] = useState([]);
    const [filter, setFilter] = useState('all');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getLifegroups();
    }, []);

    const getLifegroups = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifegroupWithLeader`);
            console.log(response)
            setLifegroups(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateLifegroup = async (id, status) => {
        console.log(status,id)
        // Cek status dan lakukan pembaruan yang sesuai
        if (status === 'active') {
            // Logika untuk status 'active'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateLifegroup/${id}`, { status: 'deactive' });
            console.log(`success update Lifegroup ${id} to inactive status`);
        } else {
           // Logika untuk status 'dective'
            await axios.patch(`${process.env.REACT_APP_API_URL}/updateLifegroup/${id}`, { status: 'active' });
            console.log(`success update Lifegroup ${id} to active status`);
        }
        // Lakukan update ke server atau state management
        // Refresh halaman setelah pembaruan
        window.location.reload(); // Reload halaman untuk menyegarkan data
    };



    const filteredLifegroups = useMemo(() => {
        return Lifegroups.filter(lifegroup => {
            // Filter berdasarkan status jika tidak 'all'
            if (filter !== 'all' && lifegroup.status !== filter) return false;

            // Jika hanya satu kategori yang dipilih, tampilkan lifegroups yang memiliki kategori yang dipilih
            if (selectedCategories.length === 1) {
                return lifegroup.lifegroupCategory.includes(selectedCategories[0]);
            }

            // Jika lebih dari satu kategori yang dipilih, tampilkan lifegroups yang memiliki salah satu dari kategori yang dipilih
            if (selectedCategories.length > 1) {
                return selectedCategories.some(cat => lifegroup.lifegroupCategory.includes(cat));
            }

            return true;
        });
    }, [Lifegroups, filter, selectedCategories]);


    const alphanumericCaseInsensitive = (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].toLowerCase();
        const b = rowB.values[columnId].toLowerCase();
        return a > b ? 1 : a < b ? -1 : 0;
    };

    const columns = useMemo(() => [
        {
        Header: 'Lifegroup Leader',
        accessor: 'lifegroupLeader', // Make sure this matches the data field for the leader's name
        Cell: ({ row }) => <span>{row.original.leaderNamaDepan} {row.original.leaderNamaTengah} {row.original.leaderNamaBelakang}</span>, // Display the leader's name
        sortType: alphanumericCaseInsensitive
        },
        {
            Header: 'Lifegroup Name',
            accessor: 'lifegroupName',
            Cell: ({ row }) => (
                <Link to={`/viewLifegroup/${row.original.lifegroupId}`} className="has-text-info">
                    {row.original.lifegroupName}
                </Link>
            ),
            sortType: alphanumericCaseInsensitive
        },
        { Header: 'Lifegroup Category', accessor: 'lifegroupCategory', sortType: alphanumericCaseInsensitive },
    {
        Header: 'Action',
        accessor: 'actions',
        Cell: ({ row }) => {
            console.log(row.original)
            const { lifegroupId, status } = row.original;
            const isActive = status === 'active'; // Check if status is 'active'
            
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '36px' }}>
                    <button
                        className="button is-small"
                        onClick={() => navigate(`/LifegroupActivityAttendanceListPerLG/${lifegroupId}`)}
                        style={{ backgroundColor: '#0082FF', color: 'white' }} // Set background color and text color
                    >
                        Attendance List
                    </button>
                    <button
                        onClick={() => updateLifegroup(lifegroupId, status)} // Pass both the event ID and status
                        style={{ 
                            background: 'none', 
                            border: 'none', 
                            cursor: 'pointer', 
                            padding: '0',
                            color: '#0082FF' // Set text color to match button color
                        }}
                    >
                        {isActive ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                <path fill="#00A84D" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                    <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                </path>
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 24 24">
                                <path fill="#FF0000" d="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z">
                                    <animate fill="freeze" attributeName="d" dur="0.2s" values="M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm0 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z;M17 7a5 5 0 0 1 0 10H7A5 5 0 1 1 7 7h10Zm-10 2a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" />
                                </path>
                            </svg>
                        )}
                    </button>
                </div>
            );
        }
    }

    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data: filteredLifegroups, initialState: { pageIndex: 0, pageSize: 10 }, sortTypes: { alphanumericCaseInsensitive } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    // Fixed categories
    const fixedCategories = ['SMP', 'SMA', 'UNI', 'Family', 'Youngpro', 'Senior'];

    const handleCategoryChange = (event) => {
        const value = event.target.value;
        setSelectedCategories(value ? [value] : []);
    };

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-2">
                    <div className="column is-7">
                        <div className="custom-button mr-2" onClick={() => navigate('/addLifegroup')}>Add Lifegroup</div>
                        <button className="custom-button mr-2" onClick={() => exportToCSV(Lifegroups, 'lifegroups')}>Export to CSV</button>
                    </div>
                    <div className="column is-flex is-justify-content-flex-end">
                        <div className="field is-grouped">
                            <div className="control">
                                <div className="field">
                                    <div className="select">
                                        <select onChange={handleCategoryChange} value={selectedCategories[0] || ''}>
                                            <option value="">Select a category</option>
                                            {fixedCategories.map(category => (
                                                <option key={category} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="control">
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={e => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>

                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0} 
                            </strong>
                        </span>
                    </div>
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifegroupList;
