import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useSortBy } from 'react-table';
import "../style/tableStyle.css";

const TagListByPerson = () => {
    const [users, setUsers] = useState([]);
    const [namadepan, setNamaDepan] = useState("");
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getTagByUser();
    }, []);

    const getTagByUser = async () => {
        try {
            const responseUser = await axios.get(`${process.env.REACT_APP_API_URL}/viewPersonById/${id}`);
            setNamaDepan(responseUser.data.namaDepan + " " + responseUser.data.namaTengah);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewTagListByPersonId/${id}`);
            setUsers(response.data);
            setDataFetched(true);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteTagList = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/deleteTagList/${id}`);
            alert("Successfully deleted");
            // Refresh the tag list after deletion
            getTagByUser();
        } catch (error) {
            console.error(error);
        }
    };

    // Define columns
    const columns = React.useMemo(() => [
        {
            Header: 'Tag Name',
            accessor: 'tagName',
        },
        {
            Header: 'Action',
            accessor: 'tagId',
            Cell: ({ row }) => (
                <div>
                    <Link to={`/TagListByTag/${row.original.tagId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>
                    <button className="button is-danger is-small" onClick={() => deleteTagList(row.original.tagListId)}>Delete</button>
                </div>
            ),
        },
    ], []);

    // Prepare data
    const data = React.useMemo(() => users, [users]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        usePagination
    );

    return (
        <div className="column">
            <div className="columns">
                <div className="column is-2">
                    <div className='is-size-5 mb-3 has-text-weight-bold'>Nama Jemaat</div>
                </div>
                <div className="column">
                    <label className='control mb-2'>
                        <input
                            type="text"
                            className='input'
                            value={namadepan}
                            readOnly={true} // Menjadikan input menjadi readonly
                        />
                    </label>
                </div>
            </div>
            <Link to={`/JemaatTagAdd/${id}`} className="custom-button mb-4" style={{ marginRight: '10px' }}>Add Tag</Link>
            <table {...getTableProps()} className="table is-striped is-fullwidth mt-5">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TagListByPerson;
