import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useParams , Link } from 'react-router-dom';

// Style
import "../style/tableStyle.css";
import "../style/ActivityLifegroupStyle.css";


const ActivityLifegroupDetails = () => {
    const [ActivityLifegroup,setActivityLifegroup] =useState([]);
    const { id } = useParams();

    useEffect(()=>{
        getUserById();
    }, []);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            console.log(response)
            setActivityLifegroup(response.data)
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error('Invalid date string:', dateString);
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',   // Short weekday name, e.g., "Mon"
            day: 'numeric',     // Numeric day, e.g., "3"
            month: 'short',      // Short month name, e.g., "Jan"
            year: 'numeric'      // Numeric year, e.g., "2024"
        }).format(date);
    };
    
    return (
        <div className='columns'>
            <div className="column is-6 card m-2">
                <div className='event-info-title'>Event Information</div>
                <hr />
                <div className='event-info-label'>Event Type</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={ActivityLifegroup.eventType}
                        readOnly={true}
                        placeholder='Event Type'
                    />
                </label>
                <div className='event-info-label'>Event Date</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={formatDate(ActivityLifegroup.eventDate)}
                        readOnly={true}
                        placeholder='Event Date'
                    />
                </label>
                <div className='event-info-label'>Event Location</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={ActivityLifegroup.eventLokasi}
                        readOnly={true}
                        placeholder='Event Location'
                    />
                </label>
                <div className='event-info-label'>Event Time</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={(ActivityLifegroup.eventTimeIn)+' - '+(ActivityLifegroup.eventTimeOut)}
                        readOnly={true}
                        placeholder='Event Type'
                    />
                </label>
                <div className='event-info-action'>
                    <Link
                        to={`/EditEvent/${id}`}
                        className="button event-info-button"
                        style={{
                            backgroundColor: '#0052CC',
                            color: 'white',
                            padding: '10px 24px',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            marginRight: '10px'
                        }}
                    >
                        Edit Activity Lifegroup
                    </Link>
                </div>
            </div>
            <div className="column is-5 card m-2">
                <div className='event-info-title'>Detail Activity Lifegroup</div>
                <hr />
                <div className='event-info-label'>Series Title</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={ActivityLifegroup.seriesTitle}
                        readOnly={true}
                        placeholder='Series Title'
                    />
                </label>
                <div className='event-info-label'>Fasilitator</div>
                <label className='control event-info-input'>
                    <input
                        type="text"
                        className='input'
                        value={ActivityLifegroup.fasilitator}
                        readOnly={true}
                        placeholder='Fasilitator'
                    />
                </label>
                <div className='event-info-action'>
                    <Link
                        to={`/ActivityLGAttendanceDetail/${id}`}
                        className="button event-info-button"
                        style={{
                            backgroundColor: '#0052CC',
                            color: 'white',
                            padding: '10px 24px',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            marginRight: '10px'
                        }}
                    >
                        Presensi
                    </Link>
                </div>
            </div>
        </div>

    )
}
export default ActivityLifegroupDetails