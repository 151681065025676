import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import "../style/tableStyle.css";
import "../style/LifegroupAttendanceListPerLGStyle.css";


const LifegroupActivityAttendanceListPerLG = () => {
    const [DiscipleshipJourneys, setDiscipleshipJourneys] = useState([]);
    const [LG, setLG] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getDiscipleshipJourneys();
        getLG();
    }, []); // Only run once on component mount

    const getDiscipleshipJourneys = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewActivityLifegroup`);
            const filteredData = response.data.filter(item => item.lifegroupId === id);
            setDiscipleshipJourneys(filteredData);
        } catch (error) {
            console.error(error);
        }
    };

    const getLG = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${id}`);
            setLG(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return "Date not assigned";
        }

        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase(); // 3-letter month abbreviation in uppercase
        const year = date.getFullYear();

        return `${day < 10 ? '0' + day : day} ${month}\n${year}`;
    };




    const getMembers = () => {
        const members = new Set();
        DiscipleshipJourneys.forEach(journey => {
            journey.attendanceList.forEach(attendance => {
                members.add(`${attendance.namaDepan} ${attendance.namaTengah || ''} ${attendance.namaBelakang || ''}`);
            });
        });
        return Array.from(members);
    };

    const getDates = () => {
        const dates = new Set();
        DiscipleshipJourneys.forEach(journey => {
            dates.add(formatDate(journey.eventDate));
        });
        return Array.from(dates);
    };

    const getAttendanceMatrix = () => {
        const matrix = {};
        DiscipleshipJourneys.forEach(journey => {
            const date = formatDate(journey.eventDate);
            journey.attendanceList.forEach(attendance => {
                const memberName = `${attendance.namaDepan} ${attendance.namaTengah || ''} ${attendance.namaBelakang || ''}`;
                if (!matrix[memberName]) {
                    matrix[memberName] = {};
                }
                matrix[memberName][date] = attendance.kehadiran;
            });
        });
        return matrix;
    };

    // Memoizing to prevent unnecessary renders
    const members = React.useMemo(() => getMembers(), [DiscipleshipJourneys]);
    const dates = React.useMemo(() => getDates(), [DiscipleshipJourneys]);
    const attendanceMatrix = React.useMemo(() => getAttendanceMatrix(), [DiscipleshipJourneys]);

    // Define columns for React Table
    const columns = React.useMemo(() => {
        const dateColumns = dates.map(date => ({
            Header: date.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
            )),
            accessor: date,
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={attendanceMatrix[row.original.memberName][date] || false}
                    readOnly
                    style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        cursor: 'default'
                    }}
                />
            )
        }));

        return [
            {
                Header: 'Member Name',
                accessor: 'memberName'
            },
            ...dateColumns
        ];
    }, [dates, attendanceMatrix]);

    // Prepare data for React Table
    const data = React.useMemo(() => {
        return members.map(member => {
            const rowData = { memberName: member };
            dates.forEach(date => {
                rowData[date] = attendanceMatrix[member][date] || false;
            });
            return rowData;
        });
    }, [members, dates, attendanceMatrix]);

    // Table instance with pagination and global filter
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of using rows, we'll use page to support pagination
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize: setPageSizeFromTableInstance, // Renaming to avoid conflict
        state: { pageIndex, pageSize: currentPageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        { columns, data, initialState: { pageSize } },
        useGlobalFilter,
        usePagination // Add usePagination hook
    );

    return (
        <div className="columns">
            <div className="column">
                <div className="columns mt-1">
                    <div className="column is-7">
                        <div className='is-size-3 mb-4 has-text-weight-semibold'>{LG.lifegroupName}</div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-7">
                        <div className="custom-button mr-2" onClick={() => navigate(`/ActivityLGAddPerLG/${id}`)}>Add Lifegroup Activity</div>
                        <button className="custom-button mr-2" onClick={() => navigate(`/addLifegroupMember/${id}`)}>Add Lifegroup Member</button>
                    </div>
                </div>                
                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSizeFromTableInstance(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>
                {/* Table */}
                <div className="table-container">
                    <table {...getTableProps()} className="table mt-5">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned: Showing X to Y rows */}
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    {/* Center-aligned: Previous, Page Number, Next */}
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    {/* Right-aligned: Page X of Y */}
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifegroupActivityAttendanceListPerLG;
