import React,{useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, useParams , Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import "../style/tableStyle.css";

const EventDetail = () => {
    const [image, setImage] = useState(null)
    const [imageName, setName] = useState("");
    const [namaEvent,setNamaEvent] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [eventTimeIn, setEventTimeIn] = useState("");
    const [eventTimeOut, setEventTimeOut] = useState("");
    const [eventCapacity, setEventCapacity] = useState("");
    const [eventSetup, seteventSetup] = useState("");
    const [eventAttendanceTotal, seteventAttendanceTotal] = useState("");
    const [eventLokasi, seteventLokasi] = useState("");
    const [eventType, seteventType] = useState("");

    // atribut khusus Discipleship event 
    const [seriesTitle, setseriesTitle] = useState("");
    const [fasilitator,setfasilitator] =useState("");

        // atribut khusus Discipleship event 
    const[discipleshipJourneyType,setDiscipleshipJourneyType] = useState("");

    // atribut khusus Common event 
    const[organizer,setOrganizer] = useState("");

    // atribut khusus Service 
    // Ada series title
    const[sermonTitle,setSermonTitle] = useState("");
    const[preacher,setPreacher] = useState("");

    // Atribut Khusus Activity LG
    const[lifegroupId,setLifegroupId] = useState("");
    // series title
    // fasilitator

    const [Session, setSession] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (dataFetched) {
            $('#dataTable').DataTable(); // Initialize DataTables
        }
    }, [dataFetched]);

    useEffect(()=>{
        getUserById();
        getSessionByEventId();
    }, []);

    const getUserById= async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            console.log(response)
            seteventType(response.data.eventType);
            setEventName(response.data.eventName);
            seteventType(response.data.eventType)
            setEventDate(response.data.eventDate);
            setEventTimeIn(response.data.eventTimeIn);
            setEventTimeOut(response.data.eventTimeOut);
            setEventCapacity(response.data.eventCapacity);
            seteventSetup(response.data.eventSetup);
            seteventAttendanceTotal(response.data.eventAttendanceTotal);
            seteventLokasi(response.data.eventLokasi);
            setseriesTitle(response.data.seriesTitle);
            setfasilitator(response.data.fasilitator);
            setOrganizer(response.data.organizer);
            setSermonTitle(response.data.sermonTitle);
            setPreacher(response.data.preacher);
            setDiscipleshipJourneyType(response.data.setDiscipleshipJourneyTypeId)
    };

    const getSessionByEventId = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewSessionByEventId/${id}`);
        setSession(response.data);
        setDataFetched(true); 
        console.log(response)

    };
    
return (
<div className="column">
    <div className='is-size-4 mb-3 has-text-weight-bold'>Detail Event</div>
    <Link to={`/editEvent/${id}`} className="button is-info is-small"> Edit Event </Link>
    <hr />
    <div className='is-size-4 mb-5 has-text-weight-semibold'>Session List</div>
        <div className="control mb-5">
            {/* Button to trigger update */}
            <Link to={`/AddSession/${id}`} className="button is-info is-small"> Add Session </Link>
            <Link to={`/EventRegistration/${id}`} className="button is-info is-small" style={{ marginLeft: '10px' }}> Event Participant </Link>
        </div>
    {/* Table */}
    <div className="table-container">
        <table id="dataTable" className="table">
            <thead>
                <tr>
                    <th>Action</th>
                    <th>Session Name</th>
                    <th>Session Date</th>
                </tr>
            </thead>
            <tbody>
                {Session.map(Event => (
                    <tr key={Event.eventId}>
                        <td>
                        <Link to={`/DetailAttendanceList/${Event.sessionId}`} className="button is-info is-small" style={{ marginRight: '10px' }}>Details</Link>          
                        </td>
                        <td>{Event.sessionName} </td>
                        <td>{Event.sessionDate}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <hr />
    <div className='is-size-4 mb-4 has-text-weight-semibold'>Event Information</div>
    <hr />
    {/* Event Name */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Name</div>
        </div>
        <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={eventName}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
        </div>
    </div>
    {/* Event Type */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Type</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventType}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    {/* Event Date */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Date</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventDate}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    {/* Event Location */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Event Location</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventLokasi}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    {/* Time in and out */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Waktu Mulai</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventTimeIn}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Waktu Akhir</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventTimeOut}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    <div className='is-size-4 mb-4 has-text-weight-semibold'>Capacity, Setup, and Attendance Information</div>
    <hr />
    {/* Kapasitas */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Capacity</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventCapacity}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    {/* Event Setup */}
    <div className="columns">
        <div className="column is-2">
            <div className=' is-size-5 mb-3 has-text-weight-semibold'>Setup Event</div>
        </div>
        <div className="column is-3">
            <label className='control mb-2'>
                <input
                    type="text"
                    className='input'
                    value={eventSetup}
                    readOnly={true} // Menjadikan input menjadi readonly
                />
            </label>
        </div>
    </div>
    <div className='is-size-4 mb-4 has-text-weight-semibold'>Detail {eventType}</div>
    <hr />
    {/* Common Event */}
    {/* Organizer */}
    <label className={`control ${eventType != "CommonEvent" ? 'hidden' : ''}`}>
        <div className="columns">
            <div className="column is-2">
                    <div className=' is-size-5 mb-3 has-text-weight-semibold'>Organizer</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={organizer}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
            </div>
        </div>
    </label>
    {/* Service */}
    <label className={`control ${eventType != "Service" ? 'hidden' : ''}`}>
        {/* Series Title */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={seriesTitle}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
            </div>
        </div>
        {/* Sermon Title */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Sermon Title</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={sermonTitle}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
            </div>
        </div>
        {/* Preacher */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Preacher</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={preacher}
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
            </div>
        </div>
    </label>
    {/* Activity LG */}
    <label className={`control ${eventType != "Activity Lifegroup" ? 'hidden' : ''}`}>
        {/* LG Name */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Lifegroup Name</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={organizer} //ganti value ke LG
                        readOnly={true} 
                    />
                </label>
            </div>
        </div>
        {/* Series Title */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Series Title</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={seriesTitle} //ganti value ke LG
                        readOnly={true}
                    />
                </label>
            </div>
        </div>
        {/* Fasilitator */}
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Fasilitator</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={fasilitator} //ganti value ke LG
                        readOnly={true}
                    />
                </label>
            </div>
        </div>
    </label>
    {/* DiscJourney */}
    <label className={`control ${eventType != "Discipleship Journey" ? 'hidden' : ''}`}>
        <div className="columns">
            <div className="column is-2">
                <div className=' is-size-5 mb-3 has-text-weight-semibold'>Discipleship Journey Type</div>
            </div>
            <div className="column is-3">
                <label className='control mb-2'>
                    <input
                        type="text"
                        className='input'
                        value={organizer} // ganti ke disc journey type
                        readOnly={true} // Menjadikan input menjadi readonly
                    />
                </label>
            </div>
        </div>
    </label>
</div>

)
}
export default EventDetail