import React, { useState,  } from 'react';
import axios from 'axios';
import { useNavigate, Link , useParams } from 'react-router-dom';
import '../style/UserRegisterStyle.css'; // File CSS untuk styling khusus halaman login


const UserRegister = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [Email, setEmail] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  const checkUsername= async (username) =>{
    try {
      if(username.length > 4){
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserByUsername/${username}`);
      if (response.status === 200 && response.data) {
            alert('Username Already Exists');
          } else if (response.status === 404) {
            console.log('Username not found, proceed to the next step');
            // Your code for the next step here
          } else if (response.status === 400) {
            console.log('Bad Request for username check, but continuing');
          }
    }
    else{
      alert('Username must be at least 5 characters');
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log('Username not found, proceed to the next step');
      // Your code for the next step here
    } else {
      console.error('Error fetching data:', error);
      alert('An error occurred while fetching data');
    }
  }
}


  const checkEmail= async (email) =>{
    try {
    if (!email.includes('@')) {
      alert('Please enter a valid email address')
    }
    else{
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewUserByEmail/${email}`);
      if (response.status === 200 && response.data) {
        alert('Email Already Exists');
      } else if (response.status === 404) {
        console.log('Username not found, proceed to the next step');
        // Your code for the next step here
      } else if (response.status === 400) {
        console.log('Bad Request for username check, but continuing');
    }
  }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log('Username not found, proceed to the next step');
      // Your code for the next step here
    } else {
      console.error('Error fetching data:', error);
      alert('An error occurred while fetching data');
    }
  }
}

  const checkPasswrod= async (password,password2) =>{
    if(password !== password2 ){
      alert('Password Tidak Sama')
    }
    else{
      console.log('Passwword same')
    }
}



const saveUser = async (e) => {
        e.preventDefault();
        try {
            checkUsername(username)
            checkEmail(Email)
            checkPasswrod(password,password2)
              const userData = {
                  UserName:username,
                  Email:Email,
                  password,
                  role:"jemaat",
                  personId: id,
                  dateAdded:new Date(),   
              };
      
              // Filter hanya properti yang memiliki nilai
              const filteredUserData = Object.fromEntries(
                  Object.entries(userData).filter(([_, value]) => value !== null && value !== undefined && value !== "")
              );
              console.log(filteredUserData)
              await axios.post(`${process.env.REACT_APP_API_URL}/insertUser`, filteredUserData);
              navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

  return (
    <div className="login-container column">
      <div className="columns">
          <div className="column is-2 ">
            <div className='is-size-4 mb-3 has-text-weight-bold'>Every Nation</div>
          </div>
          <div className="column is-3 is-offset-7">
            {/* Menggunakan flexbox untuk mengatur teks "New User?" dan tautan "Register" berdampingan */}
            <div className="is-flex">
              {/* Bagian teks "New User?" */}
              <div className='is-size-6 mb-3 has-text-weight-normal mr-3'>Already have an account?</div>
              
              {/* Bagian tautan "Register" */}
              <div className='is-size-6 mb-3 has-text-weight-normal'>
                <Link to="/login" className="register-link is-size-6 has-text-weight-semibold">Login</Link>
              </div>
            </div>
          </div>
      </div>
      <div className="columns ml-2 is-mobile">
        <div className="column is-centered is-vcentered">
          <div className="columns is-centered is-vcenetered">
            <div className='is-size-3 mb-1 has-text-weight-bold'>Welcome !</div>
          </div>
          <div className="columns is-centered">
            <div className='is-size-6 mb-5 has-text-weight-normal'>Register to Continue</div>
          </div>
          <form onSubmit={saveUser} className="login-form">
            {/* Username */}
            <div className="columns is-centered is-vcentered">
              <div className="column is-2 has-text-left">
                  <div className=' is-size-5 mb-3 has-text-weight-semibold'>Username :</div>
              </div>
              <div className="column is-3">
                  <label className='control '>
                      <input type="text"className='input' value ={username} 
                      onChange={(e)=> setUsername(e.target.value)}
                      placeholder='Username'
                      />
                  </label>
              </div>
            </div>
            {/* Email */}
            <div className="columns is-centered is-vcentered">
              <div className="column is-2 has-text-left">
                  <div className=' is-size-5 mb-3 has-text-weight-semibold'>Email :</div>
              </div>
              <div className="column is-3">
                  <label className='control '>
                      <input type="text"className='input' value ={Email} 
                      onChange={(e)=> setEmail(e.target.value)}
                      placeholder='Email'
                      />
                  </label>
              </div>
            </div>
            {/* Password */}
            <div className="columns is-centered is-vcentered">
                <div className="column is-2 has-text-left">
                  <div className=' is-size-5 mb-3 has-text-weight-semibold'>Password :</div>
              </div>
                <div className="column is-3">
                    <label className='control '>
                        <input type="password"className='input' value ={password} 
                        onChange={(e)=> setPassword(e.target.value)}
                        placeholder='password'
                        />
                    </label>
                </div>
            </div>
            <div className="columns is-centered is-vcentered">
              <div className="column is-2 has-text-left">
                  <div className=' is-size-5 mb-3 has-text-weight-semibold'>RepeatPassword :</div>
              </div>
              <div className="column is-3">
                  <label className='control '>
                      <input type="password"className='input' value ={password2} 
                      onChange={(e)=> setPassword2(e.target.value)}
                      placeholder='password'
                      />
                  </label>
              </div>
            </div>
            <div className="columns is-centered  is-vcentered">
              <button className='button mt-2 is-success'>Register</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserRegister;
