import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../style/LoginStyle.css'; // File CSS untuk styling khusus halaman login
// import loginIcon from "../assets/login_icon.svg";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Periksa panjang username dan password
    if (username.length < 4 || password.length < 4) {
      alert('Username dan password tidak boleh kurang dari 4 karakter.');
      return; // Hentikan eksekusi jika kondisi tidak terpenuhi
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login1`, { identifier: username, password }, { withCredentials: true });
      console.log(response.data.token);
      console.log(response.data.message);
      console.log(response.data);

      if (response.data.message === 'Login successful') {
        console.log("masuk");
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/'); // Pastikan Anda menggunakan path yang benar untuk navigasi
      }
    } catch (error) {
      console.error('Login failed:', error); // Tampilkan informasi error untuk debugging
      alert('Login failed. Please check your credentials.');
    }
  };

    const handleSignUpClick = () => {
    navigate('/registerDataJemaat'); // Ganti '/signup' dengan rute halaman pendaftaran yang benar
  };

  return (
    <div className="login-container">
      <div className="rectangle-container">
        <div className="left-side">
          <div>
            <div className="columns">
              <div className="column is-centered is-vcentered">
                <div className="columns is-centered is-vcenetered">
                  <div className='is-size-3 ml-2 mb-4 has-text-weight-bold'>Login</div>
                </div>
                <form onSubmit={handleLogin} className="login-form">
                  <div className=' is-size-6 mb-3 has-text-weight-semibold'> Email </div>
                    <label className='control '>
                        <input type="text" className='input' value={username} 
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='Username/Email'
                          style={{
                              width: '100%', /* Menetapkan lebar sesuai kebutuhan */
                              maxWidth: '20rem', /* Lebar maksimal */
                              padding: '0.5rem', /* Padding di dalam input */
                              boxSizing: 'border-box' /* Menghitung padding dan border dalam lebar elemen */
                          }}
                        />
                    </label>
                  <div className=' is-size-6 mb-3 has-text-weight-semibold'> Password </div>
                  <label className='control '>
                        <input type="password" className='input' value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='password'
                        style={{
                            width: '100%', /* Menetapkan lebar sesuai kebutuhan */
                            maxWidth: '20rem', /* Lebar maksimal */
                            padding: '0.5rem', /* Padding di dalam input */
                            boxSizing: 'border-box' /* Menghitung padding dan border dalam lebar elemen */
                        }}
                        />
                  </label>
                  <div className="columns mt-6 is-centered">
                    <button 
                        className='button mt-2 ml-2' 
                        style={{ backgroundColor: '#005EB8', width: '150px' ,color: 'white' }}
                      >
                        LOGIN
                    </button>
                  </div>
                  <div className="columns is-centered">
                    <div className='is-size-6 mb-3 has-text-weight-normal mt-2' style={{ color: '#939393' }}>
                      Don’t have an account? 
                      <span style={{ color: '#005EB8', cursor: 'pointer' }} onClick={handleSignUpClick}>
                        Sign Up
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <img src="https://www.everynation.org/wp-content/uploads/2023/02/125407087_423695602355869_397845639419320020_n-EDIT.jpg" alt="Login Visual" />
          {/* Replace with the path to your image */}
        </div>
      </div>
    </div>
  );
};

export default Login;
