import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTable,usePagination, useGlobalFilter, useSortBy } from 'react-table';
import "../style/tableStyle.css";
import { formatDate_DDMMMMYYYY } from '../Utils/dateUtils';

const LifegroupActivityAttendanceDetail = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const [lifegroupName, setLifegroupName] = useState("");
    const [lifegroupId, setLifegroupId] = useState("");
    const [lifegroupDate, setLifegroupDate] = useState("");

    useEffect(() => {
        getUsers();
        getDetailLG();
    }, [id]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAttendanceListByEventId/${id}`);
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getDetailLG = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewEvent/${id}`);
            setLifegroupDate(formatDate_DDMMMMYYYY(response.data.eventDate));
            const fetchedLifegroupId = response.data.lifegroupId;
            setLifegroupId(fetchedLifegroupId);
            if (fetchedLifegroupId) {
                const responseLG = await axios.get(`${process.env.REACT_APP_API_URL}/viewLifeGroupById/${fetchedLifegroupId}`);
                setLifegroupName(responseLG.data.lifegroupName);
            }
        } catch (error) {
            console.error("Error fetching event data:", error);
        }
    };

    const handleButtonClick = () => {
        if (users.length > 0) {
            navigate(`/ActivityLGAttendanceUpdate/${id}`);
        } else {
            navigate(`/ActivityLGAttendance/${id}`);
        }
    };
    
    // React Table setup
    const columns = useMemo(
        () => [
            {
                Header: 'Person Name',
                accessor: 'namaDepan',
            },
            {
                Header: 'Status',
                accessor: 'kehadiran',
                Cell: ({ row }) => (
                    <input type="checkbox" checked={row.original.kehadiran} disabled className="checkbox custom-checkbox" />
                ),
            },
        ],
        []
    );

    const data = useMemo(() => users, [users]);

    // Use useTable with pagination state
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        setPageSize
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10  } }, // Set initial pageSize
        useGlobalFilter,
        useSortBy,  // useSortBy must come before usePagination
        usePagination // usePagi
    );

    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <div className="columns">
            <div className="column">
                <div className="columns">
                    <div className="column is-3">
                        <div className='is-size-5 mb-2 has-text-weight-semibold'>Lifegroup Name</div>
                    </div>
                    <div className="column is-4">
                        <label className='control'>
                            <input
                                type="text"
                                className='input'
                                value={lifegroupName}
                                placeholder='Lifegroup Name'
                                readOnly
                            />
                        </label>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3">
                        <div className='is-size-5 mb-2 has-text-weight-semibold'>Date</div>
                    </div>
                    <div className="column is-4">
                        <label className='control'>
                            <input
                                type="text"
                                className='input'
                                value={lifegroupDate}
                                placeholder='Date'
                                readOnly
                            />
                        </label>
                    </div>
                </div>

                <hr />

                <div className="custom-button mb-3" onClick={handleButtonClick}>
                    Add/ Edit Attendance
                </div>

                {/* Entries per page selector */}
                <div className="field is-grouped is-grouped-multiline" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="control" style={{ marginRight: '10px' }}>
                        <div className="select">
                            <select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map(size => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <label className="label" style={{ textAlign: 'center' }}>entries per page</label>
                </div>

                <table className="table is-striped is-fullwidth mt-5" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* Pagination */}
                <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                        <span>
                            <strong>
                                Showing {page.length ? page[0].index + 1 : 0} to {page.length ? page[0].index + page.length : 0}
                            </strong>
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <button className='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </button>
                        <span style={{ margin: '0 10px' }}>
                            <button className='custom-button'>
                                {pageIndex + 1}
                            </button>
                        </span>
                        <button className='button' onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <span>
                            <strong>
                                Page: {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default LifegroupActivityAttendanceDetail;
